/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const axios = require("axios");

function ReportAccountStatement() {
    const current = new Date();
    let month = current.getMonth() + 1;
    let day = current.getDate();
    const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
        day < 10 ? `0${day}` : `${day}`
    }`;

    const [menu, setMenu] = useState(null);
    const [report, setReport] = useState({ columns: [], rows: [] });
    const [download, setDownload] = useState(true);
    const [errors, setErrors] = useState([]);
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState();
    const [language, setLanguage] = useState(localStorage.getItem("Language"));
    const [open, setOpen] = useState(false);
    const [sortSelect, setSortSelect] = useState();
    const [data, setData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [totals, setTotals] = useState({days30: 0, days60: 0, days90: 0, daysOver90: 0})
    const [customer, setCustomer] = useState({});

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const navigate = useNavigate();

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, "0");

        let hours = now.getHours();
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight as 12

        return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    };

    const createPDF = () => {
        setDownload(false);
        const pdf = new jsPDF("portrait", "px", "a0");
        const data = document.querySelector("#pdf");
        pdf.html(data).then(() => {
            pdf.save(`Open Customer Balance - ${customer.name} - ${currentDate}`);
            setDownload(true);
        });
    };

    async function GetStores() {
        await axios
            .get(IP + "/stores", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                console.log(response.data.data);
                let store = [];

                response.data.data.map((item) => {
                    if (item.active == "Active") {
                        store.push({ label: item.name, id: item.id });
                    }
                });

                setStores(store);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function sortTotal(total) {
        total = total.toString();
        let newTotal = ''
        for (let i = 0; i < total.length; i++) {
            if (total[i] != ',')
                newTotal += total[i]
        }
        return parseFloat(newTotal)
    }

    async function getStatement() {
        await axios
            .get(IP + "/reports/account-statement?store_id=" +
                selectedStore.id, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
            .then((response) => {
                setData(response.data.data.statement)
                setCustomer(response.data.data.store)
                let columns = [
                    { Header: language == 1 ? "Tipo" : "Type", accessor: "type", width: "10%" },
                    { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
                    { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
                    { Header: language == 1 ? "Menos de 30 días" : "Less than 30 days", accessor: "days30" },
                    { Header: language == 1 ? "30 a 60 días" : "30 to 60 days", accessor: "days60" },
                    { Header: language == 1 ? "60 a 90 días" : "60 to 90 days", accessor: "days90" },
                    { Header: language == 1 ? "Más de 90 días" : "More than 90 days", accessor: "over90" },
                ];

                let totalDays30 = 0;
                let totalDays60 = 0;
                let totalDays90 = 0;
                let totalDaysOver90 = 0;

                let rows = [];
                let excelRows = [];
                response.data.data.statement.map((item) => {
                    excelRows.push({
                        ...(language == 1 ? {"Tipo": "Factura"} : {"Type": "Invoice"}),
                        ...(language == 1 ? {"Fecha": item.date} : {"Date": item.date}),
                        ...(language == 1 ? {"Número de Factura": item.invoice_number} : {"Invoice Number": item.invoice_number}),
                        ...(language == 1 ? {"Menos de 30 días": item['30days'] != '' ? '$' + item['30days'] : ''} : {"Less than 30 days": item['30days'] != '' ? '$' + item['30days'] : ''}),
                        ...(language == 1 ? {"30 a 60 días": item['60days'] != '' ? '$' + item['60days'] : ''} : {"30 to 60 days": item['60days'] != '' ? '$' + item['60days'] : ''}),
                        ...(language == 1 ? {"60 a 90 días": item['90days'] != '' ? '$' + item['90days'] : ''} : {"60 to 90 days": item['90days'] != '' ? '$' + item['90days'] : ''}),
                        ...(language == 1 ? {"Más de 90 días": item['over_90days'] != '' ? '$' + item['over_90days'] : ''} : {"More than 90 days": item['over_90days'] != '' ? '$' + item['over_90days'] : ''}),
                    });
                    totalDays30 += sortTotal(item['30days'] != '' ? item['30days'] : 0);
                    totalDays60 += sortTotal(item['60days'] != '' ? item['60days'] : 0);
                    totalDays90 += sortTotal(item['90days'] != '' ? item['90days'] : 0);
                    totalDaysOver90 += sortTotal(item['over_90days'] != '' ? item['over_90days'] : 0);
                    rows.push({
                        type: language == 1 ? "Factura" : "Invoice",
                        date: item.date,
                        invoice: item.invoice_number,
                        days30: item['30days'] != '' ? '$' + item['30days'] : '',
                        days60: item['60days'] != '' ? '$' + item['60days'] : '',
                        days90: item['90days'] != '' ? '$' + item['90days'] : '',
                        over90: item['over_90days'] != '' ? '$' + item['over_90days'] : '',
                    });

                    item.payments.map(payment => {
                        totalDays30 -= (payment['30days'] != '' ? payment['30days'] : 0);
                        totalDays60 -= (payment['60days'] != '' ? payment['60days'] : 0);
                        totalDays90 -= (payment['90days'] != '' ? payment['90days'] : 0);
                        totalDaysOver90 -= (payment['over_90days'] != '' ? payment['over_90days'] : 0);
                        rows.push({
                            type: language == 1 ? "Pago" : "Payment",
                            date: payment.date,
                            invoice: item.invoice_number,
                            days30: payment['30days'] != '' ? '-$' + payment['30days'] : '',
                            days60: payment['60days'] != '' ? '-$' + payment['60days'] : '',
                            days90: payment['90days'] != '' ? '-$' + payment['90days'] : '',
                            over90: payment['over_90days'] != '' ? '-$' + payment['over_90days'] : '',
                        });
                        excelRows.push({
                            ...(language == 1 ? {"Tipo": "Pago"} : {"Type": "Payment"}),
                            ...(language == 1 ? {"Fecha": payment.date} : {"Date": payment.date}),
                            ...(language == 1 ? {"Número de Factura": item.invoice_number} : {"Invoice Number": item.invoice_number}),
                            ...(language == 1 ? {"Menos de 30 días": payment['30days'] != '' ? '-$' + payment['30days'] : ''} : {"Less than 30 days": payment['30days'] != '' ? '-$' + payment['30days'] : ''}),
                            ...(language == 1 ? {"30 a 60 días": payment['60days'] != '' ? '-$' + payment['60days'] : ''} : {"30 to 60 days": payment['60days'] != '' ? '-$' + payment['60days'] : ''}),
                            ...(language == 1 ? {"60 a 90 días": payment['90days'] != '' ? '-$' + payment['90days'] : ''} : {"60 to 90 days": payment['90days'] != '' ? '-$' + payment['90days'] : ''}),
                            ...(language == 1 ? {"Más de 90 días": payment['over_90days'] != '' ? '-$' + payment['over_90days'] : ''} : {"More than 90 days": payment['over_90days'] != '' ? '-$' + payment['over_90days'] : ''}),
                        });
                    });

                    item.adjustments.map(adjustment => {
                        totalDays30 += (adjustment['30days'] != '' ? adjustment['30days'] : 0);
                        totalDays60 += (adjustment['60days'] != '' ? adjustment['60days'] : 0);
                        totalDays90 += (adjustment['90days'] != '' ? adjustment['90days'] : 0);
                        totalDaysOver90 += (adjustment['over_90days'] != '' ? adjustment['over_90days'] : 0);
                        rows.push({
                            type: language == 1 ? "Ajuste" : "Adjustment",
                            date: adjustment.date,
                            invoice: item.invoice_number,
                            days30: adjustment['30days'] !== '' ? (adjustment['30days'] < 0 ? '-$' + Math.abs(adjustment['30days']) : '$' + adjustment['30days']) : '',
                            days60: adjustment['60days'] !== '' ? (adjustment['60days'] < 0 ? '-$' + Math.abs(adjustment['60days']) : '$' + adjustment['60days']) : '',
                            days90: adjustment['90days'] !== '' ? (adjustment['90days'] < 0 ? '-$' + Math.abs(adjustment['90days']) : '$' + adjustment['90days']) : '',
                            over90: adjustment['over_90days'] !== '' ? (adjustment['over_90days'] < 0 ? '-$' + Math.abs(adjustment['over_90days']) : '$' + adjustment['over_90days']) : '',
                        });
                        excelRows.push({
                            ...(language == 1 ? {"Tipo": "Ajuste"} : {"Type": "Adjustment"}),
                            ...(language == 1 ? {"Fecha": adjustment.date} : {"Date": adjustment.date}),
                            ...(language == 1 ? {"Número de Factura": item.invoice_number} : {"Invoice Number": item.invoice_number}),
                            ...(language == 1 ? {"Menos de 30 días": adjustment['30days'] !== '' ? (adjustment['30days'] < 0 ? '-$' + Math.abs(adjustment['30days']) : '$' + adjustment['30days']) : ''} : {"Less than 30 days": adjustment['30days'] !== '' ? (adjustment['30days'] < 0 ? '-$' + Math.abs(adjustment['30days']) : '$' + adjustment['30days']) : ''}),
                            ...(language == 1 ? {"30 a 60 días": adjustment['60days'] !== '' ? (adjustment['60days'] < 0 ? '-$' + Math.abs(adjustment['60days']) : '$' + adjustment['60days']) : ''} : {"30 to 60 days": adjustment['60days'] !== '' ? (adjustment['60days'] < 0 ? '-$' + Math.abs(adjustment['60days']) : '$' + adjustment['60days']) : ''}),
                            ...(language == 1 ? {"60 a 90 días": adjustment['90days'] !== '' ? (adjustment['90days'] < 0 ? '-$' + Math.abs(adjustment['90days']) : '$' + adjustment['90days']) : ''} : {"60 to 90 days": adjustment['90days'] !== '' ? (adjustment['90days'] < 0 ? '-$' + Math.abs(adjustment['90days']) : '$' + adjustment['90days']) : ''}),
                            ...(language == 1 ? {"Más de 90 días": adjustment['over_90days'] !== '' ? (adjustment['over_90days'] < 0 ? '-$' + Math.abs(adjustment['over_90days']) : '$' + adjustment['over_90days']) : ''} : {"More than 90 days": adjustment['over_90days'] !== '' ? (adjustment['over_90days'] < 0 ? '-$' + Math.abs(adjustment['over_90days']) : '$' + adjustment['over_90days']) : ''}),
                        });
                    });
                });
                setTotals({days30: totalDays30, days60: totalDays60, days90: totalDays90, daysOver90: totalDaysOver90})

                rows.unshift({
                    date: '',
                    type: '',
                    invoice: 'Total: ',
                    days30: '$' + totalDays30.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    days60: '$' + totalDays60.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    days90: '$' + totalDays90.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    over90: '$' + totalDaysOver90.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                })

                setData(excelRows);

                let tableData = { columns: columns, rows: rows };
                //console.log(tableData)
                setReport(tableData);
            })
            .catch((error) => {
                // if (!error.response.data.authenticated) {
                //     navigate("/signIn");
                // }
                console.log(error);
            });
    }

    function getTotal() {
        let total = 0;
        total += totals.days30
        total += totals.days60
        total += totals.days90
        total += totals.daysOver90

        return total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }

    function TestValidation(message) {
        if (message)
            return message.map((item) => {
                return (
                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{item}</label>
                    </MDTypography>
                );
            });
        else return null;
    }

    function ShowError(param) {
        // console.log(errors["name"]);
        if (param.id) return TestValidation(errors[param.id]);
        else return null;
    }

    useEffect(() => {
        GetStores();
    }, []);

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDBox display="flex">
                        {data.length > 0 ? (
                            <>
                                <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                                    {language == 1 ? "Organizar" : "Sort"}&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </MDButton>
                                <MDBox ml={1}>
                                    <ExportToExcel apiData={data} fileName={`Open Customer Balance - ${customer?.name} - ${currentDate}`} />
                                </MDBox>
                                <MDBox ml={1}>
                                    <MDButton variant="outlined" color="dark" onClick={createPDF}>
                                        {language == 1 ? "Descargar PDF" : "Download PDF"}
                                    </MDButton>
                                </MDBox>
                            </>
                        ) : null}
                    </MDBox>
                </MDBox>
                <Card>
                    <MDBox p={3}>
                        <MDTypography variant="h4" sx={{ mb: 1 }}>
                            {language == 1 ? "Busqueda" : "Search"}
                        </MDTypography>
                        <Grid container spacing={1}>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <Autocomplete
                                    disablePortal
                                    value={selectedStore}
                                    onChange={(event, newValue) => {
                                        setSelectedStore(newValue);
                                    }}
                                    options={stores}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={language == 1 ? "Tiendas" : "Stores"}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid id="grid1-2" item xs={12} sm={2}>
                                <MDButton
                                    type="button"
                                    variant="gradient"
                                    className="float-sm-right"
                                    color="info"
                                    onClick={getStatement}
                                    disabled={!selectedStore}
                                >
                                    {language == 1 ? "Busqueda" : "Search"}
                                </MDButton>
                            </Grid>
                        </Grid>
                        {data.length > 0 && <h5 className={'mt-2'}>Total: ${getTotal()}</h5>}
                        <DataTable table={report} entriesPerPage={true} canSearch />
                    </MDBox>
                </Card>
                <div hidden={download}>
                    <MDBox mt={50}>
                        <div id="pdf">
                            <h3>{`Open Customer Balance - ${customer?.name} - ${getCurrentDateTime()}`}</h3>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{language == 1 ? "Tipo" : "Type"}</th>
                                        <th>{language == 1 ? "Fecha" : "Date"}</th>
                                        <th>{language == 1 ? "Número de Factura" : "Invoice Number"}</th>
                                        <th>{language == 1 ? "Menos de 30 días" : "Less than 30 days"}</th>
                                        <th>{language == 1 ? "30 a 60 días" : "30 to 60 days"}</th>
                                        <th>{language == 1 ? "60 a 90 días" : "60 to 90 days"}</th>
                                        <th>{language == 1 ? "Más de 90 días" : "More than 90 days"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {report.rows.map(row =>
                                    <tr>
                                        {report.columns.map(column =>
                                            <td>{row[column?.accessor] ?? ''}</td>
                                        )}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </MDBox>
                </div>
            </MDBox>
        </DashboardLayout>
    );
}

export default ReportAccountStatement;
