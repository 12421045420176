import { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import IP from "app/IP";
import MDTypography from "components/MDTypography";
import AlertDialog from "app/components/confirmBox";

const axios = require("axios");

export default function PurchaseOrderModal({
  open,
  setOpen,
  Product,
  isEdit,
  currentTotal,
  selectedVendor,
  getPurchaseOrderDetails
}) {
  const [productQty, setProductQty] = useState(isEdit ? Product.quantity : 0);
  const [shows, setShows] = useState(false);
  const [total, setTotal] = useState(0.00)
  const [newPrice, setNewPrice] = useState('')
  const [newPricePercent, setNewPricePercent] = useState('')
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar2 = () => setShows(!shows);
  const handleClose = () => {
    setOpen(false);
    console.log(Product);
    console.log(isEdit);
  };

  if ((total == 0.00 || total != currentTotal) && currentTotal) {
    setTotal(currentTotal)
  }

  async function createPurchaseOrderDetail() {
    let reqBody = {
      cost: Product.cost,
      quantity: productQty,
      product_id: Product.id,
      purchase_order_id: localStorage.getItem("purchaseOrderId"),
      quantity_received: 0,
      newPrice: newPrice == '' || newPrice == 0 || newPrice == null ? null : newPrice
    };
    await axios
      .post(
        IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId") + "/po_details",
        reqBody,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then(response => {
        setProductQty(0);
        setOpen(false);
        getPurchaseOrderDetails()
      })
  }

  async function editPurchaseOrderDetail() {
    let reqBody = {
      cost: Product.cost,
      quantity: productQty,
      product_id: Product.product.id,
      purchase_order_id: localStorage.getItem("purchaseOrderId"),
      quantity_received: 0,
    };
    await axios
      .put(IP + "/po_details/" + Product.id, reqBody, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(response => {
        setProductQty(0);
        setOpen(false);
        getPurchaseOrderDetails()
      })
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    setNewPrice(isEdit ? Product.newPrice : '')
    setNewPricePercent((isEdit && Product.newPrice) ? parseFloat((Product.newPrice/Product.cost - 1) * 100).toFixed(2) : '')
  }, [Product])

  return (
    <>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Material Dashboard"
        content="Product Added!"
        dateTime={language==1 ? "Ahora" : "Now"}
        open={shows}
        close={toggleSnackbar2}
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog 
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Grid container justifyContent={'space-between'}>
              <div>
                {language == 1 ? "Producto: " : "Product: "}
                {isEdit ? Product.product.name : Product.name}
              </div>
              <div>
                {language == 1 ? "UPC Unidad: " : "UPC Unit: "}
                {isEdit ? Product.product.upcUnit : Product.upcUnit}
              </div>
            </Grid>
            <Grid>
              {language == 1 ? "Costo base: " : "Base cost: "}
              ${isEdit ? Product.product.base_cost : Product.base_cost}
            </Grid>
            {(isEdit ? Product?.product.offers : Product?.offers)?.map(offer => (
                <Grid>
                  {offer.description} ({offer.percent}%): ${parseFloat(offer.offer).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                </Grid>
            ))}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  value={productQty}
                  label={language == 1 ? "Cantidad" : "Order Qty"}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value >= 0 || e.target.value == "") {
                      setProductQty(e.target.value);
                    }
                  }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="price"
                  value={"$" + Product.cost}
                  label={language == 1 ? "Costo" : "Cost"}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  value={newPricePercent}
                  label={language == 1 ? "Porcentaje" : "Percentage"}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value >= 0 || e.target.value == "") {
                      let newValue = e.target.value;
                      setNewPricePercent(newValue)
                      setNewPrice(newValue == '' ? '' : parseFloat(((newValue/100) + 1) * Product.cost).toFixed(2))
                    }
                  }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="price"
                  value={newPrice}
                  label={language == 1 ? "Precio" : "Price"}
                  onChange={(e) => {
                    if (e.target.value >= 0 || e.target.value == "") {
                      let newValue = e.target.value;
                      setNewPrice(newValue)
                      setNewPricePercent(newValue == '' ? '' : parseFloat((newValue/Product.cost - 1) * 100).toFixed(2))
                    }
                  }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>
            <MDTypography sx={{ mt: 1.5 }} variant="h6">
              Total: <FadeIn>{"$" + parseFloat(
                total + ((isEdit ? productQty - Product.quantity : productQty) * (Product.cost)) 
                + ((isEdit ? productQty - Product.quantity : productQty) * (Product.cost) 
                * ((isEdit ? Product?.product?.tax?.percent : Product?.tax?.percent)/100))).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}</FadeIn>
            </MDTypography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              disabled={productQty == 0}
              onClick={() => {
                if (isEdit) {
                  editPurchaseOrderDetail();
                } else {
                  createPurchaseOrderDetail();
                }
              }}
            >
              {language == 1 ? "Guardar" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
}
