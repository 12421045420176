import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon, TextField } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";
import FormField from "layouts/pages/users/new-user/components/FormField";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import PurchaseOrderModal from "app/components/purchaseOrderModal";
import AlertDialog from "app/components/confirmBox";
import AlertDialog2 from "app/components/confirmBoxDeliveryPickup";
import AlertDialogPurchase from "app/components/confirmBoxPurchaseOrder";
import DynamicPurchasePopup from "app/components/dynamicPurchasePopup";
import IP from "app/IP";
import PurchaseOrderCharges from "./purchaseOrderCharges";

const axios = require("axios");

function EditPurchaseOrder() {
  const [date, setDate] = useState("");
  const [eda, setEda] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [vendorId, setVendorId] = useState();
  const [selectedVendor, setSelectedVendor] = useState({ name: "" });
  const [notes, setNotes] = useState("");
  const [contact, setContact] = useState("");
  const [arrival, setArrival] = useState("");
  const [delivered, setDelivered] = useState("")
  const [deliveredBy, setDeliveredBy] = useState("")
  const [pickup, setPickup] = useState("");
  const [pickupBy, setPickupBy] = useState("");
  const [cif, setCif] = useState("");
  const [fob, setFob] = useState("");
  const [deliveryAddressName, setDeliveryAddressName] = useState('Supermercados Agranel, INC');
  const [deliveryAddress, setDeliveryAddress] = useState('Carr. # 2 KM 20.5');
  const [deliveryAddress2, setDeliveryAddress2] = useState('Edif. 2 BO. Candelaria');
  const [deliveryZipcode, setDeliveryZipcode] = useState('00949');
  const [deliveryState, setDeliveryState] = useState('PR');
  const [deliveryCity, setDeliveryCity] = useState('Toa Baja');
  const [billingAddressName, setBillingAddressName] = useState('Supermercados Agranel, INC');
  const [billingAddress, setBillingAddress] = useState('P.O. Box 200');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingZipcode, setBillingZipcode] = useState('00952');
  const [billingState, setBillingState] = useState('PR');
  const [billingCity, setBillingCity] = useState('Sabana Seca');
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null)
  const [vendorProducts, setVendorProducts] = useState({ columns: [], rows: [] });
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [prod, setProd] = useState("");
  const [changeTable, setChangeTable] = useState(true);
  const [purchaseOProducts, setPurchaseOProducts] = useState([]);
  const [isQtyEdit, setIsQtyEdit] = useState(false);
  const [prodToDel, setProdToDel] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [changeStatus, setChangeStatus] = useState(false);
  const [transport, setTransport] = useState("D");
  const [currentTotal, setCurrentTotal] = useState(0.0);
  const [deliveries, setDeliveries] = useState([]);
  const [showmsg2, setShowMsg2] = useState("");
  const [noProdsNotif, setNoProdsNotif] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbar2 = () => setShowMsg2(!showmsg2);
  const toggleSnackbar3 = () => setNoProdsNotif(!noProdsNotif);
  const [additionalFees, setAdditionalFees] = useState(0.0);
  const [chargeDetails, setChargeDetails] = useState([]);
  const [charges, setCharges] = useState([]);
  const navigate = useNavigate();

  const vendorProductColumns = [
    { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
    { Header: language == 1 ? "Código" : "Code", accessor: "code" },
    { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
    { Header: language == 1 ? "Cant. Max." : "Qty. Max", accessor: "maximum" },
    { Header: language == 1 ? "Cant. Min." : "Qty. Min", accessor: "minimum" },
    { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
    { Header: "Diff.", accessor: "diff" },
    { Header: language == 1 ? "Cant." : "Qty", accessor: "qty" },
    { Header: language == 1 ? "Costo Base" : "Base Cost", accessor: "baseprice" },
    { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
    { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
    { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
  ];

  const purchaseOColumns = [
    { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
    { Header: language == 1 ? "Código" : "Code", accessor: "code" },
    { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
    { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
    { Header: language == 1 ? "Precio Nuevo" : "New Price", accessor: "newPrice" },
    { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "qty" },
    { Header: "Sub-Total", accessor: "subtotal" },
    { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
    { Header: "Total", accessor: "total", width: "15%" },
    { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
  ];

  async function getPaymentTerms() {
    await axios
      .get(IP + "/payment-terms", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setPaymentTerms(
          response.data.data.map((item) => {
            return { label: item.name, id: item.payment_term_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getCharges() {
    await axios
      .get(IP + "/additional-charges", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCharges(response.data.data.map((item) => ({ ...item, id: item.additional_charge_id })));
      });
  }

  async function getCurrentCharges() {
    await axios
      .get(IP + "/purchase-order/" + localStorage.getItem("purchaseOrderId") + "/charges", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        setChargeDetails(
          response.data.data.map((item) => ({ ...item, amount: item.pivot.amount }))
        );
      });
  }

  async function getPurchaseOrder() {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Purchase order request:", response.data.data);
        let orders = response.data.data;

        setDate(orders.date);
        setEda(orders.eda);
        setSelectedVendor({ name: orders.vendor.vendor_number + " " + orders.vendor.name });
        setSelectedPaymentTerm({ id: orders.payment_term.id, label: orders.payment_term.name })
        setContact(orders.contact_name)
        setNotes(orders.notes)
        setVendorId(orders.vendor.id);
        setArrival(orders.arrival);
        setPickup(orders.pickup);
        setPickupBy(orders.pickup_by);
        setDelivered(orders.delivered);
        setDeliveredBy(orders.delivered_by);
        setCif(orders.CIF);
        setFob(orders.FOB);
        setDeliveryAddressName(orders.delivery_address_name);
        setDeliveryAddress(orders.delivery_address);
        setDeliveryAddress2(orders.delivery_address2 ?? '');
        setDeliveryZipcode(orders.delivery_zipcode);
        setDeliveryState(orders.delivery_state);
        setDeliveryCity(orders.delivery_city);
        setBillingAddressName(orders.billing_address_name);
        setBillingAddress(orders.billing_address);
        setBillingAddress2(orders.billing_address2 ?? '');
        setBillingZipcode(orders.billing_zipcode);
        setBillingState(orders.billing_state);
        setBillingCity(orders.billing_city);
        getVendorProducts(orders.vendor.id, parseFloat(response.data.data.additional_fee));
      });
  }

  async function getVendorProducts(vendorid) {
    await axios
      .get(IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId") + "/po_details", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        //test
        let vendorProductRows = response?.data?.data?.all
          ?.filter((item) => {
            return item.active == "Active";
          })
          .map((item) => {
            //console.log(item);
            return {
              ...item,
              name: item.name,
              code: item.code,
              upcunit: item.upcUnit,
              maximum: item.maximum,
              minimum: item.minimum,
              available: item.available,
              diff: item.diff,
              baseprice: item.basePrice,
              cost: item.cost,
              tax: parseFloat(item.cost * (item.tax.percent / 100)).toFixed(2),
              qty: 0,
              action: (
                <MDButton
                  color="info"
                  onClick={() => {
                    setOpen(true);
                    setProd(item);
                    setIsQtyEdit(false);
                  }}
                  type="button"
                  variant="gradient"
                >
                  {language == 1 ? "Añadir" : "Add"}
                </MDButton>
              ),
            };
          });


        let tableData = { columns: vendorProductColumns ?? [], rows: vendorProductRows ?? [] };
        setVendorProducts(tableData);

        let purchaseORows = response?.data?.data?.selected?.map((item) => {
          return {
            ...item,
            name: item.product.name,
            code: item.product.code,
            upcunit: item.product.upcUnit,
            tax: parseFloat(item.tax).toFixed(2),
            newPrice: item.newPrice ? parseFloat(item.newPrice).toFixed(2) : '',
            cost: item.cost,
            qty: item.quantity,
            subtotal: parseFloat(item.quantity * item.cost).toFixed(2),
            total: parseFloat(item.total).toFixed(2),
            action: (
              <>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    color="info"
                    onClick={() => {
                      setOpen(true);
                      setProd(item);
                      setIsQtyEdit(true);
                    }}
                    type="button"
                    variant="gradient"
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    color="error"
                    onClick={() => {
                      setProdToDel(item);
                      setConfirm(true);
                    }}
                    type="button"
                    variant="gradient"
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </>
            ),
          };
        });

        let tableDataPO = { columns: purchaseOColumns ?? [], rows: purchaseORows ?? [] };
        setPurchaseOProducts(tableDataPO);
        setSelectedProducts(response.data.data.selected);
      })
      .catch((error) => {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function savePurchaseOrderHeader() {
    setDisableButton(true);
    await axios
      .put(
        IP + "/purchase_orders/" + localStorage.getItem("purchaseOrderId"),
        {
          date: date,
          eda: eda,
          status_id: 1,
          active: true,
          notes: notes,
          contact_name: contact,
          arrival: arrival,
          pickup: pickup,
          pickup_by: pickupBy,
          delivered: delivered,
          delivered_by: deliveredBy,
          CIF: cif,
          FOB: fob,
          delivery_address_name: deliveryAddressName,
          delivery_address: deliveryAddress,
          delivery_address2: deliveryAddress2,
          delivery_zipcode: deliveryZipcode,
          delivery_state: deliveryState,
          delivery_city: deliveryCity,
          billing_address_name: billingAddressName,
          billing_address: billingAddress,
          billing_address2: billingAddress2,
          billing_zipcode: billingZipcode,
          billing_state: billingState,
          billing_city: billingCity,
          payment_term_id: selectedPaymentTerm?.id
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setErrors([]);
        toggleSnackbar();
        getVendorProducts();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  async function deleteOrderDetailProduct() {
    setConfirm(false);
    await axios
      .delete(IP + "/po_details/" + prodToDel.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        getVendorProducts();
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  async function CambiarEstatus() {
    await axios
      .post(
        IP + "/submit/purchase",
        {
          purchase_order_id: localStorage.getItem("purchaseOrderId"),
          transport: transport,
          additional_fee: chargeDetails.reduce((prev, current) => prev.amount + current.amount, 0),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        toggleSnackbar2();
        setTimeout(() => {
          navigate("/purchase_orders");
        }, 2000);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  const getTotal = () => {
    return currentTotal;
  };

  useEffect(() => {
    getPurchaseOrder();
    getCharges();
    getCurrentCharges();
    getPaymentTerms();
  }, []);

  useEffect(() => {
    let rows = purchaseOProducts?.rows ?? [];
    console.log(rows)
    let additionalFees = chargeDetails.reduce((sum, charge) => sum + (charge.amount || 0), 0);

    let sub = rows.map((item) => {
      return parseFloat(item.qty * item.cost);
    });

    let subtotal = sub.reduce((prev, current) => prev + current, 0);

    let taxTotal = rows
      .map((item) => {
        return parseFloat(item.tax);
      })
      .reduce((prev, current) => prev + current, 0);

    let prices = rows?.map((item) => {
      return parseFloat(item.total);
    });

    let totalPrice = prices?.reduce((prev, current) => prev + current, 0);
    setCurrentTotal(totalPrice + additionalFees + taxTotal);

    let totalQty = rows?.reduce(
      (prev, current) => prev + current.quantity,
      0
    );

    if (rows.length > 0) {
      let totalsRow = {
        name: "",
        price: "",
        qty: language == 1 ? "Total de bultos: " + totalQty : "Total Quantity: " + totalQty,
        subtotal: "Sub-Total: $" + parseFloat(subtotal).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        tax:
          language == 1
            ? "Total de Impuestos: $" + parseFloat(taxTotal).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
            : "Tax Total: $" + parseFloat(taxTotal).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
        total:
          language == 1
            ? "Total sin cargos: $" + parseFloat((totalPrice + additionalFees + taxTotal) ?? 0).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
            : "Total without charges: $" + parseFloat((totalPrice + additionalFees + taxTotal) ?? 0).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
        action: "",
        isTotal: true,
      };

      if (rows[rows.length - 1]?.isTotal) // if there's already a totals row, then replace it.
        rows[rows.length - 1] = totalsRow
      else
        rows.push(totalsRow)
    }

    let tableDataPO = { columns: purchaseOColumns ?? [], rows: rows ?? [] };
    setPurchaseOProducts(tableDataPO);

    if (rows.length == 0) //if there are no products selected, change table to add products
      setChangeTable(true)

  }, [selectedProducts, chargeDetails])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PurchaseOrderModal
        open={open}
        currentTotal={getTotal()}
        setCurrentTotal={setCurrentTotal}
        Product={prod}
        setOpen={setOpen}
        isEdit={isQtyEdit}
        selectedVendor={selectedVendor}
        getPurchaseOrderDetails={getVendorProducts}
      />
      <AlertDialog
        Show={confirm}
        setShowConfirm={setConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres sacar el producto '" + prodToDel?.product?.name + "' ?"
            : "Are you sure you want to remove the product '" + prodToDel?.product?.name + "' ?"
        }
        OkayFunction={deleteOrderDetailProduct}
      />
      <DynamicPurchasePopup
        Show={openPopup}
        setShowConfirm={setOpenPopup}
        data={{
          po_id: localStorage.getItem("purchaseOrderId"),
          vendor_id: vendorId,
        }}
        OkayFunction={getPurchaseOrder}
      />
      <AlertDialogPurchase
        Show={changeStatus}
        setShowConfirm={setChangeStatus}
        Message={
          language == 1
            ? "¿Estás seguro que quieres que se confirme esta Orden de Compra?"
            : "Are you sure you want confirm this Purchase Order?"
        }
        selected={transport}
        setSelected={setTransport}
        total={parseFloat(currentTotal)}
        OkayFunction={CambiarEstatus}
        AdditionalFee={additionalFees}
        setFee={setAdditionalFees}
        charges={chargeDetails}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Cambio hecho exitosamente!" : "Change Succesful!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Orden Confirmada!" : "Order Confirmed!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={showmsg2}
        close={toggleSnackbar2}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "No se encontraron productos" : "No products found"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={noProdsNotif}
        close={toggleSnackbar3}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox>
              <MDBox>
                <MDBox>
                  <MDBox>
                    <MDBox>
                      <MDBox lineHeight={0}>
                        <MDTypography variant="h5">
                          {language == 1 ? "Editar Orden de Compra" : "Edit Purchase Order"}
                        </MDTypography>
                        <MDTypography variant="button" color="text">
                          {language == 1
                            ? "Favor de entrar la información de la orden."
                            : "Please enter order information."}
                        </MDTypography>
                      </MDBox>
                      <MDBox id="box1" mt={1.625}>
                        <Grid id="grid1" container spacing={3}>
                          <Grid id="grid1-1" item xs={12} sm={3}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label={language == 1 ? "Fecha" : "Date"}
                              fullWidth
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                            <ShowError id={"date"} />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="EDA"
                              fullWidth
                              value={eda}
                              onChange={(e) => {
                                setEda(e.target.value);
                              }}
                            />
                            <ShowError id={"eda"} />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <MDInput
                              value={selectedVendor?.name}
                              type="text"
                              label={language == 1 ? "Vendedor" : "Vendor"}
                              variant="standard"
                              fullWidth
                            />
                            <ShowError id={"vendor_id"} />
                          </Grid>
                        </Grid>
                        <Grid container pt={0.5} spacing={3}>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              disablePortal
                              value={selectedPaymentTerm}
                              disableClearable
                              onChange={(event, newValue) => {
                                console.log(newValue);
                                setSelectedPaymentTerm(newValue);
                              }}
                              options={paymentTerms}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={language == 1 ? "Término" : "Term"}
                                  variant="standard"
                                />
                              )}
                            />
                            <ShowError id={"payment_term_id"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Contacto" : "Contact"}
                              fullWidth
                              value={contact}
                              onChange={(e) => {
                                setContact(e.target.value);
                              }}
                            />
                            <ShowError id={"contact_name"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Requerido" : "Required"}
                              fullWidth
                              value={arrival}
                              onChange={(e) => {
                                setArrival(e.target.value);
                              }}
                            />
                            <ShowError id={"arrival"} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Recogido" : "Pickup"}
                              fullWidth
                              value={pickup}
                              onChange={(e) => {
                                setPickup(e.target.value);
                              }}
                            />
                            <ShowError id={"pickup"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Recogido Por" : "Pickup By"}
                              fullWidth
                              value={pickupBy}
                              onChange={(e) => {
                                setPickupBy(e.target.value);
                              }}
                            />
                            <ShowError id={"pickup_by"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={"CIF"}
                              fullWidth
                              value={cif}
                              onChange={(e) => {
                                setCif(e.target.value);
                              }}
                            />
                            <ShowError id={"CIF"} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Entregado" : "Delivered"}
                              fullWidth
                              value={delivered}
                              onChange={(e) => {
                                setDelivered(e.target.value);
                              }}
                            />
                            <ShowError id={"delivered"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Entregado Por" : "Delivered By"}
                              fullWidth
                              value={deliveredBy}
                              onChange={(e) => {
                                setDeliveredBy(e.target.value);
                              }}
                            />
                            <ShowError id={"delivered_by"} />
                          </Grid>
                          <Grid item lg={4}>
                            <MDInput
                              variant="standard"
                              label={"FOB"}
                              fullWidth
                              value={fob}
                              onChange={(e) => {
                                setFob(e.target.value);
                              }}
                            />
                            <ShowError id={"FOB"} />
                          </Grid>
                        </Grid>
                        <MDInput
                          type="text"
                          label={language == 1 ? "Notas" : "Notes"}
                          variant="standard"
                          multiline
                          rows={2}
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          fullWidth
                        />
                        <Grid container spacing={3} mt={.5}>
                          <Grid item lg={6}>
                            <MDTypography variant={"h5"}>{language == 1 ? "Dirección de Facturación" : "Billing Address"}</MDTypography>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Nombre" : "Name"}
                              fullWidth
                              value={billingAddressName}
                              onChange={(e) => {
                                setBillingAddressName(e.target.value);
                              }}
                            />
                            <ShowError id={"billing_address_name"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Dirección" : "Address"}
                              fullWidth
                              value={billingAddress}
                              onChange={(e) => {
                                setBillingAddress(e.target.value);
                              }}
                            />
                            <ShowError id={"billing_address"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Dirección 2" : "Address 2"}
                              fullWidth
                              value={billingAddress2}
                              onChange={(e) => {
                                setBillingAddress2(e.target.value);
                              }}
                            />
                            <ShowError id={"billing_address2"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Ciudad" : "City"}
                              fullWidth
                              value={billingCity}
                              onChange={(e) => {
                                setBillingCity(e.target.value);
                              }}
                            />
                            <ShowError id={"billing_city"} />
                            <Grid container spacing={2}>
                              <Grid item lg={4}>
                                <MDInput
                                  variant="standard"
                                  label={language == 1 ? "Estado" : "State"}
                                  fullWidth
                                  value={billingState}
                                  onChange={(e) => {
                                    setBillingState(e.target.value);
                                  }}
                                />
                                <ShowError id={"billing_state"} />
                              </Grid>
                              <Grid item lg={8}>
                                <MDInput
                                  variant="standard"
                                  label={"Zipcode"}
                                  fullWidth
                                  value={billingZipcode}
                                  onChange={(e) => {
                                    setBillingZipcode(e.target.value);
                                  }}
                                />
                                <ShowError id={"billing_zipcode"} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6}>
                            <MDTypography variant={"h5"}>{language == 1 ? "Dirección de Entrega" : "Delivery Address"}</MDTypography>
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Nombre" : "Name"}
                              fullWidth
                              value={deliveryAddressName}
                              onChange={(e) => {
                                setDeliveryAddressName(e.target.value);
                              }}
                            />
                            <ShowError id={"delivery_address_name"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Dirección" : "Address"}
                              fullWidth
                              value={deliveryAddress}
                              onChange={(e) => {
                                setDeliveryAddress(e.target.value);
                              }}
                            />
                            <ShowError id={"delivery_address"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Dirección 2" : "Address 2"}
                              fullWidth
                              value={deliveryAddress2}
                              onChange={(e) => {
                                setDeliveryAddress2(e.target.value);
                              }}
                            />
                            <ShowError id={"delivery_address2"} />
                            <MDInput
                              variant="standard"
                              label={language == 1 ? "Ciudad" : "City"}
                              fullWidth
                              value={deliveryCity}
                              onChange={(e) => {
                                setDeliveryCity(e.target.value);
                              }}
                            />
                            <ShowError id={"delivery_city"} />
                            <Grid container spacing={2}>
                              <Grid item lg={4}>
                                <MDInput
                                  variant="standard"
                                  label={language == 1 ? "Estado" : "State"}
                                  fullWidth
                                  value={deliveryState}
                                  onChange={(e) => {
                                    setDeliveryState(e.target.value);
                                  }}
                                />
                                <ShowError id={"delivery_state"} />
                              </Grid>
                              <Grid item lg={8}>
                                <MDInput
                                  variant="standard"
                                  label={"Zipcode"}
                                  fullWidth
                                  value={deliveryZipcode}
                                  onChange={(e) => {
                                    setDeliveryZipcode(e.target.value);
                                  }}
                                />
                                <ShowError id={"delivery_zipcode"} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                    <MDBox mt={1.625} display="flex" justifyContent="right">
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={() => {
                          localStorage.removeItem("purchaseOrderId");
                          localStorage.removeItem("vendorId");
                          navigate("/purchase_orders");
                        }}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                      <MDButton
                        type="button"
                        variant="gradient"
                        className="float-sm-right"
                        color="info"
                        onClick={savePurchaseOrderHeader}
                        disabled={disableButton}
                      >
                        {language == 1 ? "Guardar" : "Save"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Grid>
          <MDBox id="box1" mt={2}>
            <Card>
              <MDBox id="box1" mt={-4.5}>
                {(selectedProducts?.length > 0) &&
                  (!changeTable ? (
                    <MDButton
                      sx={{ ml: 2, mb: -14 }}
                      type="button"
                      variant="gradient"
                      onClick={() => {
                        setChangeTable(true);
                      }}
                      className="float-sm-right"
                      color="info"
                    >
                      {language == 1 ? "Añadir Productos" : "Add Products"}
                    </MDButton>
                  ) : (
                    <MDButton
                      sx={{ mb: -14, ml: 2 }}
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={() => {
                        setChangeTable(false);
                      }}
                    >
                      <Icon>shopping_cart</Icon>
                      &nbsp;{language == 1 ? "Carrito" : "PO Cart"}
                    </MDButton>
                  ))}{" "}
                {selectedProducts?.length > 0 ? (
                  <MDButton
                    sx={{ mb: -14 }}
                    type="button"
                    variant="gradient"
                    className="float-sm-right"
                    color="warning"
                    onClick={() => {
                      setChangeStatus(true);
                    }}
                  >
                    <Icon>shopping_cart</Icon>
                    {language == 1 ? "Confirmar Orden" : "Confirm Order"}
                  </MDButton>
                ) : null}{" "}
                <PurchaseOrderCharges
                  id={localStorage.getItem("purchaseOrderId")}
                  sx={{ ml: selectedProducts?.length > 0 ? 0 : 2 }}
                  charges={charges}
                  currentTotal={getTotal()}
                  setCurrentTotal={setCurrentTotal}
                  setChargeDetails={setChargeDetails}
                  chargeDetails={chargeDetails}
                />{" "}
                <MDButton
                  sx={{ mb: -14 }}
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="dark"
                  onClick={() => {
                    setOpenPopup(!openPopup);
                  }}
                >
                  {language == 1 ? "Compras Dinamicas" : "Dynamic Purchases"}
                </MDButton>
              </MDBox>
              {changeTable ? (
                <DataTable table={vendorProducts} entriesPerPage={false} canSearch />
              ) : (
                <DataTable table={purchaseOProducts} entriesPerPage={false} canSearch />
              )}
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditPurchaseOrder;
