/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
//import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
//import ReactPDF from "@react-pdf/renderer";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import MDInput from "components/MDInput";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Pdf from "react-to-pdf";
import IP from "app/IP";

const axios = require("axios");

function ReportDecommissions() {
    const current = new Date();
    let month = current.getMonth() + 1;
    let day = current.getDate();
    const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
        day < 10 ? `0${day}` : `${day}`
    }`;

    const [menu, setMenu] = useState(null);
    const [report, setReport] = useState({ columns: [], rows: [] });
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([])
    const [responseData, setResponseData] = useState([]);
    const [download, setDownload] = useState(true);
    const [vendors, setVendors] = useState([])
    const [selectedVendor, setSelectedVendor] = useState('')
    const [open, setOpen] = useState(false)
    const [sortSelect, setSortSelect] = useState('')
    const [errors, setErrors] = useState([]);
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const navigate = useNavigate();

    const ref = useRef();

    const options = {
        orientation: "portrait",
        unit: "in",
        format: [20, 10],
    };

    const createPDF = () => {
        setDownload(false)
        const pdf = new jsPDF("landscape", "px", "a0"); //px
        const data = document.querySelector("#pdf");
        pdf.html(data).then(() => {
            pdf.save("decommissions.pdf");
            setDownload(true)
        });
    };


    async function getReportData() {
        await axios
            .get(
                IP + "/reports/decommissions?startDate=" + dateFrom + "&endDate=" + dateTo,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
            .then((response) => {
                setAllData(response.data.data)
                setResponseData(response.data.data);
                console.log(response.data.data);
                let columns = [
                    {
                        Header: language == 1 ? "Código" : "Code",
                        accessor: "code",
                    },
                    {
                        Header: language == 1 ? "Producto" : "Product",
                        accessor: "product",
                    },
                    { Header: language == 1 ? "Decomisado" : "Decommissioned", accessor: "quantity" },
                    { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
                    { Header: language == 1 ? "Usuario" : "User", accessor: "user" },
                    { Header: language == 1 ? "Facturable" : "Billable", accessor: "billable" },
                    // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%" },
                ];
                let rows = response.data.data.map((item) => {
                    return {
                        code: item.product.code,
                        product: item.product.name,
                        quantity: item.quantity,
                        date: item.date,
                        user: item.user.name,
                        billable: item.billable ? (language == 1 ? "Sí" : "Yes") : "No",
                    };
                });

                let excelRows = response.data.data.map((item) => {
                    if (language == 1) {
                        return {
                            Código: item.product.code,
                            Nombre_De_Producto: item.product.name,
                            Cantidad: item.quantity,
                            Fecha: item.date,
                            Usuario: item.user.name,
                            Facturable: item.billable ? 'Sí' : 'No'
                        };
                    } else {
                        return {
                            Code: item.product.code,
                            Product_Name: item.product.name,
                            Quantity: item.quantity,
                            Date: item.date,
                            User: item.user.name,
                            Billable: item.billable ? 'Yes' : 'No'
                        };
                    }
                });
                setErrors([]);

                setData(excelRows);

                let tableData = { columns: columns, rows: rows };
                //console.log(tableData)
                setReport(tableData);
            })
            .catch((error) => {
                // setErrors(error.response.data.data);
                // if (!error.response.data.authenticated) {
                //   navigate("/signIn");
                // }
                console.log(error);
            });
    }

    function TestValidation(message) {
        if (message)
            return message.map((item) => {
                return (
                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{item}</label>
                    </MDTypography>
                );
            });
        else return null;
    }

    function ShowError(param) {
        // console.log(errors["name"]);
        if (param.id) return TestValidation(errors[param.id]);
        else return null;
    }

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDBox display="flex">
                        {data.length > 0 ? (
                            <>
                                <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                                    {language == 1 ? "Organizar" : "Sort"}&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </MDButton>
                                <MDBox ml={1}>
                                    <ExportToExcel apiData={data} fileName="Decommissions"/>
                                </MDBox>
                                <MDBox ml={1}>
                                    <MDButton variant='outlined' color='dark' onClick={createPDF}>Download PDF</MDButton>
                                </MDBox>
                            </>
                        ) : null}
                    </MDBox>
                </MDBox>
                <Card>
                    <MDBox p={3}>
                        <MDTypography variant="h4" sx={{mb: 1}}>
                            {language == 1 ? "Busqueda" : "Search"}
                        </MDTypography>
                        <Grid container spacing={1} sx={{mb: 4}}>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDInput
                                    variant="standard"
                                    type="date"
                                    label={language == 1 ? "Desde" : "From Date"}
                                    fullWidth
                                    value={dateFrom}
                                    onChange={(e) => {
                                        setDateFrom(e.target.value);
                                    }}
                                />
                                <ShowError id={"startDate"}/>
                            </Grid>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDInput
                                    variant="standard"
                                    type="date"
                                    label={language == 1 ? "Hasta" : "To Date"}
                                    fullWidth
                                    value={dateTo}
                                    onChange={(e) => {
                                        setDateTo(e.target.value);
                                    }}
                                />
                                <ShowError id={"endDate"}/>
                            </Grid>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDButton
                                    type="button"
                                    variant="gradient"
                                    className="float-sm-right"
                                    color="info"
                                    onClick={getReportData}
                                >
                                    {language == 1 ? "Busqueda" : "Search"}
                                </MDButton>
                            </Grid>
                        </Grid>
                        <div>
                            <DataTable table={report} entriesPerPage={true} canSearch/>
                        </div>
                    </MDBox>
                </Card>
                <div hidden={download}>
                    <MDBox mt={50}>
                        <div id="pdf">
                            <h3>Agranel - Decommissions Report {dateFrom} - {dateTo}</h3>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>{language == 1 ? "Código" : "Code"}</th>
                                    <th>{language == 1 ? "Producto" : "Product"}</th>
                                    <th>{language == 1 ? "Decomisado" : "Decommissioned"}</th>
                                    <th>{language == 1 ? "Fecha" : "Date"}</th>
                                    <th>{language == 1 ? "Usuario" : "User"}</th>
                                    <th>{language == 1 ? "Facturable" : "Billable"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {responseData.map(item => {
                                    return <tr>
                                        <td>{item.product.code}</td>
                                        <td>{item.product.name}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.date}</td>
                                        <td>{item.user.name}</td>
                                        <td>{item.billable ? (language == 1 ? "Sí" : "Yes") : "No"}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </MDBox>
                </div>
            </MDBox>
        </DashboardLayout>
)
;
}

export default ReportDecommissions;
