/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

import IP from "../IP";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FadeIn from "react-fade-in";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";

const axios = require("axios");

function CreateDecommissions() {
    const [data, setData] = useState({ columns: [], rows: [] });
    const [index, setIndex] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [storeToDelName, setStoreToDelName] = useState(false);
    const [active, setActive] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);
    const [show2, setShow2] = useState(false);
    const toggleSnackbar2 = () => setShow2(!show2);
    const [creating, setCreating] = useState(false)

    const navigate = useNavigate();

    async function getDecommissions() {
        await axios
            .get(IP + "/decommissions", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                let columns = [
                    {
                        Header: language == 1 ? "Código" : "Code",
                        accessor: "code",
                    },
                    {
                        Header: language == 1 ? "Producto" : "Product",
                        accessor: "product",
                    },
                    { Header: language == 1 ? "Decomisado" : "Decommissioned", accessor: "quantity" },
                    { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
                    { Header: language == 1 ? "Usuario" : "User", accessor: "user" },
                    { Header: language == 1 ? "Facturable" : "Billable", accessor: "billable" },
                    // { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%" },
                ];
                let rows = response.data.data.map((item) => {
                    return {
                        code: item.product.code,
                        product: item.product.name,
                        quantity: item.quantity,
                        date: item.date,
                        user: item.user.name,
                        billable: item.billable ? (language == 1 ? "Sí" : "Yes") : "No",
                    };
                });

                let tableData = { columns: columns, rows: rows };

                setData(tableData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if(!creating)
            getDecommissions();
    }, [creating]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                        setCreating(!creating)
                    }}
                >
                    {creating ? (language == 1 ? "Ver decomisaciones" : "View decommissions") : (language == 1 ? "Crear Decomisaciones" : "Create Decommissions")}
                </MDButton>
                <MDTypography variant="button" color="text"></MDTypography>
            </MDBox>
            <Card>
                <h4 style={{marginLeft: 16, marginTop: 16}}>
                    {creating ?
                        (language == 1 ? "Crear Decomisaciones" : "Create Decommissions") :
                        (language == 1 ? "Decomisaciones" : "Decommissions")
                    }
                </h4>
                {creating ? <ProductsTable /> : <DataTable2
                    entriesPerPage={true}
                    index={index}
                    setIndex={setIndex}
                    pageEntries={10}
                    table={data}
                    canSearch
                />}
            </Card>
        </DashboardLayout>
    );
}

function ProductsTable() {
    const current = new Date();
    let month = current.getMonth() + 1;
    const currentDate = `${current.getFullYear()}-${
        month < 10 ? `0${month}` : `${month}`
    }-${current.getDate()}`;
    const [products, setProducts] = useState({columns: [], rows: []});
    const [showModal, setShowModal] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));
    const [index, setIndex] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [notes, setNotes] = useState('')
    const [reason, setReason] = useState('damage')
    const [billable, setBillable] = useState(false)
    const [date, setDate] = useState(currentDate);
    const [errors, setErrors] = useState([]);

    async function getProducts() {
        await axios
            .get(IP + "/products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                let columns = [
                    {
                        Header: language == 1 ? "Código" : "Code",
                        accessor: "code",
                    },
                    {
                        Header: language == 1 ? "Producto" : "Product",
                        accessor: "product",
                    },
                    { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
                    { Header: language == 1 ? "Suplidor" : "Supplier", accessor: "supplier" },
                    { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "20%" },
                ];
                let rows = response.data.data.map((item) => {
                    return {
                        code: item.code,
                        product: item.name,
                        cost: item.cost,
                        supplier: item.vendor.name,
                        action: (
                            <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    onClick={() => {
                                        setShowModal(true)
                                        setSelectedProduct(item);
                                        setQuantity(0)
                                        setBillable(false)
                                        setNotes('')
                                        setReason('damage')
                                        setDate(currentDate)
                                    }}
                                    iconOnly
                                >
                                    <Icon>edit</Icon>
                                </MDButton>
                            </Tooltip>
                        )
                    };
                });

                let tableData = { columns: columns, rows: rows };

                setProducts(tableData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function createDecommission() {
        setErrors([]);
        await axios.post(IP + "/decommissions", {
            product_id: selectedProduct?.id,
            quantity: quantity,
            reason: reason,
            notes: notes,
            billable: billable,
            date: date,
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then(response => {
            setShowModal(false);
        }).catch(error => {
            setErrors(error?.response?.data?.data ?? []);
        })
    }

    useEffect(() => {
        getProducts();
    }, []);

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    function TestValidation(message) {
        if (message)
            return message.map((item) => {
                return (
                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{item}</label>
                    </MDTypography>
                );
            });
        else return null;
    }

    function ShowError(param) {
        // console.log(errors["name"]);
        if (param.id) return TestValidation(errors[param.id]);
        else return null;
    }


    return <>
        <ThemeProvider theme={darkTheme}>
            <Dialog open={showModal} onClose={() => setSelectedProduct(null)}>
                <DialogTitle>
                    <Grid>
                        {language == 1 ? "Producto: " : "Product: "}
                        {selectedProduct?.name} {" "}
                    </Grid>
                    <Grid>
                        {language == 1 ? "Disponible" : "Available"}: {selectedProduct.available}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <RadioGroup
                            row
                            name="Reason"
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value="damage"
                                control={<Radio />}
                                label={language == 1 ? "Dañado" : "Damaged"}
                            />
                            <FormControlLabel
                                value="expiration"
                                control={<Radio />}
                                label={language == 1 ? "Expirado" : "Expired"}
                            />
                            <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label={language == 1 ? "Otro" : "Other"}
                            />
                        </RadioGroup>
                        <ShowError id={'reason'} />
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="quantity"
                        value={quantity}
                        type={'number'}
                        label={language == 1 ? "Cantidad" : "Quantity"}
                        onChange={(e) => {
                            setQuantity(e.target.value);
                        }}
                        fullWidth
                        variant="standard"
                    />
                    <ShowError id={'quantity'} />
                    <TextField
                        margin="dense"
                        id="notes"
                        value={notes}
                        label={language == 1 ? "Notas" : "Notes"}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                        fullWidth
                        variant="standard"
                    />
                    <ShowError id={'notes'} />
                    <TextField
                        margin="dense"
                        id="notes"
                        value={date}
                        type={'date'}
                        label={language == 1 ? "Fecha" : "Date"}
                        onChange={(e) => {
                            setDate(e.target.value);
                        }}
                        fullWidth
                        variant="standard"
                    />
                    <ShowError id={'date'} />
                    <MDTypography variant="body">
                        {language == 1 ? "¿Se factura al suplidor?" : "Can be billed to supplier?"}{" "}
                        <Checkbox checked={billable} onChange={() => setBillable(!billable)} />
                    </MDTypography>
                    {billable && <MDTypography variant="subtitle2" style={{color: 'red'}}>
                        {language == 1 ? "Recuerde crear una factura al suplidor" : "Remember to bill the supplier"}{" "}
                    </MDTypography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
                    <Button
                        disabled={quantity <= 0 || quantity == '' || quantity == null}
                        onClick={() => {
                            swal(
                                language == 1
                                    ? `¿Estás seguro de que quieres decomisar ${quantity} de ${selectedProduct?.name}?`
                                    : `Are you sure you want to decommission ${quantity} of ${selectedProduct?.name}?`,
                                billable ? (language == 1 ? "Recuerde crear una factura al suplidor" : "Remember to bill the supplier") : "",
                                "warning",
                                {
                                    buttons: {
                                        cancel: language == 1 ? "Cancelar" : "Cancel",
                                        confirm: language == 1 ? "Confirmar" : "Confirm",
                                    },
                                }
                            ).then((willConfirm) => {
                                if (willConfirm) {
                                    createDecommission();
                                }
                            });

                        }}
                    >
                        {language == 1 ? "Guardar" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
        <DataTable2
            entriesPerPage={true}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            table={products}
            canSearch
        />
    </>
}

export default CreateDecommissions;
