import { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { flushSync } from "react-dom";
import dayjs from "dayjs";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "components/CustomDatePicker";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import MDInput from "components/MDInput";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import DataTable2 from "app/components/shopperTable";

// API
import IP from "app/IP";
import axios from "axios";

const OfferModal = ({ open, onClose, language, editMode, vendors, offer_data, runFunction }) => {
  const current = new Date();
  let month = current.getMonth() + 1;
  let day = current.getDate();
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
  const [errors, setErrors] = useState([]);
  const [showNotif, setShowNotif] = useState(false);
  const [openFamilyPopup, setOpenFamilyPopup] = useState(false);
  const [openProductList, setOpenProductList] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [familyID, setFamilyID] = useState();
  const [productList, setProductList] = useState([]);
  const productListRef = useRef([]);
  const [productFamilyList, setProductFamilyList] = useState([]);
  const [selectedFamilyProducts, setSelectedFamilyProducts] = useState([]);
  const [upcList, setUpcList] = useState([]);
  const [selectedUpc, setSelectedUpc] = useState("");
  const [selectedSupplierFilter, setSelectedSupplierFilter] = useState("");
  const [offerId, setOfferId] = useState();
  const [selectedOfferType, setSelectedOfferType] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState("");
  const [isFamily, setIsFamily] = useState(false);
  const [code, setCode] = useState("");
  const [upc, setUPC] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [offers, setOffers] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [netWorth, setNetWorth] = useState("");
  const [by, setBy] = useState({ id: 1, label: "Regular" });
  const [cg, setCg] = useState();
  const [c, setC] = useState(0);
  //const [g, setCg] = useState(0);
  const [percent, setPercent] = useState("");
  const [offer, setOffer] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  //const [editMode, setEditMode] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyCode, setFamilyCode] = useState("");
  const [familyUPC, setFamilyUPC] = useState("");
  const [familyDescription, setFamilyDescription] = useState("");
  const [relatedProductList, setRelatedProductList] = useState([]);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState("");
  const isAllSelectedProducts =
    relatedProductList.length > 0 && setSelectedRelatedProduct.length === relatedProductList.length;
  const [index, setIndex] = useState(0);
  const [offerData, setOfferData] = useState({ columns: [], rows: [] });
  const [productData, setProductData] = useState({ columns: [], rows: [] });
  const [relatedProductData, setRelatedProductData] = useState({ columns: [], rows: [] });

  const handleFamilyPopupClose = () => {
    setOpenFamilyPopup(false);
    setErrors([]);
  };

  const handleProductListPopupClose = () => {
    setOpenProductList(!openProductList);
  };

  const handleClickOpen = () => {
    setOpenDialog(true); //opens dialog model
    //setEditMode(mode); //sets whether the dialog will be in create or edit mode.
  };

  const handleClose = () => {
    onClose();
    productListRef.current = [];
    setProductList([]);
    setErrors([]);
    setSelectedOfferType("");
    setSelectedProduct("");
    setSelectedFamily("");
    setSelectedSupplier("");
    setCode("");
    setUPC("");
    setDescription("");
    setCost("");
    setOffers("");
    setNetWorth("");
    setBy("");
    setC("");
    setCg("");
    setPercent("");
    setOffer();
    setFromDate();
    setToDate();
    setIsFamily(false);
  };

  const resetModal = () => {
    //productListRef.current = [];
    //setProductList([]);
    setErrors([]);
    setSelectedOfferType("");
    //setSelectedProduct("");
    setSelectedFamily("");
    //setSelectedSupplier("");
    //setCode("");
    //setUPC("");
    setDescription("");
    //setCost("");
    //setOffers("");
    //setNetWorth("");
    setBy("");
    setC("");
    setCg("");
    setPercent("");
    setOffer();
    setFromDate();
    setToDate();
    setIsFamily(false);
  };

  //Clear basic fields
  const clearFields = () => {
    setCode("");
    setUPC("");
    setCost("");
    setOffers("");
    setNetWorth("");
    setC("");
    setCg("");
    setPercent("");
    setOffer("");
  };

  //Check if product has a family
  async function findProductFamily(product) {
    console.log("FindProductFamily ID?", product);
    await axios
      .post(
        IP + "/product_families/findProductFamily",
        { product_id: product },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response Find Product Family:", response);
        const prodResponse = response.data.map((prod) => {
          return {
            id: prod.product.product_id,
            label:
              prod.product.code +
              " - " +
              prod.product.upc +
              " - " +
              prod.product.name +
              " - " +
              prod.product.packaging,
            family_id: prod.family_id,
            name: prod.product.name,
            upc: prod.product.upc,
            code: prod.product.code,
            cost: prod.product.cost,
          };
        });
        console.log("Product Family Response:", prodResponse);
        setProductFamilyList(prodResponse);
        //console.log("Contract Products Ids Array Hook:", contractProductIds);
        if (response.data.length > 0) {
          setFamilyID(response.data[0].family_id);
          //setContractProductData(product_data);
          setOpenFamilyPopup(!openFamilyPopup);
        } else {
          //addContractProducts(product_data);
          createOffer({
            vendor_id: selectedSupplier.id,
            product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
            family_id: selectedFamily ? selectedFamily.id : null,
            offer_type_id: selectedOfferType.id,
            code: code,
            upc: upc,
            description: description,
            cost: cost,
            offers: offers,
            net_worth: netWorth,
            by_id: 0,
            c: c,
            g: cg,
            percent: percent,
            offer: offer,
            from_date: fromDate,
            to_date: toDate,
          });
        }
      })
      .catch((error) => {
        console.log("Error Find Product Family:", error);
      });
  }

  async function storeUniqueProductFamilyOffers(offer) {
    console.log("storeUniqueProductFamilyOffers:", offer);
    await axios
      .post(IP + "/offers/storeUniqueProductFamilyOffers", offer, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("storeUniqueProductFamilyOffers Response:", response);
        //setOpenFamilyPopup(!openFamilyPopup);
        //handleClose();
        resetModal();
        runFunction();
        //getOffers();
      })
      .catch((error) => {
        console.log("storeUniqueProductFamilyOffers ERROR:", error);
      });
  }

  //Get list of families
  async function getProductOfFamily(product_id, offer_sum) {
    console.log("product id for get product of family:", product_id);
    await axios
      .post(IP + "/offers/getProductOfFamily", product_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("get Product of family:", response);
        let family_product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.product_id + " " + prod.name,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list from family:", family_product_list);
        setCost(family_product_list[0].cost);
        console.log("Offer Sum from Family:", offer_sum);
        console.log("Result of sum:", family_product_list[0].cost - offer_sum);
        //if (family_product_list[0].cost > 0 && offers > 0) {
        let result = family_product_list[0].cost - offer_sum;
        setNetWorth(result.toFixed(2));
        // } else {
        //   setNetWorth("");
        // }
        //setProducts(family_product_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getProductListFromOffer(offer_id) {
    await axios
      .post(IP + "/offers/getProductListFromOffer", offer_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Response getProductListFromOffer", response.data);

        let productListMap = response.data.map((prod) => {
          return {
            id: prod.product_id,
            upc: prod.product.upc,
            name: prod.product.name,
            packaging: prod.product.packaging,
          };
        });

        productListRef.current = productListMap;
        setProductList(productListMap);
      })
      .catch((error) => {
        console.log("Error getProductListFromOffer", error);
      });
  }

  //Create an offer
  async function createOffer(offer_data) {
    await axios
      .post(IP + "/offers/createOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("createOffer Response:", response);
        setShowNotif(!showNotif);
        //handleClose();
        resetModal();
        runFunction();
        //getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("createOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  async function editOffer(offer_data) {
    //console.log("PRODUCT_INFO:", product_info);
    await axios
      .post(IP + "/offers/editOffer", offer_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("editOffer Response:", response);
        setShowNotif(!showNotif);
        handleClose();
        runFunction();
        //getOffers();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("editOffer Error:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
      });
  }

  //   async function GetVendors() {
  //     await axios
  //       .get(IP + "/vendors", {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //           Accept: "application/json",
  //         },
  //       })
  //       .then(function (response) {
  //         console.log("Get Vendors Response:", response.data.data);

  //         let vendor = response.data.data.map((item) => {
  //           return {
  //             id: item.id,
  //             label: item.id + " " + item.name,
  //           };
  //         });

  //         setSuppliers(vendor);
  //       })
  //       .catch(function (error) {
  //         if (!error.response.data.authenticated) {
  //           navigate("/signIn");
  //         }
  //         console.log("Get Vendors ERROR:", error);
  //       });
  //   }

  //
  async function getOfferFromProduct(data, fromDate, toDate, prod_cost) {
    let prod_data = {
      upc: data.upc,
      product_id: data.id,
      from_date: fromDate,
      to_date: toDate,
    };

    console.log("prod_data:", prod_data);

    try {
      const response = await axios.post(IP + "/offers/getOfferFromProduct", prod_data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      });

      console.log("getOfferFromProduct Response:", response);

      if (response.data.length > 0) {
        const offerInvoiceArray = response.data.filter((item) => item.offer_type_id === 1);
        const tempOfferArray = response.data.filter((item) => item.offer_type_id === 4);
        const billbackArray = response.data.filter((item) => item.offer_type_id === 2);
        const cajaGratis = response.data.filter((item) => item.offer_type_id === 3);

        const offerInvoice = parseFloat(offerInvoiceArray[0]?.offer ?? 0);
        const tempOffer = parseFloat(tempOfferArray[0]?.offer ?? 0);
        const billback = parseFloat(billbackArray[0]?.offer ?? 0);
        const cajaGratisSum = cajaGratis[0]?.g + cajaGratis[0]?.c;

        console.log("offer Invoice:", offerInvoice);
        console.log("tempOffer:", tempOffer);
        console.log("cajaGratis:", cajaGratis);
        console.log("cajaGratisSum:", cajaGratisSum);
        console.log("billback:", billback);

        let costDiscount = (
          ((parseFloat(prod_cost || 0) - (offerInvoice || 0) - (tempOffer || 0) - (billback || 0)) *
            (cajaGratis?.[0]?.c ?? 1)) /
            (cajaGratis?.[0]?.g ?? 1) +
          (cajaGratis?.[0]?.c ?? 1)
        ).toFixed(2);

        console.log("costDiscount:", costDiscount);

        // ✅ Use flushSync to update state immediately after async operation completes
        flushSync(() => {
          setNetWorth(costDiscount);

          let offerSum = (prod_cost - costDiscount).toFixed(2);
          console.log("offerSum:", offerSum);
          setOffers(offerSum);
        });
      }
    } catch (error) {
      console.error("getOfferFromProduct error:", error);
    }
  }

  //Get products by vendor id
  async function getProducts(vendor) {
    await axios
      .post(IP + "/offers/getProductsByVendor", vendor, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get Products Response:", response);
        let product_list = response.data.map((prod) => {
          return {
            id: prod.product_id,
            label: prod.code + " - " + prod.upc + " - " + prod.name + " - " + prod.packaging,
            name: prod.name,
            upc: prod.upc,
            code: prod.code,
            cost: prod.cost,
          };
        });
        console.log("Mapped product list:", product_list);
        setProducts(product_list);
        let upc_list = response.data.map((prod) => {
          return {
            id: prod.upc,
            label: prod.upc,
          };
        });
        setUpcList(upc_list);
      })
      .catch((error) => {
        console.log("products error:", error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            {/* <label>{item}</label> */}
            <label>{language == 1 ? "El campo es requerido." : "The field is required."}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  //Inserts the checkbox values into the array of jsons
  const handleAllCheck = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values:", selectedFamilyProducts);

    if (value === "all") {
      setSelectedFamilyProducts(
        selectedFamilyProducts.length === productFamilyList.length
          ? []
          : productFamilyList.map((val) => (!val.disabled ? val.value : null)) //This will not check any disabled checkboxes or checkboxes with disabled field.
        //: requirementsList.map((val) => val.value)
      );
      return;
    }

    const list = [...selectedFamilyProducts];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected family products into List:", list);
    setSelectedFamilyProducts(list);
  };

  //Inserts the checkbox products values into the array of jsons
  const handleAllCheckProducts = (event) => {
    const value = event.target.value === "all" ? event.target.value : parseInt(event.target.value);
    console.log(event);
    //console.log("Venodors List", vendorList);
    console.log("Checked 2:", value);
    //alert("Checked stringify:", JSON.stringify(value));
    console.log("Selected values 2:", setSelectedRelatedProduct);

    if (value === "all") {
      setSelectedRelatedProduct(
        setSelectedRelatedProduct.length === relatedProductList.length
          ? []
          : relatedProductList.map((val) => val.value) //do NOT add ".value" to vendorList else you'll get undefined error
      );
      return;
    }

    const list = [...setSelectedRelatedProduct];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    console.log("Selected requirements into List 2:", list);
    setSelectedRelatedProduct(list);
  };

  useEffect(() => {
    console.log("Offer Data:", offer_data);

    if (open) {
      if (editMode) {
        getOfferFromProduct(
          offer_data.selectedProduct,
          offer_data.fromDate,
          offer_data.toDate,
          offer_data.cost
        ).then(() => {
          flushSync(() => {
            setSelectedSupplier(offer_data.selectedSupplier);
            setSelectedProduct(offer_data.selectedProduct);
            setSelectedFamily(offer_data.selectedFamily);
            setSelectedOfferType(offer_data.selectedOfferType);
            setCode(offer_data.code);
            setUPC(offer_data.upc);
            setDescription(offer_data.description);
            setCost(offer_data.cost);
            setBy(0);
            setC(offer_data.c);
            setCg(offer_data.g);
            setPercent(offer_data.percent);
            setOffer(offer_data.offer);
            setFromDate(offer_data.fromDate);
            setToDate(offer_data.toDate);
          });
        });
      } else {
        flushSync(() => {
          setSelectedSupplier("");
          setSelectedProduct("");
          setSelectedFamily("");
          setSelectedOfferType("");
          setCode("");
          setUPC("");
          setDescription("");
          setCost("");
          setOffers("");
          setNetWorth("");
          setBy("");
          setC("");
          setCg("");
          setPercent("");
          setOffer("");
          setFromDate(null);
          setToDate(null);
        });
      }
    }
  }, [open, editMode, offer_data]);

  useEffect(() => {
    productListRef.current = [];
    setProductList([]);
    console.log("editMode:", editMode);
    if (editMode) {
      getProductListFromOffer({ offer_id: offer_data.offer_id });
    }
    console.log("Product List:", productList);
    console.log("Product List Ref:", productListRef.current);
  }, [open]);

  useEffect(() => {
    if (showNotif) {
      const timer = setTimeout(() => {
        setShowNotif(false);
      }, 3000); // ✅ Close after 3 seconds

      // ✅ Cleanup to avoid memory leaks
      return () => clearTimeout(timer);
    }
  }, [showNotif]);

  return (
    <>
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Oferta creado exitosamente!" : "Offer Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={showNotif}
        close={() => {
          setShowNotif(!showNotif);
        }}
      />
      <Dialog
        fullWidth="md"
        maxWidth="md"
        open={openProductList}
        onClose={handleProductListPopupClose}
      >
        {" "}
        <DialogTitle>{language == 1 ? "Productos" : "Products"}</DialogTitle>
        <DialogContent dividers>
          <Grid lg={"auto"}>
            <List
              sx={{
                width: "100%",
                //maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 300,
                "& ul": { padding: 0 },
              }}
            >
              <List subheader={<ListSubheader>UPC - Name - Packaging</ListSubheader>}>
                {productListRef.current.map((item) => (
                  <ListItem key={item}>
                    <ListItemText primary={`${item.upc} - ${item.name} - ${item.packaging}`} />
                  </ListItem>
                ))}
              </List>
            </List>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth="md" maxWidth="md" open={openFamilyPopup} onClose={handleFamilyPopupClose}>
        {" "}
        <DialogTitle>{language ? "Familia encontrada" : "Family found"}</DialogTitle>
        <DialogContent dividers>
          <Grid lg={"auto"}>
            <MDTypography>
              {language
                ? "Selecciona los productos a los que deseas aplicar la oferta:"
                : "Select the products you wish to apply the offer:"}
            </MDTypography>
          </Grid>
          <Grid lg={"auto"}>
            <List
              sx={{
                display: "flex",
                flexFlow: "column wrap",
                // height: 300, // set the height limit to your liking
                // width: 300,
              }}
            >
              {productFamilyList.map((item) => {
                return (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton disabled={item.disabled} dense>
                      <Checkbox
                        //disabled={item.disabled}
                        onChange={handleAllCheck}
                        value={item.id}
                        disableRipple
                      />
                      <ListItemText id={item.id} primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenFamilyPopup(!openFamilyPopup);
              createOffer({
                vendor_id: selectedSupplier.id,
                product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                family_id: selectedFamily ? selectedFamily.id : null,
                offer_type_id: selectedOfferType.id,
                code: code,
                upc: upc,
                description: description,
                cost: cost,
                offers: offers,
                net_worth: netWorth,
                by_id: 0,
                c: c,
                g: cg,
                percent: percent,
                offer: offer,
                from_date: fromDate,
                to_date: toDate,
              });
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenFamilyPopup(!openFamilyPopup);
              storeUniqueProductFamilyOffers({
                product_list: selectedFamilyProducts,
                family_id: familyID,
                vendor_id: selectedSupplier.id,
                product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                //family_id: selectedFamily ? selectedFamily.id : null,
                offer_type_id: selectedOfferType.id,
                code: code,
                upc: upc,
                description: description,
                cost: cost,
                offers: offers,
                net_worth: netWorth,
                by_id: 0,
                c: c,
                g: cg,
                percent: percent,
                offer: offer,
                from_date: fromDate,
                to_date: toDate,
                //product_id: contractProductIds,
                //product_data: product_data,
              });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth="lg" maxWidth="lg" open={open} onClose={onClose}>
        {" "}
        <DialogTitle>
          {language == 1
            ? editMode
              ? "Editar Oferta"
              : "Crear Oferta"
            : editMode
            ? "Edit Offer"
            : "Create Offer"}
        </DialogTitle>
        <DialogContent dividers spacing={2} sx={{ paddingRight: "30px" }}>
          <Grid item lg={8}>
            <VirtualizedAutocomplete
              sx={{ m: 1, width: "100%" }}
              options={[
                { id: 1, label: "Offer Invoice" },
                { id: 2, label: "Billback" },
                { id: 3, label: "C/G" },
                { id: 4, label: "Off/Temp" },
              ]}
              getOptionsLabel={(options) => options.label || ""}
              value={selectedOfferType}
              onChange={(event, newValue) => {
                console.log("selected offer type:", newValue);
                if (newValue == null) {
                  setSelectedOfferType("");
                } else {
                  setSelectedOfferType(newValue);
                  setC("");
                  setCg("");
                  setOffer("");
                  setPercent("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Tipo de Oferta" : "Offer type"}
                  variant="outlined"
                />
              )}
            />
            <ShowError id={"offer_type_id"} />
          </Grid>
          <Grid item lg={8}>
            <VirtualizedAutocomplete
              sx={{ m: 1, width: "100%" }}
              componentsProps={{
                paper: {
                  sx: {
                    width: "100%", // Set the width to take all the empty space
                  },
                },
              }}
              options={vendors}
              getOptionsLabel={(options) => options.label || ""}
              value={selectedSupplier}
              onChange={(event, newValue) => {
                console.log("selected supplier:", newValue);
                if (newValue == null) {
                  setSelectedSupplier("");
                  setSelectedProduct("");
                  setSelectedFamily("");
                  clearFields();
                } else {
                  setSelectedSupplier(newValue);
                  setSelectedProduct("");
                  setSelectedFamily("");
                  clearFields();
                  getProducts({ vendor_id: newValue.id });
                  //getFamilies({ vendor_id: newValue.id });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Suplidor" : "Supplier"}
                  variant="outlined"
                />
              )}
            />
            <ShowError id={"vendor_id"} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={8}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                componentsProps={{
                  paper: {
                    sx: {
                      width: "100%", // Set the width to take all the empty space
                    },
                  },
                }}
                options={products}
                getOptionsLabel={(options) => options.label || ""}
                value={selectedProduct}
                onChange={(event, newValue) => {
                  console.log("selected product:", newValue);
                  if (newValue == null) {
                    setSelectedProduct("");
                    setCode("");
                    setUPC("");
                    setCost("");
                    clearFields();
                  } else {
                    setSelectedProduct(newValue);
                    clearFields();
                    let offerSum = offerList
                      .filter((item) => item.product_id == newValue.id)
                      .reduce(
                        (total, currentValue) => (total = total + parseFloat(currentValue.offer)),
                        0
                      );
                    console.log("Offer List:", offerList);
                    setOffers(offerSum.toFixed(2));
                    setNetWorth((newValue.cost - offerSum).toFixed(2));
                    setCode(newValue.code);
                    setUPC(newValue.upc);
                    setCost(newValue.cost);
                    // if (newValue.cost > 0 && offers > 0) {
                    //   let result = newValue.cost - offerSum;
                    //   setNetWorth(result);
                    // } else {
                    //   setNetWorth("");
                    // }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language == 1 ? "Producto" : "Product"}
                    variant="outlined"
                  />
                )}
              />
              <ShowError id={"product_id"} />
            </Grid>
            {/* <Grid item lg={"auto"}>
              <FormGroup sx={{ mt: 2, ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFamily}
                      onChange={() => {
                        setIsFamily(!isFamily);
                      }}
                    />
                  }
                  label="Is Family"
                />
              </FormGroup>
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <MDInput
                variant="outlined"
                type="text"
                //disabled
                label={language == 1 ? "Codigo" : "Code"}
                sx={{ m: 1, width: "100%" }}
                value={code}
                // onChange={(e) => {
                //   setCode(e.target.value);
                // }}
              />
              <ShowError id={"code"} />
            </Grid>
            <Grid item lg={4}>
              <MDInput
                variant="outlined"
                type="text"
                label="UPC"
                //disabled
                sx={{ m: 1, width: "100%" }}
                value={upc}
                // onChange={(e) => {
                //   setUPC(e.target.value);
                // }}
              />
              <ShowError id={"upc"} />
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <MDInput
              variant="outlined"
              type="text"
              label={language == 1 ? "Descripcion" : "Description"}
              sx={{ m: 1, width: "100%" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <ShowError id={"description"} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Costo" : "Cost"}
                sx={{ m: 1, width: "100%" }}
                value={cost}
              />
              <ShowError id={"cost"} />
            </Grid>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Ofertas" : "Offers"}
                sx={{ m: 1, width: "100%" }}
                value={offers}
              />
            </Grid>
            <Grid item lg={3}>
              <MDInput
                variant="outlined"
                type="text"
                label={language == 1 ? "Costo Neto" : "Net worth"}
                sx={{ m: 1, width: "100%" }}
                value={netWorth}
                // onChange={(e) => {
                //   setNetWorth(e.target.value);
                // }}
              />
              <ShowError id={"net_worth"} />
            </Grid>
            {/* <Grid item lg={3}>
              <VirtualizedAutocomplete
                sx={{ m: 1, width: "100%" }}
                options={[{ id: 1, label: "Regular" }]}
                getOptionsLabel={(options) => options.label || ""}
                value={by}
                onChange={(event, newValue) => {
                  console.log("selected by:", newValue);
                  setBy(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={language == 1 ? "Por" : "By"} variant="outlined" />
                )}
              />
              <ShowError id={"by_id"} />
            </Grid> */}
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
            {selectedOfferType.id == 3 ? (
              <>
                <Grid item lg={1}>
                  <MDInput
                    variant="outlined"
                    type="text"
                    label="C/G"
                    sx={{ m: 1, width: "100%" }}
                    value={cg}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9]*/g, "");
                      if (selectedOfferType.label == "C/G") {
                        setCg(value);
                      }
                    }}
                    onBlur={(e) => {
                      if (selectedOfferType.label == "C/G") {
                        if (cost > 0 && c > 0 && cg > 0) {
                          let c_num = Number(c);
                          let cg_num = Number(cg);

                          console.log("cost", cost);
                          console.log("c", c_num);
                          console.log("cg", cg_num);

                          let net = cost * cg_num;
                          let totalbox = c_num + cg_num;
                          let result = net / totalbox;

                          console.log("net res:", net);
                          console.log("totalbox", totalbox);
                          console.log("result", result);

                          setOffer(result.toFixed(2));
                          let percent_result = (result / cost) * 100;
                          let round_result = Math.round(percent_result * 1000) / 1000;
                          console.log("Percent result", round_result);
                          setPercent(round_result);
                        } else {
                          setOffer("");
                          setPercent("");
                        }
                      }
                    }}
                  />
                  <ShowError id={"g"} />
                </Grid>
                <Grid item lg={1}>
                  <MDInput
                    variant="outlined"
                    type="text"
                    label="C"
                    sx={{ m: 1, width: "100%" }}
                    value={c}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9]*/g, "");
                      if (selectedOfferType.label == "C/G") {
                        setC(value);
                      }
                    }}
                    onBlur={(e) => {
                      if (selectedOfferType.label == "C/G") {
                        if (cost > 0 && c > 0 && cg > 0) {
                          let c_num = Number(c);
                          let cg_num = Number(cg);

                          console.log("cost", cost);
                          console.log("c", c_num);
                          console.log("cg", cg_num);

                          let net = cost * cg_num;
                          let totalbox = c_num + cg_num;
                          let result = net / totalbox;

                          console.log("net res:", net);
                          console.log("totalbox", totalbox);
                          console.log("result", result);

                          setOffer(result.toFixed(2));
                          let percent_result = (result / cost) * 100;
                          let round_result = Math.round(percent_result * 1000) / 1000;
                          console.log("Percent result", round_result);
                          setPercent(round_result);
                        } else {
                          setOffer("");
                          setPercent("");
                        }
                      }
                    }}
                  />
                  <ShowError id={"c"} />
                </Grid>
              </>
            ) : null}
            <Grid item lg={2}>
              <MDInput
                variant="outlined"
                type="text"
                label="%"
                sx={{ m: 1, width: "100%" }}
                value={percent}
                onChange={(e) => {
                  setPercent(e.target.value);
                }}
              />
              <ShowError id={"percent"} />
            </Grid>
            <Grid item lg={2}>
              <MDInput
                variant="outlined"
                type="number"
                label={language == 1 ? "Oferta" : "Offer"}
                sx={{ m: 1, width: "100%" }}
                value={offer ?? ""} // Fix potential null value issue
                //value={offer === null ? "" : offer} // Allow empty value when clearing
                onChange={(e) => {
                  const inputValue = e.target.value === "" ? null : parseFloat(e.target.value); // Handle empty state
                  const costValue = parseFloat(cost);
                  console.log("Cost:", costValue);
                  console.log("Input value:", inputValue);

                  // Allow empty input OR value within the valid range
                  if (inputValue === null || inputValue <= costValue) {
                    setOffer(inputValue);
                  } else {
                    alert(`The offer value cannot be greater than the cost (${costValue}).`);
                  }
                }}
                onBlur={(e) => {
                  const offerValue = parseFloat(e.target.value);
                  const costValue = parseFloat(cost);
                  if (!isNaN(offerValue) && offerValue > 0 && costValue > 0) {
                    let result = (offerValue / costValue) * 100;
                    let round_result = Math.round(result * 1000) / 1000;
                    setPercent(round_result);
                  } else {
                    setPercent("");
                  }
                }}
              />
              <ShowError id={"offer"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Desde" : "From"}
                sx={{ m: 1, width: "100%" }}
                value={fromDate}
                maxDate={toDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
              />
              <ShowError id={"from_date"} />
            </Grid>
            <Grid item lg={3}>
              <CustomDatePicker
                label={language == 1 ? "Hasta" : "To"}
                sx={{ m: 1, width: "100%" }}
                value={toDate}
                minDate={fromDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
              />
              <ShowError id={"to_date"} />
            </Grid>
            {productList.length > 0 && (
              <Grid item lg={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    //handleClickOpen(false);
                    setOpenProductList(true);
                  }}
                >
                  {language == 1 ? "View products" : "Ver productos"}
                </MDButton>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
          <Button
            type="submit"
            onClick={() => {
              //If the dialog popup is open from the edit button it will run the following edit function otherwise it'll run the create one.
              if (editMode) {
                editOffer({
                  offer_id: offer_data.offer_id,
                  vendor_id: selectedSupplier.id,
                  product_id: selectedProduct ? selectedProduct.id : selectedFamily.product_id,
                  family_id: selectedFamily ? selectedFamily.id : null,
                  offer_type_id: selectedOfferType.id,
                  code: code,
                  upc: upc,
                  description: description,
                  cost: cost,
                  offers: offers,
                  net_worth: netWorth,
                  by_id: 0,
                  c: c,
                  g: cg,
                  percent: percent,
                  offer: offer,
                  from_date: fromDate,
                  to_date: toDate,
                });
              } else {
                findProductFamily(selectedProduct.id);
              }
            }}
          >
            {language == 1 ? (editMode ? "Guardar" : "Crear") : editMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfferModal;
