import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

const EditableRow = ({ editFormData, handleEditFormChange, handleCancelClick }) => {
  const [language, setLanguage] = React.useState(localStorage.getItem("Language"));
  return (
    <TableRow>
      <DataTableBodyCell>{editFormData.fullName}</DataTableBodyCell>
      <DataTableBodyCell>{editFormData.address}</DataTableBodyCell>
      <DataTableBodyCell>{editFormData.phoneNumber}</DataTableBodyCell>
      <DataTableBodyCell>
        <MDInput
          required="required"
          placeholder="Enter Payment Value..."
          name="email"
          value={editFormData.email}
          onChange={(e)=>{
            if (e.target.value >= 0)
              handleEditFormChange(e)
          }}
        ></MDInput>
      </DataTableBodyCell>
      <DataTableBodyCell>
        <MDButton variant="gradient" id={"id" + editFormData.address} color="info" type="submit">
          {language==1 ? "Guardar" : "Save"}
        </MDButton>{" "}
        <MDButton variant="gradient" color="light" type="button" onClick={handleCancelClick}>
          {language==1 ? "Cancelar" : "Cancel"}
        </MDButton>
      </DataTableBodyCell>
    </TableRow>
  );
};

export default EditableRow;
