/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, Navigate } from "react-router-dom";

import MDSnackbar from "components/MDSnackbar";

import IP from "../IP";

const axios = require("axios");

function CreateVendors() {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [dataToShow, setDataToShow] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [storeName, setStoreName] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeAddress2, setStoreAdress2] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [contact, setContact] = useState("");
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState([])
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null)
  const [vendorNumber, setVendorNumber] = useState("");
  const navigate = useNavigate();

  async function GetCities(id) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/countries/" + id + "/states", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setCities(
          response.data.data.map((item) => {
            return { label: item.name, id: item.city_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCountries() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/countries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setCountries(
          response.data.data.map((item) => {
            return { label: item.name, id: item.country_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getPaymentTerms() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/payment-terms", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setPaymentTerms(
          response.data.data.map((item) => {
            return { label: item.name, id: item.payment_term_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function CreateVendor() {
    setDisableButton(true);
    await axios
      .post(
        IP + "/vendors",
        {
          name: storeName,
          address1: storeAddress,
          address2: storeAddress2,
          zipcode: zipcode,
          phone: phone,
          contact_name: contact,
          contact_phone: phone2,
          contact_email: storeEmail,
          city_id: selectedCity?.id,
          vendor_number: vendorNumber,
          country_id: selectedCountry?.id,
          payment_term_id: selectedPaymentTerm?.id,
          active: true,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        //console.log(response.data.data);
        toggleSnackbar();
        setTimeout(() => {
          navigate("/emos/viewVendors");
        }, 3000);
      })
      .catch(function (error) {
        setDisableButton(false);
        console.log(error);
        setErrors(error.response.data.data);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  useEffect(() => {
    // GetCities();
    getPaymentTerms();
    GetCountries();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Vendedor Creado Exitosamente!" : "Vendor Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox p={3}>
                <MDBox>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">
                        {language == 1 ? "Crear Vendedor" : "Create Vendor"}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {language == 1
                          ? "Favor de Entrar la Información del Vendedor."
                          : "Please enter vendor information."}
                      </MDTypography>
                    </MDBox>
                    <MDBox id="box1" mt={1.625}>
                      <Grid id="grid1" container spacing={3}>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Nombre de Vendedor" : "Vendor Name"}
                            fullWidth
                            value={storeName}
                            onChange={(e) => {
                              setStoreName(e.target.value);
                            }}
                          />
                          <ShowError id={"name"} />
                        </Grid>
                        <Grid id="grid1-1" item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Número de Vendedor" : "Vendor Number"}
                            fullWidth
                            value={vendorNumber}
                            onChange={(e) => {
                              if (e.target.value.match(/^[0-9]+$/) || e.target.value == "")
                                setVendorNumber(e.target.value);
                            }}
                          />
                          <ShowError id={"vendor_number"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="Email"
                            fullWidth
                            value={storeEmail}
                            onChange={(e) => {
                              if (!e.target.value.includes(" ")) setStoreEmail(e.target.value);
                            }}
                          />
                          <ShowError id={"contact_email"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedPaymentTerm}
                            onChange={(event, newValue) => {
                              setSelectedPaymentTerm(newValue);
                            }}
                            options={paymentTerms}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={language == 1 ? "Término de Pago" : "Payment Term"}
                                variant="standard"
                              />
                            )}
                          />
                          <ShowError id={"payment_term_id"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Dirección Linea 1" : "Address Line 1"}
                            fullWidth
                            value={storeAddress}
                            onChange={(e) => {
                              setStoreAddress(e.target.value);
                            }}
                          />
                          <ShowError id={"address1"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Dirección Linea 2" : "Address Line 2"}
                            fullWidth
                            value={storeAddress2}
                            onChange={(e) => {
                              setStoreAdress2(e.target.value);
                            }}
                          />
                          <ShowError id={"address2"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedCountry}
                            onChange={(event, newValue) => {
                              setSelectedCountry(newValue);
                              setSelectedCity();
                              GetCities(newValue.id);
                            }}
                            options={countries}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={language == 1 ? "Pais" : "Country"}
                                variant="standard"
                              />
                            )}
                          />
                          <ShowError id={"country_id"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disablePortal
                            value={selectedCity}
                            onChange={(event, newValue) => {
                              console.log(newValue);

                              setSelectedCity(newValue);
                            }}
                            options={cities}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={language == 1 ? "Ciudad" : "City"}
                                variant="standard"
                              />
                            )}
                          />
                          <ShowError id={"city_id"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label="ZipCode"
                            fullWidth
                            value={zipcode}
                            onChange={(e) => {
                              if (e.target.value.match(/^[0-9]+$/) || e.target.value == "")
                                setZipcode(e.target.value);
                            }}
                          />
                          <ShowError id={"zipcode"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Nombre Contacto" : "Contact Name"}
                            fullWidth
                            value={contact}
                            onChange={(e) => {
                              setContact(e.target.value);
                            }}
                          />
                          <ShowError id={"contact_name"} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Teléfono de Vendedor" : "Vendor Phone Number"}
                            fullWidth
                            value={phone}
                            onChange={(e) => {
                              setPhone(formatPhoneNumber(e.target.value));
                            }}
                          />
                          <ShowError id={"phone"} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Teléfono de Contacto" : "Contact Phone Number"}
                            fullWidth
                            value={phone2}
                            onChange={(e) => {
                              setPhone2(formatPhoneNumber(e.target.value));
                            }}
                          />
                          <ShowError id={"contact_phone"} />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="light"
                      onClick={() => {
                        navigate("/emos/viewVendors");
                      }}
                    >
                      {language == 1 ? "Cancelar" : "Cancel"}
                    </MDButton>{" "}
                    <MDButton
                      type="button"
                      variant="gradient"
                      className="float-sm-right"
                      color="info"
                      onClick={() => CreateVendor()}
                      disabled={disableButton}
                    >
                      {language == 1 ? "Guardar" : "Save"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateVendors;
