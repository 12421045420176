//const IP = "http://192.168.1.24/emos-public/api";
//const IP = "http://18.222.223.148/emos/api";
//const IP = "https://emos.utreee.net/api";
//const IP = "http://localhost/emos-public/api";
//const IP = "http://127.0.0.1/emos-public/api";
const IP = "https://emos.agranelpr.com/api"; //production
// const IP = "http://emos-agranel.test/api"; //production
//const IP = "http://192.168.1.22/emos-public/api";
// const IP = "http://emos-backend.test/api";
//const IP = "http://127.0.0.1:81/api";
// const IP = "http://emos-backend.test:81/api";
// const IP = "http://3.14.237.100/api"; //test server/
//const IP = "http://127.0.0.1/emos/api";
//const IP = "http://emos-backend.test:81/api";
//const IP = "http://3.129.88.50/api"; //test server
// const IP = "http://18.219.204.92/api"; //test server

// const IP = "http://192.168.5.47/api"; // not production


export default IP;
