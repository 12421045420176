import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

const axios = require("axios");

function AddPurchaseOrder() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`
    }`;

  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const [date, setDate] = useState(currentDate);
  const [eda, setEda] = useState(currentDate);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [arrival, setArrival] = useState("");
  const [delivered, setDelivered] = useState("")
  const [deliveredBy, setDeliveredBy] = useState("")
  const [pickup, setPickup] = useState("");
  const [pickupBy, setPickupBy] = useState("");
  const [cif, setCif] = useState("");
  const [fob, setFob] = useState("");
  const [deliveryAddressName, setDeliveryAddressName] = useState('Supermercados Agranel, INC');
  const [deliveryAddress, setDeliveryAddress] = useState('Carr. # 2 KM 20.5');
  const [deliveryAddress2, setDeliveryAddress2] = useState('Edif. 2 BO. Candelaria');
  const [deliveryZipcode, setDeliveryZipcode] = useState('00949');
  const [deliveryState, setDeliveryState] = useState('PR');
  const [deliveryCity, setDeliveryCity] = useState('Toa Baja');
  const [billingAddressName, setBillingAddressName] = useState('Supermercados Agranel, INC');
  const [billingAddress, setBillingAddress] = useState('P.O. Box 200');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingZipcode, setBillingZipcode] = useState('00952');
  const [billingState, setBillingState] = useState('PR');
  const [billingCity, setBillingCity] = useState('Sabana Seca');
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null)
  const [contact, setContact] = useState("")
  const [notes, setNotes] = useState("")
  const [show, setShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState([]);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();

  async function getPaymentTerms() {
    await axios
      .get(IP + "/payment-terms", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setPaymentTerms(
          response.data.data.map((item) => {
            return { label: item.name, id: item.payment_term_id };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetVendors(products) {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log("Vendors Response:", response.data.data);
        // setVendors(
        //   response.data.data.map((item) => {
        //     console.log(item.active == "Active");
        //     return { label: item.name, id: item.id };
        //   })
        // );
        let vendorRes = response.data.data
          .map((item) => {
            if (item.active == "Active") {
              return { label: item.vendor_number + " " + item.name, id: item.id, ...item };
            }
            return undefined;
          })
          .filter((item) => item !== undefined); // Remove undefined values
        //console.log("Vendor map:", vendorRes);
        setVendors(vendorRes);
        // response.data.data.map(item=>{
        //   if (products.some(prod=>prod.vendor.id==item.id)) {
        //     vendors.push({ label: item.name, id: item.id })
        //     console.log(vendors)
        //   }
        // })
      })
      .catch(function (error) {
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });
  }

  async function sendPurchaseOrder() {
    // getVendorProducts();
    setDisableButton(true);
    await axios
      .post(
        IP + "/purchase_orders",
        {
          date: date,
          eda: eda,
          vendor_id: selectedVendor?.id,
          status_id: 1,
          active: true,
          notes: notes,
          contact_name: contact,
          arrival: arrival,
          pickup: pickup,
          pickup_by: pickupBy,
          delivered: delivered,
          delivered_by: deliveredBy,
          CIF: cif,
          FOB: fob,
          delivery_address_name: deliveryAddressName,
          delivery_address: deliveryAddress,
          delivery_address2: deliveryAddress2,
          delivery_zipcode: deliveryZipcode,
          delivery_state: deliveryState,
          delivery_city: deliveryCity,
          billing_address_name: billingAddressName,
          billing_address: billingAddress,
          billing_address2: billingAddress2,
          billing_zipcode: billingZipcode,
          billing_state: billingState,
          billing_city: billingCity,
          payment_term_id: selectedPaymentTerm?.id
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem("purchaseOrderId", response.data.data.id);
        toggleSnackbar();
        setDisableButton(false);
        setTimeout(() => {
          navigate("/edit-purchase_order");
        }, 2500);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        setDisableButton(false);
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetVendors();
    getPaymentTerms()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={
          language == 1
            ? "Orden de Compra Creada Exitosamente!"
            : "Purchase Order Created Successfully!"
        }
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDBox py={3} mb={20} height="65vh">
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox>
              <MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h5">
                    {language == 1 ? "Crear Orden de Compra" : "Create Purchase Order"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    {language == 1
                      ? "Favor de entrar la información de la orden de compra."
                      : "Please enter order information."}
                  </MDTypography>
                </MDBox>
                <MDBox id="box1" mt={1.625}>
                  <Grid id="grid1" container spacing={3}>
                    <Grid id="grid1-1" item xs={12} sm={3}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label={language == 1 ? "Fecha" : "Date"}
                        fullWidth
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <ShowError id={"date"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label="EDA"
                        fullWidth
                        value={eda}
                        onChange={(e) => {
                          setEda(e.target.value);
                        }}
                      />
                      <ShowError id={"eda"} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Autocomplete
                        disablePortal
                        value={selectedVendor}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedVendor(newValue);
                          if (newValue?.payment_term)
                            setSelectedPaymentTerm({ label: newValue?.payment_term?.name, id: newValue?.payment_term?.id })
                          else
                            setSelectedPaymentTerm(null)
                          setContact(newValue?.contact_name)
                        }}
                        options={vendors}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language == 1 ? "Vendedor" : "Vendor"}
                            variant="standard"
                          />
                        )}
                      />
                      <ShowError id={"vendor_id"} />
                    </Grid>
                  </Grid>
                  <Grid container pt={0.5} spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        disablePortal
                        value={selectedPaymentTerm}
                        disableClearable
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedPaymentTerm(newValue);
                        }}
                        options={paymentTerms}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language == 1 ? "Término" : "Term"}
                            variant="standard"
                          />
                        )}
                      />
                      <ShowError id={"payment_term_id"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Contacto" : "Contact"}
                        fullWidth
                        value={contact}
                        onChange={(e) => {
                          setContact(e.target.value);
                        }}
                      />
                      <ShowError id={"contact_name"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Requerido" : "Required"}
                        fullWidth
                        value={arrival}
                        onChange={(e) => {
                          setArrival(e.target.value);
                        }}
                      />
                      <ShowError id={"arrival"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Recogido" : "Pickup"}
                        fullWidth
                        value={pickup}
                        onChange={(e) => {
                          setPickup(e.target.value);
                        }}
                      />
                      <ShowError id={"pickup"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Recogido Por" : "Pickup By"}
                        fullWidth
                        value={pickupBy}
                        onChange={(e) => {
                          setPickupBy(e.target.value);
                        }}
                      />
                      <ShowError id={"pickup_by"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={"CIF"}
                        fullWidth
                        value={cif}
                        onChange={(e) => {
                          setCif(e.target.value);
                        }}
                      />
                      <ShowError id={"CIF"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Entregado" : "Delivered"}
                        fullWidth
                        value={delivered}
                        onChange={(e) => {
                          setDelivered(e.target.value);
                        }}
                      />
                      <ShowError id={"delivered"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Entregado Por" : "Delivered By"}
                        fullWidth
                        value={deliveredBy}
                        onChange={(e) => {
                          setDeliveredBy(e.target.value);
                        }}
                      />
                      <ShowError id={"delivered_by"} />
                    </Grid>
                    <Grid item lg={4}>
                      <MDInput
                        variant="standard"
                        label={"FOB"}
                        fullWidth
                        value={fob}
                        onChange={(e) => {
                          setFob(e.target.value);
                        }}
                      />
                      <ShowError id={"FOB"} />
                    </Grid>
                  </Grid>
                  <MDInput
                    type="text"
                    label={language == 1 ? "Notas" : "Notes"}
                    variant="standard"
                    multiline
                    rows={2}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    fullWidth
                  />
                  <Grid container spacing={3} mt={.5}>
                    <Grid item lg={6}>
                      <MDTypography variant={"h5"}>{language == 1 ? "Dirección de Facturación" : "Billing Address"}</MDTypography>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Nombre" : "Name"}
                        fullWidth
                        value={billingAddressName}
                        onChange={(e) => {
                          setBillingAddressName(e.target.value);
                        }}
                      />
                      <ShowError id={"billing_address_name"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Dirección" : "Address"}
                        fullWidth
                        value={billingAddress}
                        onChange={(e) => {
                          setBillingAddress(e.target.value);
                        }}
                      />
                      <ShowError id={"billing_address"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Dirección 2" : "Address 2"}
                        fullWidth
                        value={billingAddress2}
                        onChange={(e) => {
                          setBillingAddress2(e.target.value);
                        }}
                      />
                      <ShowError id={"billing_address2"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Ciudad" : "City"}
                        fullWidth
                        value={billingCity}
                        onChange={(e) => {
                          setBillingCity(e.target.value);
                        }}
                      />
                      <ShowError id={"billing_city"} />
                      <Grid container spacing={2}>
                        <Grid item lg={4}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Estado" : "State"}
                            fullWidth
                            value={billingState}
                            onChange={(e) => {
                              setBillingState(e.target.value);
                            }}
                          />
                          <ShowError id={"billing_state"} />
                        </Grid>
                        <Grid item lg={8}>
                          <MDInput
                            variant="standard"
                            label={"Zipcode"}
                            fullWidth
                            value={billingZipcode}
                            onChange={(e) => {
                              setBillingZipcode(e.target.value);
                            }}
                          />
                          <ShowError id={"billing_zipcode"} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6}>
                      <MDTypography variant={"h5"}>{language == 1 ? "Dirección de Entrega" : "Delivery Address"}</MDTypography>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Nombre" : "Name"}
                        fullWidth
                        value={deliveryAddressName}
                        onChange={(e) => {
                          setDeliveryAddressName(e.target.value);
                        }}
                      />
                      <ShowError id={"delivery_address_name"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Dirección" : "Address"}
                        fullWidth
                        value={deliveryAddress}
                        onChange={(e) => {
                          setDeliveryAddress(e.target.value);
                        }}
                      />
                      <ShowError id={"delivery_address"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Dirección 2" : "Address 2"}
                        fullWidth
                        value={deliveryAddress2}
                        onChange={(e) => {
                          setDeliveryAddress2(e.target.value);
                        }}
                      />
                      <ShowError id={"delivery_address2"} />
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Ciudad" : "City"}
                        fullWidth
                        value={deliveryCity}
                        onChange={(e) => {
                          setDeliveryCity(e.target.value);
                        }}
                      />
                      <ShowError id={"delivery_city"} />
                      <Grid container spacing={2}>
                        <Grid item lg={4}>
                          <MDInput
                            variant="standard"
                            label={language == 1 ? "Estado" : "State"}
                            fullWidth
                            value={deliveryState}
                            onChange={(e) => {
                              setDeliveryState(e.target.value);
                            }}
                          />
                          <ShowError id={"delivery_state"} />
                        </Grid>
                        <Grid item lg={8}>
                          <MDInput
                            variant="standard"
                            label={"Zipcode"}
                            fullWidth
                            value={deliveryZipcode}
                            onChange={(e) => {
                              setDeliveryZipcode(e.target.value);
                            }}
                          />
                          <ShowError id={"delivery_zipcode"} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox mt={1.625} display="flex" justifyContent="right">
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => {
                    localStorage.removeItem("vendorId");
                    navigate("/purchase_orders");
                  }}
                >
                  {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  //onClick={getVendorProducts}
                  onClick={sendPurchaseOrder}
                  disabled={disableButton}
                >
                  {language == 1 ? "Guardar" : "Save"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Grid>
          <MDBox id="box1" mt={2}>
            <Card>
              {/*  <DataTable table={vendorProducts} entriesPerPage={false} canSearch /> */}
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddPurchaseOrder;
