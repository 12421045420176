/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

import IP from "../IP";
import swal from "sweetalert";

const axios = require("axios");

function AdditionalCharges() {
    const [data, setData] = useState({ columns: [], rows: [] });
    const [index, setIndex] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [chargeToDelete, setChargeToDelete] = useState();
    const [active, setActive] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);
    const [show2, setShow2] = useState(false);
    const toggleSnackbar2 = () => setShow2(!show2);

    const navigate = useNavigate();

    async function getCharges() {
        await axios
            .get(IP + "/additional-charges", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                let columns = [
                    {
                        Header: language == 1 ? "Nombre" : "Name",
                        accessor: "name",
                    },
                    { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
                ];
                let rows = response.data.data.map((item) => {
                    return {
                        ...item,
                        action: (
                            <Grid>
                                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => {
                                            navigate('/edit-additional-charge', {state: {id: item.additional_charge_id}});
                                        }}
                                        iconOnly
                                    >
                                        <Icon>edit</Icon>
                                    </MDButton>
                                </Tooltip>{" "}
                                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        onClick={() => {
                                            setShowConfirm(true);
                                            setChargeToDelete(item)
                                        }}
                                        iconOnly
                                    >
                                        <Icon>delete</Icon>
                                    </MDButton>
                                </Tooltip>
                            </Grid>
                        )
                    };
                });

                let tableData = { columns: columns, rows: rows };

                setData(tableData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function EditStore(storeid) {
        localStorage.setItem("VenId", storeid);
        setRedirect(true);
        console.log("hello");
    }

    async function deleteCharge() {
        await axios
            .delete(IP + "/additional-charges/" + chargeToDelete?.additional_charge_id, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                getCharges();
            })
            .catch(function (error) {
                swal(
                    error?.response?.data?.message,
                    "",
                    "error"
                );
            });
    }

    useEffect(() => {
        getCharges();
    }, []);

    return (
        <DashboardLayout>
            {redirect && <Navigate push={true} to="/editVendor" />}
            <AlertDialog
                Show={showConfirm}
                setShowConfirm={setShowConfirm}
                Message={
                    language == 1
                        ? "¿Estás seguro de que quieres borrar el cargo ''" + chargeToDelete?.name + "'' ?"
                        : "Are you sure you want to delete the charge ''" + chargeToDelete?.name + "'' ?"
                }
                OkayFunction={deleteCharge}
            />
            <DashboardNavbar />
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                        navigate("/create-additional-charge");
                    }}
                >
                    {language == 1 ? "Crear Cargo" : "Create Charge"}
                </MDButton>
            </MDBox>
            <Card>
                <DataTable2
                    entriesPerPage={true}
                    index={index}
                    setIndex={setIndex}
                    pageEntries={10}
                    table={data}
                    canSearch
                />
            </Card>
        </DashboardLayout>
    );
}

export default AdditionalCharges;
