import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form, setIn } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import VirtualizedAutocomplete from "app/components/VirtualizedAutocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import Address from "layouts/pages/users/new-user/components/Address";
import Socials from "layouts/pages/users/new-user/components/Socials";
import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import MDInput from "components/MDInput";

import IP from "app/IP";

import productImage from "assets/images/products/product-11.jpg";

const axios = require("axios");

function AddProduct2() {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [upcUnit, setUpcUnit] = useState("");
  const [upc, setUpc] = useState("");
  const [description, setDescription] = useState("");
  const [minimum, setMinimum] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [available, setAvailable] = useState("");
  const [cost, setCost] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [suggestedPrice, setSuggestedPrice] = useState("");
  const [familyList, setFamilyList] = useState([]);
  const [familyRequirements, setFamilyRequirements] = useState([]);
  const [selectedFamilies, setSelectedFamilies] = useState();
  const [isFood, setIsFood] = useState(0);
  const [check, setCheck] = useState(false);
  const [checkExpires, setCheckExpires] = useState(false);
  const [checkAlmacen, setCheckAlmacen] = useState(false);
  const [packaging, setPackaging] = useState("");
  const [stock, setStock] = useState("");
  const [inQty, setInQty] = useState("");
  const [outQty, setOutQty] = useState("");
  const [measurements, setMeasurements] = useState([{}]);
  const [selectedMeasurement, setSelectedMeasurement] = useState("");
  const [subcategories, setSubcategories] = useState([{}]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [taxes, setTaxes] = useState([{}]);
  const [selectedTax, setSelectedTax] = useState("");
  const [vendors, setVendors] = useState([{}]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [warehouses, setWarehouse] = useState([{}]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const toggleSnackbar = () => setShow(!show);

  const fileRef = useRef();

  const handleChange2 = (e) => {
    console.log("here");
    const [fileVar] = e.target.files;
    if (fileVar.size <= 1024000) {
      setFile(fileVar);
      setImage(URL.createObjectURL(fileVar));
      console.log(fileVar);
      setShowImage(true);
    } else if (fileVar.size >= 1024000) {
      console.log(showImage);
      setShowImage(false);
      setImage();
      setFile();
    }
  };

  const handleChange = () => {
    setCheck(!check);
    setCheckExpires(!check);
  };

  const handleChangeExpires = () => {
    if (!check) setCheckExpires(!checkExpires);
  };

  const handleChangeAlmacen = () => {
    setCheckAlmacen(!checkAlmacen);
  };

  function activate() {
    check ? setIsFood(1) : setIsFood(0);
  }

  const navigate = useNavigate();

  async function GetMeasurements() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/measurements", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setMeasurements(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetSubcategories() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/subcategories", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let subcats = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            subcats.push({ label: item.name, id: item.id });
          }
        });

        setSubcategories(subcats);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetTaxes() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/taxes", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setTaxes(
          response.data.data
            .filter((item) => {
              return item.active == "Active";
            })
            .map((item) => {
              return { label: item.name + ` (${item.percent}%)`, id: item.id };
            })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function GetVendors() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/vendors", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        console.log("Get Vendors Response:", response.data.data);
        //let vendor = [];

        let vendor = response.data.data.map((item) => {
          //if (item.active == "Active") {
          //vendor.push({ label: item.name, id: item.id });
          return {
            id: item.id,
            label: item.vendor_number + ' ' + item.name,
          };
          //}
        });
        console.log("Get Vendors:", vendor);

        setVendors(vendor);
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log("Get Vendors ERROR:", error);
      });
  }

  async function GetWarehouses() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/warehouses", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        setWarehouse(
          response.data.data.map((item) => {
            return { label: item.name, id: item.id };
          })
        );
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendProduct() {
    const formData = new FormData();

    formData.append("image", file);
    formData.append("upcUnit", upcUnit);
    formData.append("upc", upc);
    formData.append("code", code);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("minimum", minimum);
    formData.append("maximum", maximum);
    formData.append("available", available);
    formData.append("cost", cost);
    formData.append("sales_price", salesPrice);
    formData.append("suggested_price", suggestedPrice);
    formData.append("is_food", isFood ? 3 : 1); //3 es tax 0; 1 es tax 10.5
    formData.append("expires", checkExpires ? 1 : 0);
    formData.append("almacen", checkAlmacen ? 1 : 0);
    formData.append("packaging", packaging);
    formData.append("measurement_id", 1);
    formData.append("subcategory_id", selectedSubcategory?.id);
    formData.append("tax_id", isFood ? 3 : 1); //3 es tax 0; 1 es tax 10.5
    formData.append("vendor_id", selectedVendor?.id);
    formData.append("warehouse_id", 1);
    formData.append("stock", stock);
    formData.append("in_qty", inQty);
    formData.append("out_qty", outQty);
    formData.append("active", 1);
    setDisableButton(true);
    await axios
      .post(IP + "/products", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          XDEBUG_SESSION_START: "PHPSTORM",
        },
      })
      .then((response) => {
        console.log("Send product Response:", response);
        console.log(response.data.data.id);
        setTimeout(() => {
          navigate("/products");
        }, 1500);
        // console.log(response);
        toggleSnackbar();
        // let selectedFamiliesMap = selectedFamilies.map((prod) => {
        //   return {
        //     product_id: response.data.id,
        //     family_id: prod.id,
        //   };
        // });
        // saveProductFamily([{ product_id: response?.data?.data?.id, family_id: selectedFamilies?.id }]);
      })
      .catch((error) => {
        console.log("Send product ERROR:", error);
        setErrors(error.response.data.data);
        //setErrors(error);
        // if (!error.response.data.authenticated) {
        //   navigate("/signIn");
        // }
        console.log(error);
      });

    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  async function GetFamilyRequirements(family_info) {
    await axios
      .post(
        IP + "/product_families/getFamilyRequirements",
        { family_id: family_info.family_id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("GetFamilyRequirements response", response);
        let familyRequirement = response.data.map((item) => item.requirement_id);
        console.log("Requirement Family:", familyRequirement);
        familyRequirement.includes(1) && setPackaging(family_info.packaging);
        familyRequirement.includes(9) && setSelectedSubcategory(family_info.subcategory);
        familyRequirement.includes(10) && setSelectedVendor(family_info.vendor);
        //setFamilyRequirements(familyRequirement);
        //setProductFamilyData(productFamily);
      })
      .catch((error) => {
        console.log("getFamilyRequirements error", error);
      });
  }

  //Get list of families
  async function GetFamilies() {
    await axios
      .get(IP + "/families/getFamilies", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Get families response:", response);
        let families = response.data.map((fam) => {
          return {
            id: fam.family_id,
            name: fam.family_name,
            subcategory_id: fam.subcategory_id,
            vendor_id: fam.vendor_id,
            packaging: fam.packaging,
          };
        });
        setFamilyList(families);
      })
      .catch((error) => {
        console.log("Get families error:", error);
      });
  }

  async function saveProductFamily(product_info) {
    console.log("PRODUCT_INFO:", product_info);
    //console.log("FAMILY_INFO:", family_id);
    await axios
      .post(IP + "/product_families/saveProductFamily", product_info, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("saveProductFamily Response:", response);
        setTimeout(() => {
          navigate("/products");
        }, 3000);
        toggleSnackbar();
        //navigate("/families");
      })
      .catch((error) => {
        console.log("saveProductFamily Error:", error);
      });
  }

  useEffect(() => {
    // getProductData()
    // GetWarehouses();
    GetFamilies();
    GetVendors();
    GetTaxes();
    GetSubcategories();
    // GetMeasurements();
  }, []);

  useEffect(() => {
    activate();
  });

  return (
    <DashboardLayout>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="Material Dashboard"
        content={language == 1 ? "Producto Creado Exitosamente!" : "Product Created Successfully!"}
        dateTime={language == 1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              {language == 1 ? "Crear Producto" : "Add Product"}
            </MDTypography>
            <MDBox mt={1} mb={2}>
              <MDTypography variant="body2" color="text">
                {language == 1
                  ? "Favor de entrar la información del producto."
                  : "Please enter product information."}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", height: "100%" }}>
              <input
                ref={fileRef}
                onChange={(e) => {
                  handleChange2(e);
                }}
                multiple={false}
                type="file"
                hidden
              />
              <MDBox textAlign="center" mt={3} pb={3} px={3}>
                {showImage ? (
                  <MDBox
                    component="img"
                    src={image}
                    alt="Product Image"
                    borderRadius="lg"
                    shadow="sm"
                    sx={{ cursor: "pointer", width: "90%", height: "335px" }}
                    hidden={!image}
                  />
                ) : (
                  <MDTypography mt={3} mb={-2} color="error">
                    {language == 1
                      ? "La imagen debe ser menor de 1 MB"
                      : "Image must be less than 1 MB"}
                  </MDTypography>
                )}
                <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
                  {language == 1 ? "Imagen del Producto" : "Product Image"}
                </MDTypography>
                <MDBox mt={2.625} width="100%" display="flex" justifyContent="center">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="medium"
                    onClick={() => fileRef.current.click()}
                  >
                    {language == 1 ? "Adjuntar" : "Attach"}
                  </MDButton>
                  <ShowError id={"image"} />
                  <MDButton
                    variant="gradient"
                    color="light"
                    size="medium"
                    hidden={!image}
                    onClick={() => {
                      setImage(), setShowImage(true);
                    }}
                  >
                    {language == 1 ? "remover" : "remove"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">
                  {language == 1 ? "Información del Producto" : "Product Information"}
                </MDTypography>
                <MDBox id="box1" mt={1.625}>
                  <Grid id="grid1" container spacing={3}>
                    <Grid id="grid1-1" item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label={language == 1 ? "UPC Unidad" : "UPC Unit"}
                        fullWidth
                        value={upcUnit}
                        onChange={(e) => {
                          if (e.target.value >= 0) setUpcUnit(e.target.value);
                        }}
                      />
                      <ShowError id={"upcUnit"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label="UPC"
                        fullWidth
                        value={upc}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setUpc(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"upc"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Código" : "Code"}
                        fullWidth
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                      <ShowError id={"code"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Nombre del Producto" : "Product Name"}
                        fullWidth
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <ShowError id={"name"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Descripción" : "Description"}
                        fullWidth
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                      <ShowError id={"description"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Costo" : "Cost"}
                        fullWidth
                        value={cost}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setCost(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"cost"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Precio de Venta" : "Sales Price"}
                        fullWidth
                        value={salesPrice}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setSalesPrice(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"sales_price"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Precio Sugerido" : "Suggested Price"}
                        fullWidth
                        value={suggestedPrice}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setSuggestedPrice(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"suggested_price"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Máximo" : "Maximum"}
                        fullWidth
                        type="number"
                        value={maximum}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setMaximum(e.target.value);
                          }

                          if (
                            (e.target.value >= 0 || e.target.value == "") &&
                            (parseInt(e.target.value ?? 0) >= parseInt(minimum ?? 0) ||
                              minimum == "")
                          ) {
                            setMaximum(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"maximum"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Mínimo" : "Minimum"}
                        fullWidth
                        type="number"
                        value={minimum}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setMinimum(e.target.value);
                          }

                          if (
                            e.target.value >= 0 &&
                            (parseInt(e.target.value ?? 0) <= parseInt(maximum ?? 0) ||
                              maximum == "")
                          ) {
                            setMinimum(e.target.value);
                            console.log(maximum);
                          }
                        }}
                      />
                      <ShowError id={"minimum"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label="Stock"
                        fullWidth
                        value={stock}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setStock(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"stock"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label="In Qty."
                        fullWidth
                        value={inQty}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setInQty(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"in_qty"} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        variant="standard"
                        label="Out Qty."
                        fullWidth
                        value={outQty}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setOutQty(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"out_qty"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        label={language == 1 ? "Disponible" : "Available"}
                        fullWidth
                        value={available}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setAvailable(e.target.value);
                          }
                        }}
                      />
                      <ShowError id={"available"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label={language == 1 ? "Empaque" : "Packaging"}
                        fullWidth
                        value={packaging}
                        onChange={(e) => {
                          setPackaging(e.target.value);
                        }}
                      />
                      <ShowError id={"packaging"} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        value={selectedMeasurement}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedMeasurement(newValue);
                        }}
                        options={measurements}
                        renderInput={(params) => (
                          <MDInput {...params} label={language==1 ? "Medidas" : "Measurement"} variant="standard" />
                        )}
                      />
                      <ShowError id={"measurement_id"} />
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        value={selectedSubcategory}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedSubcategory(newValue);
                        }}
                        options={subcategories}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language == 1 ? "Sub-Categoría" : "Sub-Category"}
                            variant="standard"
                          />
                        )}
                      />
                      <ShowError id={"subcategory_id"} />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*  <Autocomplete*/}
                    {/*    disablePortal*/}
                    {/*    value={selectedTax}*/}
                    {/*    onChange={(event, newValue) => {*/}
                    {/*      console.log(newValue);*/}
                    {/*      setSelectedTax(newValue);*/}
                    {/*    }}*/}
                    {/*    options={taxes}*/}
                    {/*    renderInput={(params) => (*/}
                    {/*      <TextField*/}
                    {/*        {...params}*/}
                    {/*        label={language == 1 ? "Impuesto" : "Tax"}*/}
                    {/*        variant="standard"*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*  />*/}
                    {/*  <ShowError id={"tax_id"} />*/}
                    {/*</Grid>*/}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        disablePortal
                        value={selectedVendor}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedVendor(newValue);
                        }}
                        options={vendors}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={language == 1 ? "Vendedor" : "Vendor"}
                            variant="standard"
                          />
                        )}
                      />
                      <ShowError id={"vendor_id"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* {familyList ? (
                        <MDButton
                          variant="gradient"
                          color="success"
                          style={{ float: "right" }}
                          onClick={() => {
                            getRelatedFamilies({
                              packaging: packaging,
                              subcategoryID: selectedSubcategory.id,
                              supplier: selectedVendor,
                            });
                          }}
                          disabled={disableButton}
                        >
                          {language == 1 ? "Añadir familia" : "Add family"}
                        </MDButton>
                      ) : ( */}
                      {/* <VirtualizedAutocomplete
                        label={language ? "Family" : "Familias"}
                        id="checkboxes-tags-demo"
                        style={{ width: 320 }}
                        options={familyList}
                        onChange={(event, newValue) => {
                          console.log("Selected related family:", newValue);
                          //console.log("Selected related product 2:", newValue.packaging);
                          setSelectedFamilies(newValue);

                          if (newValue) {
                            let vendor = vendors.filter((ven) => ven.id == newValue.vendor_id);
                            console.log("Filter result:", vendor);
                            //setSelectedVendor(vendor[0]);
                            let subcategory = subcategories.filter(
                              (sub) => sub.id == newValue.subcategory_id
                            );
                            console.log("Filter result:", subcategory);
                            //setSelectedSubcategory(subcategory[0]);

                            GetFamilyRequirements({
                              family_id: newValue.id,
                              packaging: newValue.packaging,
                              vendor: vendor[0],
                              subcategory: subcategory[0],
                            });
                          } else {
                            setSelectedSubcategory("");
                            setSelectedVendor("");
                          }
                        }}
                        getOptionLabel={(option) => option.id + " " + option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="standard-multiline-flexible"
                            multiline
                            maxRows="4"
                            variant="outlined"
                            label={language ? "Family" : "Familias"}
                            //fullWidth
                          />
                        )}
                      /> */}
                      {/* )} */}
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disablePortal
                        value={selectedWarehouse}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedWarehouse(newValue);
                        }}
                        options={warehouses}
                        renderInput={(params) => (
                          <MDInput {...params} label={language==1 ? "Almacén" : "Warehouse"} variant="standard" />
                        )}
                      />
                      <ShowError id={"warehouse_id"} />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <MDBox display="flex" justifyContent="left" alignItems="flex-start">
                        <MDTypography variant="subtitle2" style={{ color: "red" }}>
                          {language == 1 ? "Es comida?" : "Is food?"}{" "}
                          <Checkbox checked={check} onChange={handleChange} />
                        </MDTypography>
                        <MDTypography variant="subtitle2" style={{ color: "red", marginLeft: 14 }}>
                          {language == 1 ? "Expira?" : "Expires?"}{" "}
                          <Checkbox checked={checkExpires} onChange={handleChangeExpires} />
                        </MDTypography>
                        <MDTypography variant="subtitle2" style={{ color: "red" }}>
                          {language == 1 ? "Almacen" : "Warehouse"}{" "}
                          <Checkbox checked={checkAlmacen} onChange={handleChangeAlmacen} />
                        </MDTypography>
                      </MDBox>
                      {/* <ShowError id={"is_food"} /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        style={{ float: "right" }}
                        onClick={sendProduct}
                        //disabled={disableButton}
                      >
                        {language == 1 ? "Guardar" : "save"}
                      </MDButton>
                      {"   "}
                      <MDButton
                        variant="gradient"
                        style={{ float: "right" }}
                        color="light"
                        onClick={() => navigate("/products")}
                      >
                        {language == 1 ? "Cancelar" : "Cancel"}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddProduct2;
