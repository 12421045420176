import axios from "axios";
import IP from "../IP";
import MDButton from "../../components/MDButton";
import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDInput from "../../components/MDInput";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import MDTypography from "../../components/MDTypography";
import FadeIn from "react-fade-in";
import {Icon} from "@mui/material";

export default function PurchaseOrderCharges({id, charges, currentTotal, setCurrentTotal, chargeDetails, setChargeDetails, sx}) {
    const [currentChargesTotal, setCurrentChargesTotal] = useState(0);
    const [chargesData, setChargesData] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));

    const getChargesTotal = () => {
        let total = 0;
        Object.values(chargesData).forEach((item) => total += item.amount != '' ? parseFloat(item.amount) : 0);

        return total ?? 0;
    }

    const syncCharges = async () => {
        await axios
            .post(
                IP + "/purchase-order/" + id + '/sync-charges',
                { charges: Object.entries(chargesData)
                        .filter(([key, value]) => value.amount > 0)
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {}) },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
            .then(response => {
                setOpenModal(false)
                setCurrentTotal(currentTotal - currentChargesTotal + getChargesTotal());
                let data = [];
                charges.map(charge => {
                    if (chargesData[charge.id] && chargesData[charge.id].amount > 0) {
                        data.push({...charge, amount: chargesData[charge.id].amount});
                    }
                })
                setChargeDetails(data)
            })
    }

    return<>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <DialogTitle>{language == 1 ? "Cargos Adicionales" : "Additional Charges"}</DialogTitle>
            <DialogContent>
                {charges.map(charge => (
                    <MDInput
                        type="number"
                        label={charge.name}
                        variant="standard"
                        value={chargesData[charge.id]?.amount}
                        onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value == "") {
                                setChargesData({...chargesData, [charge.id]: {amount: e.target.value}});
                            }
                        }}
                        fullWidth
                    />
                ))}
                <MDTypography sx={{ mt: 1.5 }} variant="h6">
                    Total: <FadeIn>{"$" + parseFloat(currentTotal + getChargesTotal() - currentChargesTotal ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}</FadeIn>
                </MDTypography>
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => {
                        setOpenModal(false)
                    }}
                >
                    {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={syncCharges}
                >
                    {language == 1 ? "Guardar" : "Save"}
                </MDButton>
            </DialogActions>
        </Dialog>
        <MDButton
            sx={{ mb: -14, ...sx }}
            type="button"
            variant="gradient"
            color="light"
            onClick={() => {
                setOpenModal(true);
                let data = {};
                let total = 0;
                chargeDetails.map(charge => {
                    total += parseFloat(charge.amount);
                    data = {...data, [charge.additional_charge_id]: {amount: charge.amount}};
                })
                setCurrentChargesTotal(total);
                setChargesData(data)
            }}
        >
            <Icon>attach_money</Icon>
            Charges
        </MDButton>
    </>
}