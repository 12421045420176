/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";
import DataTable2 from "app/components/shopperTable";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { Tooltip } from "@mui/material";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import imageIP from "app/imageIP";

const axios = require("axios");

function ProductsNotInWarehouse() {
    const [menu, setMenu] = useState(null);
    const [productData, setProductData] = useState({ columns: [], rows: [] });
    const [role, setRole] = useState(0);
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [showActive, setShowActive] = useState(false);
    const [active, setActive] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirm2, setShowConfirm2] = useState(false);
    const [productId, setProductId] = useState();
    const [language, setLanguage] = useState(localStorage.getItem("Language"));

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const navigate = useNavigate();
    const toggleSnackbar = () => setShow(!show);
    const toggleSnackbar2 = () => setShow2(!show2);

    async function getUserDetails() {
        await axios
            .get(IP + "/user/profile", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                setRole(response.data.data.role.id);
                getProductData(response.data.data.role.id);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function changeStatus() {
        await axios
            .post(
                IP + "/products/setActive/" + productId,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
            .then((response) => {
                toggleSnackbar();
                getProductData(role);
            })
            .catch((error) => {
                if (!error.response.data.authenticated) {
                    navigate("/signIn");
                }
                if (
                    language == 1
                        ? error?.response?.data?.data == "Subcategoría inactiva, no se puede activar producto"
                        : error.response.data?.data == "Subcategory Inactive, cannot activate Product"
                ) {
                    setShow3(true);
                }
                console.log(error);
            });
    }

    async function DeleteProduct() {
        await axios
            .delete(IP + "/products/" + productId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
            .then(function (response) {
                localStorage.removeItem("productDeleteId");
                getProductData(role);
            })
            .catch(function (error) {
                if (!error.response.data.authenticated) {
                    navigate("/signIn");
                }
                setShow2(true);
                console.log(error);
            });
    }

    async function setDeleteProduct() {
        await axios
            .get(IP + "/products/setDeleted/" + productId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
            .then(function (response) {
                getProductData(role);
            })
            .catch(function (error) {
                if (!error.response.data.authenticated) {
                    navigate("/signIn");
                }
                // setShow2(true)
                console.log(error);
            });
    }

    async function getProductData(roleId) {
        await axios
            .get(IP + "/products?not_almacen=true&all_products=true", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    Accept: "application/json",
                },
            })
            .then((response) => {
                let productColumns = [
                    { Header: language == 1 ? "Imagen" : "Image", accessor: "image" },
                    { Header: language == 1 ? "Código" : "Code", accessor: "code" },
                    { Header: language == 1 ? "UPC Unidad" : "UPC Unit", accessor: "upcunit" },
                    { Header: "UPC", accessor: "upc" },
                    { Header: language == 1 ? "Nombre" : "Name", accessor: "name", width: "20%" },
                    { Header: language == 1 ? "Disponible" : "Available", accessor: "available" },
                    // { Header: language == 1 ? "Vendedor" : "Vendor", accessor: "vendor" },
                    { Header: language == 1 ? "Sub-Categoría" : "Sub-Category", accessor: "subcategory" },
                    { Header: language == 1 ? "Costo" : "Cost", accessor: "cost" },
                    { Header: language == 1 ? "Precio de Venta" : "Sales Price", accessor: "price" },
                    { Header: language == 1 ? "Impuesto" : "Tax", accessor: "tax" },
                    // { Header: language == 1 ? "Activo" : "Active", accessor: "active" },
                    { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: "15%" },
                ];

                let productRows = response.data.data.map((item) => {
                    //console.log(item);
                    return {
                        image: <ProductCell name="" image={imageIP + item.image} />,
                        code: item.code,
                        upcunit: item.upcUnit,
                        upc: item.upc,
                        name: item.name,
                        available: item.available,
                        vendor: item.vendor.name,
                        cost: item.cost,
                        price: item.sales_price,
                        tax: item.tax.percent + "%",
                        subcategory: item.subcategory.name,
                        active:
                            item.active == "Active"
                                ? language == 1
                                    ? "Activo"
                                    : "Active"
                                : language == 1
                                    ? "Inactivo"
                                    : "Inactive",
                        action: (
                            <Grid>
                                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => {
                                            localStorage.setItem("productId", item.id);
                                            navigate("/edit-product");
                                        }}
                                        iconOnly
                                    >
                                        <Icon>edit</Icon>
                                    </MDButton>
                                </Tooltip>{" "}
                                {item.active == "Inactive" ? (
                                    <Tooltip title={language == 1 ? "Activar" : "Activate"}>
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={() => {
                                                setProductId(item.id);
                                                setShowActive(true);
                                                setActive(false);
                                            }}
                                            iconOnly
                                        >
                                            <Icon>checkmark</Icon>
                                        </MDButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={language == 1 ? "Desactivar" : "Deactivate"}>
                                        <MDButton
                                            variant="gradient"
                                            color="warning"
                                            onClick={() => {
                                                setProductId(item.id);
                                                setShowActive(true);
                                                setActive(true);
                                            }}
                                            iconOnly
                                        >
                                            <Icon>do_not_disturb</Icon>
                                        </MDButton>
                                    </Tooltip>
                                )}{" "}
                                {roleId == 1 && (
                                    <Tooltip
                                        title={
                                            item.deleted == 1
                                                ? language == 1
                                                    ? "Desmarcar Borrado"
                                                    : "Unmark Deleted"
                                                : language == 1
                                                    ? "Marcar Borrado"
                                                    : "Mark Deleted"
                                        }
                                    >
                                        <MDButton
                                            variant="gradient"
                                            color={item.deleted == 1 ? "light" : "dark"}
                                            onClick={() => {
                                                setProductId(item.id);
                                                setShowConfirm2(true);
                                            }}
                                            iconOnly
                                        >
                                            <Icon>cancel</Icon>
                                        </MDButton>
                                    </Tooltip>
                                )}{" "}
                                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        onClick={() => {
                                            setProductId(item.id);
                                            setShowConfirm(true);
                                        }}
                                        iconOnly
                                    >
                                        <Icon>delete</Icon>
                                    </MDButton>
                                </Tooltip>
                            </Grid>
                        ),
                    };
                });

                let tableData = { columns: productColumns, rows: productRows };
                //console.log(tableData)
                setProductData(tableData);
            })
            .catch((error) => {
                if (!error.response.data.authenticated) {
                    navigate("/signIn");
                }
                console.log(error);
            });
    }

    function nav() {
        navigate("/create-product");
    }

    useEffect(() => {
        getProductData();
        getUserDetails();
    }, []);

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <Divider sx={{ margin: "0.5rem 0" }} />
            <MenuItem onClick={closeMenu}>
                <MDTypography variant="button" color="error" fontWeight="regular">
                    Remove Filter
                </MDTypography>
            </MenuItem>
        </Menu>
    );

    return (
        <DashboardLayout>
            <AlertDialog
                Show={showConfirm}
                setShowConfirm={setShowConfirm}
                Message={
                    language == 1
                        ? "¿Estás seguro que quieres borrar este producto?"
                        : "Are you sure you want to delete this product?"
                }
                OkayFunction={DeleteProduct}
            />
            <AlertDialog
                Show={showConfirm2}
                setShowConfirm={setShowConfirm2}
                Message={
                    language == 1
                        ? "¿Estás seguro que quieres marcar este producto como borrado?"
                        : "Are you sure you want to mark this product as deleted?"
                }
                OkayFunction={setDeleteProduct}
            />
            <AlertDialog
                Show={showActive}
                setShowConfirm={setShowActive}
                Message={
                    language == 1
                        ? `¿Estás seguro que quieres ${active ? "desactivar" : "activar"} este producto?`
                        : `Are you sure you want to ${active ? "deactivate" : "activate"} this product?`
                }
                OkayFunction={() => {
                    changeStatus();
                }}
            />
            <MDSnackbar
                color="info"
                icon="notifications"
                title=""
                content={
                    language == 1
                        ? `Producto ${active ? "Desactivado" : "Activado"} Exitosamente!`
                        : `Product ${active ? "Deactivated" : "Activated"} Succesfully!`
                }
                dateTime={language == 1 ? "Ahora" : "Now"}
                open={show}
                close={toggleSnackbar}
            />
            <MDSnackbar
                color="error"
                icon="notifications"
                title=""
                content={
                    language == 1
                        ? "El producto tiene una transacción pendiente; no se pudo borrar."
                        : "This product currently has a transaction pending; could not be deleted."
                }
                dateTime={language == 1 ? "Ahora" : "Now"}
                open={show2}
                close={toggleSnackbar2}
            />
            <MDSnackbar
                color="error"
                icon="notifications"
                title=""
                content={
                    language == 1
                        ? "Subcategoría inactiva; el producto no se puede activar."
                        : "Subcategory Inactive; cannot activate Product"
                }
                dateTime={language == 1 ? "Ahora" : "Now"}
                open={show3}
                close={() => setShow3(!show3)}
            />
            <DashboardNavbar />
            <MDBox my={3}>
                <Card>
                    <DataTable2
                        table={productData}
                        index={index}
                        setIndex={setIndex}
                        pageEntries={10}
                        entriesPerPage={true}
                        canSearch
                    />
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default ProductsNotInWarehouse;
