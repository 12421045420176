/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DataTable2 from "app/components/shopperTable";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useNavigate, Navigate } from "react-router-dom";

import DataTable from "examples/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

import IP from "../IP";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FadeIn from "react-fade-in";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";

const axios = require("axios");

function CreateInventoryAdjustment() {
    const [data, setData] = useState({ columns: [], rows: [] });
    const [index, setIndex] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [storeToDelName, setStoreToDelName] = useState(false);
    const [active, setActive] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);
    const [show2, setShow2] = useState(false);
    const toggleSnackbar2 = () => setShow2(!show2);
    const [creating, setCreating] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [notes, setNotes] = useState('')
    const [errors, setErrors] = useState([])

    const navigate = useNavigate();

    async function getProducts() {
        await axios
            .get(IP + "/products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(response => {
                setProducts(response.data.data.map(item => {
                    return {
                        id: item.id,
                        label: item.code + ' ' + item.name + ' Stock: ' + item.stock,
                        ...item
                    }
                }))
            })
    }

    async function getAdjustments() {
        await axios
            .get(IP + "/inventory-adjustments", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(function (response) {
                let columns = [
                    {
                        Header: language == 1 ? "Código" : "Code",
                        accessor: "code",
                    },
                    {
                        Header: language == 1 ? "Producto" : "Product",
                        accessor: "product",
                    },
                    { Header: language == 1 ? "Notas" : "Notes", accessor: "notes" },
                    { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
                    { Header: language == 1 ? "Stock Actual" : "Current Stock", accessor: "stock" },
                    { Header: language == 1 ? "Diferencia" : "Difference", accessor: "result" },
                    { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
                    { Header: language == 1 ? "Usuario" : "User", accessor: "user" },
                    { Header: language == 1 ? "Acción" : "Action", accessor: "action", width: '20%' },
                ];
                let rows = response.data.data.map((item) => {
                    return {
                        code: item.product.code,
                        product: item.product.name,
                        quantity: item.quantity,
                        stock: item.product.stock,
                        result: item.quantity - item.product.stock,
                        notes: item.notes,
                        date: item.date,
                        user: item.user.name,
                        action: (
                            <>
                                <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={() => {
                                        swal(
                                            language == 1
                                                ? `¿Estás seguro de que quieres aplicar este ajuste?`
                                                : `Are you sure you want to apply this adjustment?`,
                                            `${item.product.name}, ${language == 1 ? "Cantidad" : "Quantity"}: ${item.quantity}`,
                                            "warning",
                                            {
                                                buttons: {
                                                    cancel: language == 1 ? "Cancelar" : "Cancel",
                                                    confirm: language == 1 ? "Confirmar" : "Confirm",
                                                },
                                            }
                                        ).then((willConfirm) => {
                                            if (willConfirm) {
                                                applyAdjustment(item.inventory_adjustment_id);
                                            }
                                        });
                                    }}
                                >
                                    {language == 1 ? "Aplicar" : "Apply"}
                                </MDButton>{" "}
                                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        onClick={() => {
                                            swal(
                                                language == 1
                                                    ? `¿Estás seguro de que quieres borrar este ajuste?`
                                                    : `Are you sure you want to delete this adjustment?`,
                                                `${item.product.name}, ${language == 1 ? "Cantidad" : "Quantity"}: ${item.quantity}`,
                                                "warning",
                                                {
                                                    buttons: {
                                                        cancel: language == 1 ? "Cancelar" : "Cancel",
                                                        confirm: language == 1 ? "Borrar" : "Delete",
                                                    },
                                                }
                                            ).then((willConfirm) => {
                                                if (willConfirm) {
                                                    deleteAdjustment(item.inventory_adjustment_id);
                                                }
                                            });
                                        }}
                                        iconOnly
                                    >
                                        <Icon>delete</Icon>
                                    </MDButton>
                                </Tooltip>
                            </>
                        )
                    };
                });

                let tableData = { columns: columns, rows: rows };

                setData(tableData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function createAdjustment() {
        setErrors([]);
        await axios.post(IP + "/inventory-adjustments", {
            product_id: selectedProduct?.id,
            quantity: quantity,
            notes: notes,
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then(response => {
            setCreating(false);
            getAdjustments()
        }).catch(error => {
            setErrors(error?.response?.data?.data ?? []);
        })
    }

    async function applyAdjustment(id) {
        await axios.get(IP + "/apply-inventory-adjustment/" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then(response => {
            getAdjustments()
            getProducts()
        })
        .catch(error => {
            console.log(error);
        })
    }

    async function deleteAdjustment(id) {
        await axios.delete(IP + "/inventory-adjustments/" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then(response => getAdjustments())
            .catch(error => {
                console.log(error);
            })
    }

    function TestValidation(message) {
        if (message)
            return message.map((item) => {
                return (
                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{item}</label>
                    </MDTypography>
                );
            });
        else return null;
    }

    function ShowError(param) {
        // console.log(errors["name"]);
        if (param.id) return TestValidation(errors[param.id]);
        else return null;
    }

    useEffect(() => {
        getAdjustments();
        getProducts();
    }, []);

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ThemeProvider theme={darkTheme}>
                <Dialog open={creating}>
                    <DialogTitle>
                        <Grid>
                            {language == 1 ? "Crear Ajuste: " : "Create Adjustment: "}
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            disablePortal
                            value={selectedProduct}
                            onChange={(event, newValue) => {
                                setSelectedProduct(newValue);
                                setQuantity(newValue?.stock ?? 0)
                            }}
                            options={products}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={language == 1 ? "Producto" : "Product"}
                                    variant="standard"
                                />
                            )}
                        />
                        <ShowError id={"product_id"} />
                        {selectedProduct?.id && <h6 className={'mt-2'}>Stock: {selectedProduct?.stock}</h6>}
                        {(selectedProduct?.id && quantity != '' && quantity != null) && <h6>{language == 1 ? "Diferencia" : "Difference"}: {quantity - selectedProduct?.stock}</h6>}
                        <TextField
                            margin="dense"
                            id="quantity"
                            value={quantity}
                            type={'number'}
                            label={language == 1 ? "Cantidad" : "Quantity"}
                            onChange={(e) => {
                                setQuantity(e.target.value);
                            }}
                            fullWidth
                            variant="standard"
                        />
                        <ShowError id={'quantity'} />
                        <TextField
                            margin="dense"
                            id="notes"
                            value={notes}
                            label={language == 1 ? "Notas" : "Notes"}
                            onChange={(e) => {
                                setNotes(e.target.value);
                            }}
                            fullWidth
                            variant="standard"
                        />
                        <ShowError id={'notes'} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCreating(false)}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
                        <Button
                            disabled={quantity < 0 || quantity == '' || quantity == null}
                            onClick={() => {
                                createAdjustment()
                            }}
                        >
                            {language == 1 ? "Guardar" : "Save"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                        setCreating(!creating)
                        setQuantity(0)
                        setSelectedProduct(null)
                        setNotes('')
                    }}
                >
                    {language == 1 ? "Crear Ajuste" : "Create Adjustment"}
                </MDButton>
                <MDTypography variant="button" color="text"></MDTypography>
            </MDBox>
            <Card>
                <h4 style={{marginLeft: 16, marginTop: 16}}>
                    {(language == 1 ? "Ajustes Pendientes" : "Pending Adjustments")}
                </h4>
                <DataTable2
                    entriesPerPage={true}
                    index={index}
                    setIndex={setIndex}
                    pageEntries={10}
                    table={data}
                    canSearch
                />
            </Card>
        </DashboardLayout>
    );
}

export default CreateInventoryAdjustment;
