/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { TextField, Switch, Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

const axios = require("axios");

function AdjustInvoice() {
  const [invoice, setInvoice] = useState();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmSuccess, setShowConfirmSuccess] = useState(false);
  const [editing, setEditing] = useState(false)
  const [adjustmentsKey, setAdjustmentsKey] = useState(0)
  const [adjusted, setAdjusted] = useState([]);
  const [invoiceProductData, setInvoiceProductData] = useState([])
  const [invoiceProducts, setInvoiceProducts] = useState({ columns: [], rows: [] });
  const [status, setStatus] = useState({id: 1, name: ''})
  const [note, setNote] = useState("")
  const [deliveryFee, setDeliveryFee] = useState(0)
  const [addingProduct, setAddingProduct] = useState(false)
  const [isProduct, setIsProduct] = useState(true)
  const [validatePrice, setValidatePrice] = useState(false)
  const [voidItem, setVoidItem] = useState(null)
  const [adjusting, setAdjusting] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [itemToAdjust, setItemToAdjust] = useState(null);
  const [quantity, setQuantity] = useState(0)
  const [price, setPrice] = useState('')
  const [itemNote, setItemNote] = useState('')
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isReturn, setIsReturn] = useState(true)
  const [errors, setErrors] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const toggleSnackbar = () => setShow(!show);
  const toggleSnackbarConfirmSuccess = () => setShowConfirmSuccess(!showConfirmSuccess);
  const navigate = useNavigate();
  const { state } = useLocation();

  const adjustedColumns = [
    { Header: language == 1 ? "Código" : "Code", accessor: "code" },
    { Header: language == 1 ? "Producto" : "Product", accessor: "product" },
    { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
    { Header: language == 1 ? "Precio" : "Price", accessor: "price" },
    { Header: language == 1 ? "Acción" : "action", accessor: "actions" },
  ];

  async function getProducts(invoiceProds) {
    await axios
      .get(IP + "/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let prod_ids = invoiceProds.map(item=>{
          return item.product.id
        })
        console.log(prod_ids)
        setProducts(response.data.data.filter(item=>{
          return !prod_ids.includes(item.id)
        }).map(item=> {
          return {id: item.id, label: item.code + ' ' + item.name, code: item.code, available: item.available}
        }))
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getInvoiceProducts() {
    await axios
      .get(IP + "/invoices/" + state?.InvoiceId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log(response?.data?.data);
        getAdjustedProducts(response.data.data[1])
        setInvoice(response.data.data[0])
        setDeliveryFee(response.data.data[0].sales.delivery_fee)
        getProducts(response.data.data[1])
        setInvoiceProductData(response.data.data[1])

        let columns = [
          { Header: language == 1 ? "Código" : "Code", accessor: "code" },
          { Header: language == 1 ? "Producto" : "Product", accessor: "product" },
          { Header: language == 1 ? "Cantidad Original" : "Original Quantity", accessor: "quantity" },
          { Header: language == 1 ? "Cantidad Ajustada" : "Adjusted Quantity", accessor: "adjust_quantity" },
          { Header: language == 1 ? "Precio Original" : "Original Price", accessor: "price" },
          { Header: language == 1 ? "Precio Ajustado" : "Adjusted Price", accessor: "adjust_price" },
          { Header: language == 1 ? "Acción" : "action", accessor: "action" },
        ];

        let rows = response?.data?.data[1].map((item) => {          
          return {
            prod_id: item.product.id,
            code: item.product.code,
            product: item.product.name,
            quantity: item.quantity_approved,
            price: '$' + item.price,
            adjust_quantity: '',
            adjust_price: '',
            action: (
              <Grid>
                <Tooltip title={language==1 ? "Editar" : "Edit"}>
                  <MDButton variant="gradient" color="info" iconOnly onClick={()=>{
                    setItemToAdjust(item)
                    setAdjusting(true)
                    setQuantity(0)
                    setPrice(item.price)
                  }}>
                      <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{"  "}
                <MDButton variant="gradient" color="dark" onClick={() => {
                  setVoidItem(item)
                }}>
                  Void
                </MDButton>
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };

        setInvoiceProducts(tableData);
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function getAdjustedProducts(invProducts) {
    await axios
      .get(IP + "/adjustments/" + state?.InvoiceId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("GET ADJUSTED")
        console.log(response?.data?.data);
        console.log(invProducts)

        setDeliveryFee(response.data.data.delivery_fee)
        setStatus(response.data.data.status)
        setNote(response.data.data.notes)
        let adjustedProds = response.data.data.details.map((item, key)=>{
          let invoiceCheck = invProducts.filter(prod=>{
            return item.product.id == prod.product.id
          })
          let id = item.product.id

          if (invoiceCheck.length > 0)
            id = invoiceCheck[0].id

          return {
            number: key,
            id: id,
            detail_id: item.id,
            product_id: item.product.id,
            product: item.product.id != null ? item.product.name : item.note,
            code: item.product.code,
            price: '$' + parseFloat(item.price).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            priceVal: parseFloat(item.price),
            quantity: item.quantity,
            note: item.note,
            actions: (!item.applied && <Grid>
              <Tooltip title={language==1 ? "Eliminar" : "Delete"}>
                <MDButton variant="gradient" color="error" iconOnly onClick={()=>{
                  setItemToDelete(key)
                }}>
                    <Icon>delete</Icon>
                </MDButton>
              </Tooltip>{"  "}
            </Grid>),
          }
        })

        setEditing(true)
        setAdjustmentsKey(adjustedProds.length)
        setAdjusted(adjustedProds)
      })
      .catch((error) => {
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }


  function getQuantityAdjusted(newProd = false) {
    let qtyAdjusted = 0
    adjusted.filter(item=> {
      return item.id == itemToAdjust?.id
    }).map(item=>{
      qtyAdjusted += parseInt(item.quantity)
    })

    if (!newProd)
      qtyAdjusted += itemToAdjust?.quantity

    return qtyAdjusted
  }

  function removeAdjustment(number) {
    setAdjusted(adjusted.filter(item=>item.number != number).sort((a, b) => (a.product < b.product ? 1 : -1)))
    setItemToDelete(null)
  }

  function voidProduct(item) {
    //checks if this product has already been voided
    // let voidCheck = adjusted.some((prod) => prod.id == item.id && prod.void)
    let voidCheck = adjusted.filter(prod=>prod.id == item.id).reduce((acc, prod) => {
      console.log(prod.priceVal, item.price)
        if (prod.priceVal == item.price) { 
            const quantity = parseInt(prod.quantity, 10);
            acc += quantity;
        }
        return acc;
    }, 0) + item.quantity;

    if (voidCheck != 0) {
      let checkAdjustment = adjusted.filter(adjust=>{
        return adjust.id == item?.id && adjust.priceVal == item.price
      })

      if (checkAdjustment.length > 0) {
        checkAdjustment[0].quantity += (0 - parseInt(voidCheck))
        checkAdjustment[0].void = true

        setAdjusted(
          [
            ...adjusted.filter(adjust=>{
              return adjust.id != item?.id || adjust.priceVal != item.price
            }),
            checkAdjustment[0]
          ].sort((a, b) => (a.product < b.product ? 1 : -1))
        )
      }
      else {
        let productAdjustment = {
          number: adjustmentsKey,
          id: item.id,
          product_id: item.product.id,
          code: item.product.code,
          product: item.product.name,
          quantity: item.quantity_approved * -1,
          price: '$' + parseFloat(item.price).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          priceVal: item.price,
          actions: <Grid>
            <Tooltip title={language==1 ? "Eliminar" : "Delete"}>
              <MDButton variant="gradient" color="error" iconOnly onClick={()=>{
                setItemToDelete(adjustmentsKey)
              }}>
                  <Icon>delete</Icon>
              </MDButton>
            </Tooltip>{"  "}
          </Grid>,
          void: true
        }

        setAdjustmentsKey(adjustmentsKey + 1)
        setAdjusted([...adjusted, productAdjustment].sort((a, b) => (a.product < b.product ? 1 : -1)))
      }
    } else {
      console.log("VOIDED")
    }
    setVoidItem(null)
  }

  function adjustItem(item) {
    let checkAdjustment = adjusted.filter(adjust=>{
      return adjust.id == item?.id && adjust.priceVal == price
    })

    if (checkAdjustment.length > 0) {
      checkAdjustment[0].quantity += parseInt(quantity)

      if (checkAdjustment[0].quantity == 0)
        setAdjusted(
          adjusted.filter(adjust=>{
            return adjust.id != item?.id || adjust.priceVal != price
          }).sort((a, b) => (a.product < b.product ? 1 : -1))
        ) 
      else
        setAdjusted(
          [
            ...adjusted.filter(adjust=>{
              return adjust.id != item?.id || adjust.priceVal != price
            }),
            checkAdjustment[0]
          ].sort((a, b) => (a.product < b.product ? 1 : -1))
        )
    }
    else {
      let productAdjustment = {
        number: adjustmentsKey,
        id: item.id,
        product_id: item.product.id,
        code: item.product.code,
        product: item.product.name,
        quantity: parseInt(quantity),
        price: '$' + parseFloat(price).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        priceVal: price,
        actions: <Grid>
          <Tooltip title={language==1 ? "Eliminar" : "Delete"}>
            <MDButton variant="gradient" color="error" iconOnly onClick={()=>{
              setItemToDelete(adjustmentsKey)
            }}>
                <Icon>delete</Icon>
            </MDButton>
          </Tooltip>{"  "}
        </Grid>,
        void: false
      }

      setAdjusted([...adjusted, productAdjustment].sort((a, b) => (a.product < b.product ? 1 : -1)))
    }

    setAdjustmentsKey(adjustmentsKey + 1)
    setItemToAdjust(null)
    setAdjusting(false)
  }

  function addItem() {
    let checkAdjustment = adjusted.filter(item=>{
      if (quantity > 0)
        return item.id == selectedProduct?.id && item.quantity > 0 && item.priceVal == price
      else
        return item.id == selectedProduct?.id && item.quantity < 0 && item.priceVal == price
    })

    if (checkAdjustment.length > 0 && isProduct) {
      checkAdjustment[0].quantity += parseInt(quantity)

      setAdjusted(
        [
          ...adjusted.filter(item=>{
            if (quantity > 0)
              return !(item.id == selectedProduct?.id && item.quantity > 0 && item.priceVal == price)
            else
              return !(item.id == selectedProduct?.id && item.quantity < 0 && item.priceVal == price)
          }),
          checkAdjustment[0]
        ].sort((a, b) => (a.product < b.product ? 1 : -1))
      )
    }
    else {
      let productAdjustment = {
        number: adjustmentsKey,
        id: isProduct ? selectedProduct?.id : '',
        product_id: isProduct ? selectedProduct?.id : '',
        code: isProduct ? selectedProduct?.code : '',
        product: isProduct ? selectedProduct?.label : itemNote,
        quantity: isProduct ? parseInt(quantity) : 1,
        price: '$' + parseFloat(price).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        priceVal: price,
        actions: <Grid>
          <Tooltip title={language==1 ? "Eliminar" : "Delete"}>
            <MDButton variant="gradient" color="error" iconOnly onClick={()=>{
              setItemToDelete(adjustmentsKey)
            }}>
                <Icon>delete</Icon>
            </MDButton>
          </Tooltip>{"  "}
        </Grid>,
        void: false
      }

      setAdjusted([...adjusted, productAdjustment].sort((a, b) => (a.product < b.product ? 1 : -1)))
    }

    setAdjustmentsKey(adjustmentsKey + 1)
    setQuantity(0)
    setPrice('')
    setItemNote("")
    setAddingProduct(false)
    setSelectedProduct(null)
  }

  async function saveAdjustment(confirm = false) {
    setErrors([])
    let method = editing ? 'put' : 'post'
    await axios[method](
        IP + "/adjustments" + (editing ? '/' + invoice.invoice_id : ''),
        { invoice_id: invoice.invoice_id, notes: note, delivery_fee: deliveryFee, details: adjusted },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        // setTimeout(() => {
        //   navigate("/invoices");
        // }, 3000);
        console.log(response);
        if (confirm)
          confirmAdjustment();
        else
          toggleSnackbar()
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          // navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function confirmAdjustment() {
    setErrors([])
    await axios.post(
        IP + "/confirm-adjustment/" + invoice.invoice_id,
        { invoice_id: invoice.invoice_id, notes: note, delivery_fee: deliveryFee, details: adjusted },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          navigate("/invoices");
        }, 3000);
        console.log(response);
        toggleSnackbarConfirmSuccess();
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error.response.data.authenticated) {
          // navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    getInvoiceProducts()
  }, []);

  // useEffect(() => {
  //   if (status?.id != 1)
  //     setInvoiceProducts({columns: invoiceProducts.columns.filter(item=>{
  //       return item.accessor != 'action'
  //     }), rows: invoiceProducts.rows})
  // }, [status])

  useEffect(() => {
    if (voidItem != null)
      voidProduct(voidItem)
  }, [voidItem])

  useEffect(() => {
    if (itemToDelete != null)
      removeAdjustment(itemToDelete)
  }, [itemToDelete])

  useEffect(() => {
    if (adjusting){
      let adjustCheck = adjusted.filter(item=>{
        return item.id == itemToAdjust.id
      })

      if (adjustCheck.length > 0) {
        let originalQuantityAdded = false
        let maxPrice = false
        let quantitySums = adjustCheck.reduce((acc, item) => {
          const priceVal = item.priceVal;
          const quantity = parseInt(item.quantity, 10);
          
          if (acc[priceVal]) {
              acc[priceVal] += quantity;
          } else {
              acc[priceVal] = quantity;
          }

          if (!originalQuantityAdded && priceVal == itemToAdjust.price) {
            acc[priceVal] += parseFloat(itemToAdjust.quantity)
            originalQuantityAdded = false
          }
          return acc;
        }, {})

        for (const [priceVal, sum] of Object.entries(quantitySums)) {
          if (sum !== 0) {
              maxPrice = priceVal;
          }
        }

        if (maxPrice)
          setPrice(maxPrice)
      }
    }
  }, [adjusting])

  useEffect(() => {
    function getPrice(itemToAdjust) {
      let adjustCheck = adjusted.filter(item=>{
        return item.id == itemToAdjust.id
      })
  
      if (adjustCheck.length > 0) {
        let originalQuantityAdded = false
        let maxPrice = false
        let quantitySums = adjustCheck.reduce((acc, item) => {
          const priceVal = item.priceVal;
          const quantity = parseInt(item.quantity, 10);
          
          if (acc[priceVal]) {
              acc[priceVal] += quantity;
          } else {
              acc[priceVal] = quantity;
          }
  
          if (!originalQuantityAdded && priceVal == itemToAdjust.price) {
            acc[priceVal] += parseFloat(itemToAdjust.quantity)
            originalQuantityAdded = false
          }
          return acc;
        }, {})
  
        for (const [priceVal, sum] of Object.entries(quantitySums)) {
          if (sum !== 0) {
              maxPrice = priceVal;
          }
        }

        if (maxPrice)
          return maxPrice
        else
          return ''
      }

      return '';
    }

    function getQuantity(itemToAdjust) {
      let adjustCheck = adjusted.filter(item=>{
        return item.id == itemToAdjust.id
      })
  
      if (adjustCheck.length > 0) {
        let quantityTotal = 0;
        adjustCheck.map(item=>{
          quantityTotal += item.quantity
        })

        return quantityTotal == 0 ? '' : quantityTotal
      }

      return ''
    }

    let rows = invoiceProductData.map((item) => {          
      let adjustPrice = getPrice(item);
      let adjustQty = getQuantity(item)
      console.log('here')
      console.log(item)
      return {
        prod_id: item.product.id,
        code: item.product.code,
        product: item.product.name,
        quantity: item.quantity_approved,
        price: '$' + item.price,
        adjust_quantity: adjustQty,
        adjust_price: adjustPrice == item.price || adjustPrice == '' ? '' : ('$' + adjustPrice),
        action: (
          <Grid>
            <Tooltip title={language==1 ? "Editar" : "Edit"}>
              <MDButton variant="gradient" color="info" iconOnly onClick={()=>{
                setItemToAdjust(item)
                setAdjusting(true)
                setQuantity(0)
                setPrice(item.price)
              }}>
                  <Icon>edit</Icon>
              </MDButton>
            </Tooltip>{"  "}
            <MDButton variant="gradient" color="dark" onClick={() => {
              setVoidItem(item)
            }}>
              Void
            </MDButton>
          </Grid>
        ),
      };
    });

    setInvoiceProducts({columns: invoiceProducts.columns, rows: rows})
  }, [adjusted])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres confirmar los ajustes?"
            : "Are you sure you want to confirm these adjustments?"
        }
        OkayFunction={() => {
          saveAdjustment(true)
        }}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Ajustes guardados exitosamente!" : "Adjustments saved succesfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="info"
        icon="notifications"
        title={language == 1 ? "EMOS Notificación" : "EMOS Notification"}
        content={language == 1 ? "Ajustes confirmados exitosamente!" : "Adjustments confirmed succesfully!"}
        dateTime={language==1 ? "Ahora" : "Now"}
        open={showConfirmSuccess}
        close={toggleSnackbarConfirmSuccess}
      />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={adjusting}>
          <DialogTitle>{language == 1 ? "Producto" : "Product"}: {itemToAdjust?.product?.name}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="price"
              value={price}
              label={language == 1 ? "Precio" : "Price"}
              type="number"
              disabled={getQuantityAdjusted() != 0}
              onChange={(e) => {
                if (e.target.value >= 0)
                  setPrice(e.target.value);
              }}
              inputProps={{
                readOnly: getQuantityAdjusted() != 0
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              id="qty"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Quantity"}
              type="number"
              onChange={(e) => {
                console.log(adjusted);
                let quantitySum = adjusted.reduce((acc, prod) => {
                  if (prod.priceVal == price) 
                    acc += parseInt(prod.quantity, 10);
                  return acc;
                }, 0) + parseInt(e.target.value)

                if (itemToAdjust.price == price)
                  quantitySum += itemToAdjust.quantity

                console.log(quantitySum)
                if (quantitySum >= 0 || e.target.value == '')
                  setQuantity(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setAdjusting(false)}}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              disabled={quantity == '' || quantity == null || price == '' || price == null} 
              onClick={() => {adjustItem(itemToAdjust)}}
            >
              {language == 1 ? "Ajustar" : "Adjust"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={addingProduct}>
          <DialogTitle>{language == 1 ? "Añadir Item" : "Add Item"}</DialogTitle>
          <DialogContent>
            {!isProduct && <TextField
              margin="dense"
              id="note"
              value={itemNote}
              label={language == 1 ? "Nota" : "Note"}
              type="text"
              onChange={(e) => {
                setItemNote(e.target.value);
              }}
              fullWidth
              variant="standard"
            />}
            {isProduct && <>
              <Autocomplete
                // sx={{ width: "25%", ml: 1, mt: 1 }}
                disablePortal
                value={selectedProduct}
                onChange={(event, newValue) => {
                  setSelectedProduct(newValue);
                  let checkAdjustment = adjusted.filter(item=>item.id == newValue.id)
                  if (checkAdjustment.length > 0) {
                    setItemToAdjust(checkAdjustment[0])
                    setPrice(checkAdjustment[0].priceVal)
                    setValidatePrice(true)
                  }
                  else setValidatePrice(false)
                }}
                options={products}
                renderInput={(params) => (
                  <TextField {...params} label={language == 1 ? "Productos" : "Products"} variant="standard" />
                )}
              />
              {selectedProduct?.available != null && <h6>{language == 1 ? "Disponible" : "Available"}: {selectedProduct?.available}</h6>}
            </>}
            <TextField
              margin="dense"
              id="price"
              value={price}
              label={language == 1 ? "Precio" : "Price"}
              type="number"
              disabled={getQuantityAdjusted(true) != 0 && isProduct && validatePrice}
              onChange={(e) => {
                if (e.target.value >= 0 || !isProduct)
                  setPrice(e.target.value);
              }}
              inputProps={{
                readOnly: getQuantityAdjusted(true) != 0 && isProduct && validatePrice
              }}
              fullWidth
              variant="standard"
            />
            {isProduct && <TextField
              margin="dense"
              id="prodQty"
              value={quantity}
              label={language == 1 ? "Cantidad" : "Quantity"}
              type="number"
              onChange={(e) => {
                if (e.target.value >= 0)
                  setQuantity(e.target.value);
              }}
              fullWidth
              variant="standard"
            />}
            <MDTypography variant="body2">
              {language == 1 ? "Producto?" : "Product?"}{" "}
              <Checkbox checked={isProduct} onChange={()=>{setIsProduct(!isProduct), setValidatePrice(false), setSelectedProduct(null)}} />
            </MDTypography>
            {isProduct && <MDTypography variant="body2">
              {language == 1 ? "Devolver a inventario?" : "Return to inventory?"}{" "}
              <Checkbox checked={isReturn} onChange={()=>{setIsReturn(!isReturn)}} />
            </MDTypography>}
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setAddingProduct(false)}}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              disabled={(selectedProduct?.available != null && quantity > selectedProduct?.available && isProduct) || (quantity == 0 && isProduct)}
              onClick={addItem}
            >
              {language == 1 ? "Añadir" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
          <MDBox mb={2}>
            <MDTypography variant="h4">{language == 1 ? "Número de Factura: " : "Invoice Number: "}{invoice?.invoice_number + " | "} Status: {status?.name}</MDTypography>
            <Grid mt={1} container>
              <MDInput
                type="text"
                label={language == 1 ? "Notas" : "Notes"}
                variant="standard"
                multiline
                rows={3}
                value={note}
                inputProps={{readOnly: status?.id != 1}}
                onChange={(e) => setNote(e.target.value)}
                fullWidth
              />
              <ShowError id={"notes"} />
            </Grid>
            <Grid mt={2} mb={2} container spacing={2}>
              <Grid item lg={4}>
                <MDInput
                  type="number"
                  label={language == 1 ? "Cargo por Entrega" : "Delivery Fee"}
                  variant="standard"
                  value={deliveryFee}
                  inputProps={{readOnly: status?.id != 1}}
                  onChange={(e) => {
                    if (e.target.value >= 0)
                      setDeliveryFee(e.target.value)
                  }}
                  fullWidth
                />
                <ShowError id={"delivery_fee"} />
              </Grid>
              <Grid item lg={'auto'}>
                <MDButton variant="gradient" color="light" style={{float:'right'}} onClick={() => {
                  navigate('/invoices')
                }}>
                  {language == 1 ? "Cancelar" : "Cancel"}
                </MDButton>
              </Grid>
            </Grid>
            {status?.id == 1 && <><MDButton variant="gradient" color="info" onClick={saveAdjustment}>
              {language == 1 ? "Guardar" : "Save"}
            </MDButton> {" "} 
            <MDButton variant="gradient" color="success" onClick={()=>setShowConfirm(true)}>
              {language == 1 ? "Confirmar" : "Confirm"}
            </MDButton></>}
          </MDBox>
            <Grid container justifyContent={"space-between"}>
              <MDTypography variant="h5">{language == 1 ? "Productos Ajustados" : "Adjusted Products"}</MDTypography>
            </Grid>
            {status?.id == 1 && <MDButton style={{float: 'right'}} sx={{mb: -8, mr: 5}} variant="gradient" color="dark" onClick={() => {setAddingProduct(true), setQuantity(0), setPrice(0)}}>
              {language == 1 ? "Añadir" : "Add"} Item
            </MDButton>}
            <DataTable table={{columns: adjustedColumns, rows: adjusted}} entriesPerPage={true} />
            <MDTypography variant="h5">{language == 1 ? "Productos en la Factura" : "Products in Invoice"}</MDTypography>
            <DataTable table={invoiceProducts} entriesPerPage={true} />
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdjustInvoice;
