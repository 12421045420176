/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ViewInvoice from "./viewInvoice";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import imageIP from "app/imageIP";
import {TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import swal from "sweetalert";

const axios = require("axios");

function InvoiceListAdmin() {
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState([]);
  const [invoices, setInvoices] = useState({ columns: [], rows: [] });
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [download, setDownload] = useState(true);
  const [user, setUser] = useState();
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [addAdjustment, setAddAdjustment] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false)
  const [selectedAdjustmentType, setSelectedAdjustmentType] = useState(null);
  const [notes, setNotes] = useState('')
  const [errors, setErrors] = useState([]);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();

  async function GetStores(storeIds) {
    await axios
      .get(IP + "/stores/getActive", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active" && storeIds.includes(item.id)) {
            store.push({ label: item.store_number + ' ' + item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function createAdjustment() {
    await axios
      .post(IP + "/create-adjustment", {price: quantity * selectedAdjustmentType?.value, note: notes, invoice_id: selectedInvoice?.invoice_id}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
        .then(response => {
          setAddAdjustment(false)
          swal(language == 1 ? "Ajuste creado" : "Adjustment created", '', 'success')
        })
        .catch(error => {
          setErrors(error?.response?.data?.data ?? []);
        })
      setLoading(false)
  }

  async function getUserDetails() {
    await axios
      .get(IP + "/user/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setUser(response.data.data);
        getInvoiceData(response.data.data.role.id);
      })
      .catch(error => {
      })
  }

  async function getInvoiceData(role) {
    await axios
      .get(IP + "/invoices", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setData(response.data.data);

        let storeIds = [];

        response.data.data.map((item) => {
          if (!storeIds.includes(item.store.store_id)) {
            storeIds.push(item.store.store_id);
          }
        });

        if (role != 7) GetStores(storeIds);

        let columns = [
          {
            Header: language == 1 ? "Número de Factura" : "Invoice Number",
            accessor: "invoice_number",
          },
          { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "order" },
          {
            Header: language == 1 ? "Tienda" : "Store",
            accessor: "store_name",
          },
          { Header: language == 1 ? "Completamente Pagado" : "Fully Paid", accessor: "paid" },
          { Header: language == 1 ? "Cantidad Total" : "Total Amount", accessor: "total" },
          { Header: language == 1 ? "Total Ajustado" : "Total Adjusted", accessor: "adjusted" },
          { Header: language == 1 ? "Total de Pagos" : "Total Paid", accessor: "pay_total" },
          { Header: language == 1 ? "Total Pendiente" : "Total Pending", accessor: "pending" },
          { Header: language == 1 ? "Acción" : "action", accessor: "action", width: "25%" },
        ];

        let rows = response.data.data.map((item) => {
          //console.log(item);
          return {
            invoice_number: item.invoice_number,
            order: item.sales.so_number,
            paid:
              item.fully_paid == 1
                ? language == 1
                  ? "Pagado"
                  : "Paid"
                : language == 1
                ? "Pendiente"
                : "Pending",
            adjusted: '$' + parseFloat(item.total_adjusted).toFixed(2),
            total: '$' + parseFloat(item.total_amount).toFixed(2),
            pay_total: '$' + parseFloat(item.payment_total).toFixed(2),
            pending: '$' + parseFloat(item.pending).toFixed(2),
            store_name: item.store.name,
            store_id: item.store.store_id,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Ver" : "View"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/view-invoice");
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>visibility</Icon>
                  </MDButton>
                </Tooltip>{" "}
                {/* <Tooltip title={language == 1 ? "Imprimir" : "Print"}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => {
                      navigate("/view-invoice", { state: { print: true } });
                      localStorage.setItem("invoiceId", item.id);
                    }}
                    iconOnly
                  >
                    <Icon>print</Icon>
                  </MDButton>
                </Tooltip>
                {"  "} */}
                <Tooltip title={language == 1 ? "Descargar" : "Download"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    href={imageIP + "invoiceDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {(role != 7 && item?.adjustment?.status_id != 10) && <Tooltip title={language == 1 ? "Ajustes" : "Adjustments"}>
                  <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        navigate("/invoice-adjustments", {
                          state: {InvoiceId: item.id},
                        });
                      }}
                      iconOnly
                  >
                    <Icon>file_copy</Icon>
                  </MDButton>
                </Tooltip>}
                {(role != 7 && item?.adjustment?.status_id == 10) && <Tooltip title={language == 1 ? "Añadir Nota" : "Add Note"}>
                  <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setAddAdjustment(true)
                        setSelectedInvoice(item)
                        setNotes('')
                        setQuantity(0)
                      }}
                      iconOnly
                  >
                    <Icon>note_add</Icon>
                  </MDButton>
                </Tooltip>}
                {"  "}
                <Tooltip title={language == 1 ? "Descargar Ajustes" : "Download Adjustments"}>
                  <MDButton
                    variant="gradient"
                    color="light"
                    href={imageIP + "adjustmentDownload/" + item.id}
                    iconOnly
                  >
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
                {"  "}
                {role != 7 && (
                  <Tooltip title={language == 1 ? "Pagos" : "Payments"}>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => {
                        navigate("/invoice-payments-admin", {
                          state: { InvoiceId: item.id },
                        });
                      }}
                      iconOnly
                    >
                      <Icon>attach_money</Icon>
                    </MDButton>
                  </Tooltip>
                )}
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setInvoices(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  async function sendEmail() {
    await axios
      .get(IP + "/sendMail", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getUserDetails();
    //getInvoiceData();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <label>{item}</label>
            </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar userData={user} />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={addAdjustment} onClose={() => setSelectedInvoice(null)}>
          <DialogTitle>
            <Grid>
              {language == 1 ? "Factura: " : "Invoice: "}
              {selectedInvoice?.invoice_number} {" "}
            </Grid>
            <Grid>
              {"Balance"}: {selectedInvoice?.pending} {" "}
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Autocomplete
                disablePortal
                fullWidth
                value={selectedAdjustmentType}
                onChange={(event, newValue) => {
                  setSelectedAdjustmentType(newValue);
                }}
                options={[{value: 1, label: language == 1 ? "Débito" : "Debit"}, {value: -1, label: language == 1 ? "Crédito" : "Credit"}]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={""}
                        variant="standard"
                    />
                )}
            />
            <ShowError id={'type'} />
            <TextField
                margin="dense"
                id="notes"
                value={notes}
                label={language == 1 ? "Notas" : "Notes"}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                fullWidth
                variant="standard"
            />
            <ShowError id={'note'} />
            <TextField
                margin="dense"
                id="quantity"
                value={quantity}
                type={'number'}
                label={language == 1 ? "Cantidad $" : "Quantity $"}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                fullWidth
                variant="standard"
            />
            <ShowError id={'quantity'} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddAdjustment(false)}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
                disabled={quantity < (parseFloat(selectedInvoice.pending) * -1) || quantity == '' || quantity == null || quantity <= 0 || (selectedAdjustmentType?.value != -1 && selectedAdjustmentType?.value != 1) || loading}
                onClick={() => {
                  setLoading(true)
                  if (!loading)
                  createAdjustment()
                }}
            >
              {language == 1 ? "Guardar" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      <MDBox my={3}>
        <Card>
          {stores.length > 2 && (
            <Autocomplete
              sx={{ width: "15%", mt: 2, ml: 2 }}
              disablePortal
              value={selectedStore}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
              }}
              options={stores}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Tiendas" : "Stores"}
                  variant="standard"
                />
              )}
            />
          )}
          <DataTable table={{columns: invoices.columns, rows: invoices.rows.filter(row => selectedStore?.id && selectedStore?.id > 0 ? row.store_id == selectedStore?.id : true)}} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default InvoiceListAdmin;
