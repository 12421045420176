/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";

const axios = require("axios");

function ReportInvoicePayments() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortSelect, setSortSelect] = useState();
  const [reportData, setReportData] = useState([]);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [totalAmount, setTotalAmount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(true);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false);
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("invoice-payments.pdf");
      setDownload(true);
    });
  };

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getData() {
    await axios
      .get(
        IP +
          "/reports/payment-details?startDate=" +
          dateFrom +
          "&endDate=" +
          dateTo +
          "&store_id=" +
          selectedStore?.id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        function sortTotal(total) {
          let newTotal = "";
          for (let i = 0; i < total.length; i++) {
            if (total[i] != ",") newTotal += total[i];
          }
          return parseFloat(newTotal);
        }

        setReportData(
          response.data.data.map((item) => {
            return { ...item, sortTotal: sortTotal(item.amount) };
          })
        );

        let columns = [
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
          {
            Header: language == 1 ? "Número de Transacción" : "Transaction Number",
            accessor: "number",
          },
          { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
          { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
          {
            Header: language == 1 ? "Monto de la Factura" : "Invoice Amount",
            accessor: "invoice_amount",
          },
          { Header: language == 1 ? "Monto del Pago" : "Payment Amount", accessor: "amount" },
          { Header: "Balance", accessor: "balance" },
        ];

        let sum = 0;

        let rows = response.data.data
          .filter((item) => {
            if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
            else return true;
          })
          .map((item) => {
            sum += parseFloat(item.amount);
            return {
              store: item.invoice.store.name,
              number: item.payment.transaction_number,
              date: item.payment.date,
              method: item.payment.method,
              invoice: item.invoice.invoice_number,
              amount: "$" + item.amount_display,
              invoice_amount:
                "$" +
                item.invoice.total_amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              balance: "$" + item.invoice.balance,
            };
          });

        setTotalAmount(sum);

        let excelRows = response.data.data
          .filter((item) => {
            if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
            else return true;
          })
          .map((item) => {
            if (language == 1) {
              return {
                Tienda: item.invoice.store.name,
                Fecha: item.payment.date,
                "Número de Transacción": item.payment.transaction_number,
                "Método de Pago": item.payment.method,
                "Número de Factura": item.invoice.invoice_number,
                "Monto de la Factura": item.invoice.total_amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                "Monto del Pago": item.amount_display,
                Balance: item.invoice.balance,
              };
            } else {
              return {
                Store: item.invoice.store.name,
                Date: item.payment.date,
                "Transaction Number": item.payment.transaction_number,
                "Payment Method": item.payment.method,
                "Invoice Number": item.invoice.invoice_number,
                "Invoice Amount": item.invoice.total_amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                "Payment Amount": item.amount_display,
                Balance: item.invoice.balance,
              };
            }
          });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        setErrors(error.response.data.data);
        if (!error?.response?.data?.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortAscending() {
    let columns = [
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
      {
        Header: language == 1 ? "Número de Transacción" : "Transaction Number",
        accessor: "number",
      },
      { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
      { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
      {
        Header: language == 1 ? "Monto de la Factura" : "Invoice Monto",
        accessor: "invoice_amount",
      },
      { Header: language == 1 ? "Monto del Pago" : "Payment Monto", accessor: "amount" },
      { Header: "Balance", accessor: "balance" },
    ];

    let rows = reportData
      .filter((item) => {
        if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
        else return true;
      })
      .sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1))
      .map((item) => {
        return {
          store: item.invoice.store.name,
          number: item.payment.transaction_number,
          date: item.payment.date,
          method: item.payment.method,
          invoice: item.invoice.invoice_number,
          invoice_amount:
            "$" +
            item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          amount: "$" + item.amount_display,
          balance: "$" + item.invoice.balance,
        };
      });

    let excelRows = reportData
      .filter((item) => {
        if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
        else return true;
      })
      .sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1))
      .map((item) => {
        if (language == 1) {
          return {
            Tienda: item.invoice.store.name,
            Fecha: item.payment.date,
            "Número de Transacción": item.payment.transaction_number,
            "Método de Pago": item.payment.method,
            "Número de Factura": item.invoice.invoice_number,
            "Monto de la Factura": item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            "Monto del Pago": item.amount_display,
            Balance: item.invoice.balance,
          };
        } else {
          return {
            Store: item.invoice.store.name,
            Date: item.payment.date,
            "Transaction Number": item.payment.transaction_number,
            "Payment Method": item.payment.method,
            "Invoice Number": item.invoice.invoice_number,
            "Invoice Amount": item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            "Payment Amount": item.amount_display,
            Balance: item.invoice.balance,
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };
    setReport(tableData);
  }

  function sortDescending() {
    let columns = [
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
      {
        Header: language == 1 ? "Número de Transacción" : "Transaction Number",
        accessor: "number",
      },
      { Header: language == 1 ? "Método de Pago" : "Payment Method", accessor: "method" },
      { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
      {
        Header: language == 1 ? "Monto de la Factura" : "Invoice Monto",
        accessor: "invoice_amount",
      },
      { Header: language == 1 ? "Monto del Pago" : "Payment Monto", accessor: "amount" },
      { Header: "Balance", accessor: "balance" },
    ];

    let rows = reportData
      .filter((item) => {
        if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
        else return true;
      })
      .sort((a, b) => (a.sortTotal < b.sortTotal ? 1 : -1))
      .map((item) => {
        return {
          store: item.invoice.store.name,
          number: item.payment.transaction_number,
          date: item.payment.date,
          method: item.payment.method,
          invoice: item.invoice.invoice_number,
          invoice_amount:
            "$" +
            item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          amount: "$" + item.amount_display,
          balance: "$" + item.invoice.balance,
        };
      });

    let excelRows = reportData
      .filter((item) => {
        if (selectedStore?.id > 0) return item.invoice.store.store_id == selectedStore?.id;
        else return true;
      })
      .sort((a, b) => (a.sortTotal < b.sortTotal ? 1 : -1))
      .map((item) => {
        if (language == 1) {
          return {
            Tienda: item.invoice.store.name,
            Fecha: item.payment.date,
            "Número de Transacción": item.payment.transaction_number,
            "Método de Pago": item.payment.method,
            "Número de Factura": item.invoice.invoice_number,
            "Monto de la Factura": item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            "Monto del Pago": item.amount_display,
            Balance: item.invoice.balance,
          };
        } else {
          return {
            Store: item.invoice.store.name,
            Date: item.payment.date,
            "Transaction Number": item.payment.transaction_number,
            "Payment Method": item.payment.method,
            "Invoice Number": item.invoice.invoice_number,
            "Invoice Amount": item.invoice.total_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            "Payment Amount": item.amount_display,
            Balance: item.invoice.balance,
          };
        }
      });

    setData(excelRows);

    let tableData = { columns: columns, rows: rows };
    setReport(tableData);
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  useEffect(() => {
    GetStores();
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Monto</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortAscending();
                }
                if (sortSelect == "descending") {
                  sortDescending();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        {data.length > 0 ? (
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDBox display="flex">
              <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                {language == 1 ? "Organizar" : "Sort"}&nbsp;
                <Icon>sort</Icon>
              </MDButton>
              <MDBox ml={1}>
                <ExportToExcel apiData={data} fileName="invoice-payments" />
              </MDBox>
              <MDBox ml={1}>
                <MDButton variant="outlined" color="dark" onClick={createPDF}>
                  {language == 1 ? "Descargar PDF" : "Download PDF"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : null}
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Desde" : "From Date"}
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                <ShowError id={"startDate"} />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDInput
                  variant="standard"
                  type="date"
                  label={language == 1 ? "Hasta" : "To Date"}
                  fullWidth
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                <ShowError id={"endDate"} />
              </Grid>
              <Grid item lg={2}>
                <Autocomplete
                  disablePortal
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                  }}
                  options={stores}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getData}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            <MDTypography mt={2} variant="h5">
              {language == 1 ? "Total Pagado: " : "Total Paid: "}$
              {totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MDTypography>
            <DataTable table={report} entriesPerPage={true} canSearch />
          </MDBox>
        </Card>
        <div hidden={download}>
          <MDBox mt={50}>
            <div id="pdf">
              <h3>Agranel - {language == 1 ? "Reporte de Cobros" : "Invoice Payments Report"} {dateFrom} - {dateTo}</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{language == 1 ? "Tienda" : "Store"}</th>
                    <th>{language == 1 ? "Fecha" : "Date"}</th>
                    <th>{language == 1 ? "Número de Transacción" : "Transaction Number"}</th>
                    <th>{language == 1 ? "Método de Pago" : "Payment Method"}</th>
                    <th>{language == 1 ? "Número de Factura" : "Invoice Number"}</th>
                    <th>{language == 1 ? "Monto de la Factura" : "Invoice Amount"}</th>
                    <th>{language == 1 ? "Monto del Pago" : "Payment Amount"}</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData
                    .filter((item) => {
                      if (selectedStore?.id > 0)
                        return item.invoice.store.store_id == selectedStore?.id;
                      else return true;
                    })
                    .map((item) => {
                      return (
                        <tr>
                          <td>{item.invoice.store.name}</td>
                          <td>{item.payment.date}</td>
                          <td>{item.payment.transaction_number}</td>
                          <td>{item.payment.method}</td>
                          <td>{item.invoice.invoice_number}</td>
                          <td>
                            {"$" +
                              item.invoice.total_amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </td>
                          <td>${item.amount_display}</td>
                          <td>${item.invoice.balance}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </MDBox>
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportInvoicePayments;
