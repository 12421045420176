/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "app/components/confirmBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable2 from "app/components/shopperTable";
import { ExportToExcel } from "app/components/exportToExcel";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";

import IP from "app/IP";

const axios = require("axios");

function DeliveryTypes() {
  const [menu, setMenu] = useState(null);
  const [deliveryTypes, setdeliveryTypes] = useState({ columns: [], rows: [] });
  const [showConfirm, setShowConfirm] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [typeId, setTypeId] = useState('')
  const toggleSnackbar2 = () => setShow2(!show2);
  const navigate = useNavigate();

  async function Delete() {
    await axios
      .delete(IP + "/delivery-types/" + typeId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {

        getTypes();
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        } else {
          toggleSnackbar2();
          setTimeout(() => {
            setShow2(false);
          }, 3000);
        }
        //  console.log(error);
      });
  }

  async function getTypes() {
    await axios
      .get(IP + "/delivery-types", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {
        let columns = [
          { Header: language == 1 ? "Nombre" : "Name", accessor: "name" },
          { Header: language == 1 ? "Acción" : "Action", accessor: "action" },
        ];

        let rows = response.data.data.map((item) => {
          // console.log(item);
          return {
            name: item.name,
            action: (
              <Grid>
                <Tooltip title={language == 1 ? "Editar" : "Edit"}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      navigate("/edit-delivery-type", {state: {id: item.delivery_type_id}});
                    }}
                    iconOnly
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>{" "}
                <Tooltip title={language == 1 ? "Borrar" : "Delete"}>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      setTypeId(item.delivery_type_id)
                      setShowConfirm(true);
                    }}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
            ),
          };
        });

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setdeliveryTypes(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  useEffect(() => {
    getTypes();
  }, []);

  return (
    <DashboardLayout>
      <AlertDialog
        Show={showConfirm}
        setShowConfirm={setShowConfirm}
        Message={
          language == 1
            ? "¿Estás seguro que quieres borrar este tipo de recogido?"
            : "Are you sure you want to delete the delivery type?"
        }
        OkayFunction={Delete}
      />
      <MDSnackbar
        color="error"
        icon="notifications"
        title="EMOS"
        content={
          language == 1
            ? "El tipo de recogido no pudo ser borrado. Ya tiene ordenes de compra atados."
            : "The delivery type  could not be deleted. It is already tied to a purchase order."
        }
        dateTime={language==1 ? "Ahora" : "Now"}
        open={show2}
        close={toggleSnackbar2}
      />
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => navigate("/create-delivery-type")}>
            {language == 1 ? "Crear" : "create"}
          </MDButton>
          <MDBox display="flex">
            <MDBox ml={1}>
              {/* <ExportToExcel
                apiData={data}
                fileName={language == 1 ? "Categorias" : "Categories"}
              /> */}
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable2
            table={deliveryTypes}
            index={index}
            setIndex={setIndex}
            pageEntries={10}
            entriesPerPage={true}
            canSearch
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default DeliveryTypes;
