/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
//import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
//import ReactPDF from "@react-pdf/renderer";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import MDInput from "components/MDInput";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Pdf from "react-to-pdf";
import IP from "app/IP";
import swal from "sweetalert";

const axios = require("axios");

function ReportInventoryMovement() {
    const current = new Date();
    let month = current.getMonth() + 1;
    let day = current.getDate();
    const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
        day < 10 ? `0${day}` : `${day}`
    }`;

    const [menu, setMenu] = useState(null);
    const [report, setReport] = useState({ columns: [], rows: [] });
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([])
    const [responseData, setResponseData] = useState([]);
    const [download, setDownload] = useState(true);
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState('')
    const [open, setOpen] = useState(false)
    const [sortSelect, setSortSelect] = useState('')
    const [errors, setErrors] = useState([]);
    const [dateFrom, setDateFrom] = useState(currentDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [language, setLanguage] = useState(localStorage.getItem("Language"));

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const navigate = useNavigate();

    const ref = useRef();

    const options = {
        orientation: "portrait",
        unit: "in",
        format: [20, 10],
    };

    const createPDF = () => {
        setDownload(false)
        const pdf = new jsPDF("landscape", "px", "a0"); //px
        const data = document.querySelector("#pdf");
        pdf.html(data).then(() => {
            pdf.save("inventory movements.pdf");
            setDownload(true)
        });
    };

    async function getProducts() {
        await axios
            .get(IP + "/products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then(response => {
                setProducts(response.data.data.map(item => {
                    return {
                        id: item.id,
                        label: item.code + ' ' + item.name,
                        ...item
                    }
                }))
            })
    }

    async function getReportData() {
        await axios
            .get(
                IP + "/reports/inventory-movement?startDate=" + dateFrom + "&endDate=" + dateTo + "&product_id=" + selectedProduct?.id,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
            .then((response) => {
                setAllData(response.data.data)
                setResponseData(response.data.data);
                console.log(response.data.data);
                let columns = [
                    {
                        Header: language == 1 ? "Movimiento" : "Movement",
                        accessor: "type",
                    },
                    { Header: language == 1 ? "Fecha" : "Date", accessor: "date" },
                    { Header: language == 1 ? "Cantidad" : "Quantity", accessor: "quantity" },
                    { Header: language == 1 ? "Número de Orden" : "Order Number", accessor: "number" },
                ];

                let rows = response.data.data

                let excelRows = rows.map(row => {
                    let excelRow = {};
                    columns.forEach(column => {
                        excelRow[column.Header] = row[column.accessor];
                    });
                    return excelRow;
                });
                setErrors([]);

                setData(excelRows);

                let tableData = { columns: columns, rows: rows };
                setReport(tableData);
            })
            .catch((error) => {
                setErrors(error.response.data.data);
                // if (!error.response.data.authenticated) {
                //   navigate("/signIn");
                // }
                console.log(error);
            });
    }

    function TestValidation(message) {
        if (message)
            return message.map((item) => {
                return (
                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                        <label>{item}</label>
                    </MDTypography>
                );
            });
        else return null;
    }

    function ShowError(param) {
        // console.log(errors["name"]);
        if (param.id) return TestValidation(errors[param.id]);
        else return null;
    }

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    useEffect(() => {
        getProducts()
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDBox display="flex">
                        {data.length > 0 ? (
                            <>
                                <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                                    {language == 1 ? "Organizar" : "Sort"}&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </MDButton>
                                <MDBox ml={1}>
                                    <ExportToExcel apiData={data} fileName="Inventory Adjustments"/>
                                </MDBox>
                                <MDBox ml={1}>
                                    <MDButton variant='outlined' color='dark' onClick={createPDF}>Download PDF</MDButton>
                                </MDBox>
                            </>
                        ) : null}
                    </MDBox>
                </MDBox>
                <Card>
                    <MDBox p={3}>
                        <MDTypography variant="h4" sx={{mb: 1}}>
                            {language == 1 ? "Busqueda" : "Search"}
                        </MDTypography>
                        <Grid container spacing={1} sx={{mb: 4}}>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDInput
                                    variant="standard"
                                    type="date"
                                    label={language == 1 ? "Desde" : "From Date"}
                                    fullWidth
                                    value={dateFrom}
                                    onChange={(e) => {
                                        setDateFrom(e.target.value);
                                    }}
                                />
                                <ShowError id={"startDate"}/>
                            </Grid>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDInput
                                    variant="standard"
                                    type="date"
                                    label={language == 1 ? "Hasta" : "To Date"}
                                    fullWidth
                                    value={dateTo}
                                    onChange={(e) => {
                                        setDateTo(e.target.value);
                                    }}
                                />
                                <ShowError id={"endDate"}/>
                            </Grid>
                            <Grid item lg={'4'}>
                                <Autocomplete
                                    disablePortal
                                    value={selectedProduct}
                                    onChange={(event, newValue) => {
                                        setSelectedProduct(newValue);
                                    }}
                                    options={products}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={language == 1 ? "Productos" : "Products"}
                                            variant="standard"
                                        />
                                    )}
                                />
                                <ShowError id={"product_id"}/>
                            </Grid>
                            <Grid id="grid1-1" item xs={12} sm={2}>
                                <MDButton
                                    type="button"
                                    variant="gradient"
                                    className="float-sm-right"
                                    color="info"
                                    onClick={getReportData}
                                >
                                    {language == 1 ? "Busqueda" : "Search"}
                                </MDButton>
                            </Grid>
                        </Grid>
                        <div>
                            <DataTable table={report} entriesPerPage={true} canSearch/>
                        </div>
                    </MDBox>
                </Card>
                <div hidden={download}>
                    <MDBox mt={50}>
                        <div id="pdf">
                            <h3>Agranel - Inventory Movement Report - {selectedProduct?.code} {selectedProduct?.name}</h3>
                            <h4>({dateFrom} - {dateTo})</h4>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {report.columns.map(column =>
                                            <th>{column.Header}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                {report.rows.map(row =>
                                    <tr>
                                        {report.columns.map(column =>
                                            <td>{row[column?.accessor] ?? ''}</td>
                                        )}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </MDBox>
                </div>
            </MDBox>
        </DashboardLayout>
    );
}

export default ReportInventoryMovement;
