/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";
import "bootstrap/dist/css/bootstrap.min.css";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IP from "app/IP";
import { item } from "examples/Sidenav/styles/sidenavItem";

const axios = require("axios");

function ReportAging() {
  const [menu, setMenu] = useState(null);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(true)
  const [sortSelect, setSortSelect] = useState("");
  const [data, setData] = useState();
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState("")
  const [allData, setAllData] = useState([])
  const [responseData, setResponseData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));
  const [sortOption, setSortOption] = useState({id: 1, label: language ==1 ? "Precio de Venta" : "Sales Price"})
  const [totals, setTotals] = useState({days30: 0, days60: 0, days90: 0, daysOver90: 0})

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight as 12

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  };

  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
      day < 10 ? `0${day}` : `${day}`
  }`;

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false)
    const pdf = new jsPDF("landscape", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("aging.pdf");
      setDownload(true)
    });
  };

  async function GetStores() {
    // console.log("aqui");
    //setRedirect(true);
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then(function (response) {
        //console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }

          setStores(store);
        });
      })
      .catch(function (error) {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortTotal(total) {
    total = total.toString();
    let newTotal = ''
    for (let i = 0; i < total.length; i++) {
      if (total[i] != ',')
        newTotal += total[i]
    }
    return parseFloat(newTotal)
  }

  async function getData() {
    await axios
      .get(IP + "/reports/aging", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
      .then((response) => {

        response.data.data.map(item=>{
          if (!responseData.some(res => res.id == item.id))
            responseData.push({...item, sortTotal: sortTotal(item.pending)})
        })
        setAllData(response.data.data)
        let columns = [
          { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
          { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
          { Header: language == 1 ? "Menos de 30 días" : "Less than 30 days", accessor: "days30" },
          { Header: language == 1 ? "30 a 60 días" : "30 to 60 days", accessor: "days60" },
          { Header: language == 1 ? "60 a 90 días" : "60 to 90 days", accessor: "days90" },
          { Header: language == 1 ? "Más de 90 días" : "More than 90 days", accessor: "over90" },
        ];

        let totalDays30 = 0;
        let totalDays60 = 0;
        let totalDays90 = 0;
        let totalDaysOver90 = 0;

        let rows = response.data.data
        .filter(item=>{
          if (selectedStore?.id > 0)
            return item.store.id == selectedStore?.id
          else
            return true
        })
        .map((item) => {
          totalDays30 += sortTotal(item['30days'] != '' ? item['30days'] : 0);
          totalDays60 += sortTotal(item['60days'] != '' ? item['60days'] : 0);
          totalDays90 += sortTotal(item['90days'] != '' ? item['90days'] : 0);
          totalDaysOver90 += sortTotal(item['over_90days'] != '' ? item['over_90days'] : 0);
          return {
            date: item.date,
            store: item.store.name,
            invoice: item.invoice_number,
            days30: item['30days'] != '' ? '$' + item['30days'] : '',
            days60: item['60days'] != '' ? '$' + item['60days'] : '',
            days90: item['90days'] != '' ? '$' + item['90days'] : '',
            over90: item['over_90days'] != '' ? '$' + item['over_90days'] : '',
          };
        });
        setTotals({days30: totalDays30, days60: totalDays60, days90: totalDays90, daysOver90: totalDaysOver90})

        rows.unshift({
          date: '',
          store: '',
          invoice: 'Total: ',
          days30: '$' + totalDays30.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          days60: '$' + totalDays60.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          days90: '$' + totalDays90.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          over90: '$' + totalDaysOver90.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        })

        let excelRows = response.data.data
        .filter(item=>{
          if (selectedStore?.id > 0)
            return item.store.id == selectedStore?.id
          else
            return true
        })
        .map((item) => {
          if (language == 1) {
            return {
              Fecha: item.date,
              Tienda: item.store.name,
              "Número de Factura": item.invoice_number,
              "Menos de 30 días": item['30days'] != '' ? '$' + item['30days'] : '',
              "30 a 60 días": item['60days'] != '' ? '$' + item['60days'] : '',
              "60 a 90 días": item['90days'] != '' ? '$' + item['90days'] : '',
              "Más de 90 días": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
            };
          } else {
            return {
              Fecha: item.date,
              Tienda: item.store.name,
              "Invoice Number": item.invoice_number,
              "Less than 30 days": item['30days'] != '' ? '$' + item['30days'] : '',
              "30 to 60 days": item['60days'] != '' ? '$' + item['60days'] : '',
              "60 to 90 days": item['90days'] != '' ? '$' + item['90days'] : '',
              "More than 90 days": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
            };
          }
        });

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        if (!error.response.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function sortAscending() {
    let columns = [
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
      { Header: language == 1 ? "Menos de 30 días" : "Less than 30 days", accessor: "days30" },
      { Header: language == 1 ? "30 a 60 días" : "30 to 60 days", accessor: "days60" },
      { Header: language == 1 ? "60 a 90 días" : "60 to 90 days", accessor: "days90" },
      { Header: language == 1 ? "Más de 90 días" : "More than 90 days", accessor: "over90" },
    ];

    let totalDays30 = 0;
    let totalDays60 = 0;
    let totalDays90 = 0;
    let totalDaysOver90 = 0;

    let rows = responseData
      .filter(item=>{
        if (selectedStore?.id > 0)
          return item.store.id == selectedStore?.id
        else
          return true
      })
      .sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1))
      .map((item) => {
        totalDays30 += sortTotal(item['30days'] != '' ? item['30days'] : 0);
        totalDays60 += sortTotal(item['60days'] != '' ? item['60days'] : 0);
        totalDays90 += sortTotal(item['90days'] != '' ? item['90days'] : 0);
        totalDaysOver90 += sortTotal(item['over_90days'] != '' ? item['over_90days'] : 0);
        return {
          date: item.date,
          store: item.store.name,
          invoice: item.invoice_number,
          days30: item['30days'] != '' ? '$' + item['30days'] : '',
          days60: item['60days'] != '' ? '$' + item['60days'] : '',
          days90: item['90days'] != '' ? '$' + item['90days'] : '',
          over90: item['over_90days'] != '' ? '$' + item['over_90days'] : '',
        };
      });
    setTotals({days30: totalDays30, days60: totalDays60, days90: totalDays90, daysOver90: totalDaysOver90})

    rows.unshift({
      date: '',
      store: '',
      invoice: 'Total: ',
      days30: '$' + totalDays30.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      days60: '$' + totalDays60.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      days90: '$' + totalDays90.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      over90: '$' + totalDaysOver90.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    })

    let excelRows = responseData.filter(item=>{
      if (selectedStore?.id > 0)
        return item.store.id == selectedStore?.id
      else
        return true
    }).sort((a, b) => (a.sortTotal > b.sortTotal ? 1 : -1)).map((item) => {
      if (language == 1) {
        return {
          Fecha: item.date,
          Tienda: item.store.name,
          "Número de Factura": item.invoice_number,
          "Menos de 30 días": item['30days'] != '' ? '$' + item['30days'] : '',
          "30 a 60 días": item['60days'] != '' ? '$' + item['60days'] : '',
          "60 a 90 días": item['90days'] != '' ? '$' + item['90days'] : '',
          "Más de 90 días": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
        };
      } else {
        return {
          Fecha: item.date,
          Tienda: item.store.name,
          "Invoice Number": item.invoice_number,
          "Less than 30 days": item['30days'] != '' ? '$' + item['30days'] : '',
          "30 to 60 days": item['60days'] != '' ? '$' + item['60days'] : '',
          "60 to 90 days": item['90days'] != '' ? '$' + item['90days'] : '',
          "More than 90 days": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
        };
      }
    });

    setData(excelRows)
  
    let tableData = { columns: columns, rows: rows };
    setReport(tableData)
  }

  function sortDescending() {
    let columns = [
      { Header: language == 1 ? "Fecha" : "Date", accessor: "date", width: "10%" },
      { Header: language == 1 ? "Tienda" : "Store", accessor: "store" },
      { Header: language == 1 ? "Número de Factura" : "Invoice Number", accessor: "invoice" },
      { Header: language == 1 ? "Menos de 30 días" : "Less than 30 days", accessor: "days30" },
      { Header: language == 1 ? "30 a 60 días" : "30 to 60 days", accessor: "days60" },
      { Header: language == 1 ? "60 a 90 días" : "60 to 90 days", accessor: "days90" },
      { Header: language == 1 ? "Más de 90 días" : "More than 90 days", accessor: "over90" },
    ];

    let totalDays30 = 0;
    let totalDays60 = 0;
    let totalDays90 = 0;
    let totalDaysOver90 = 0;
  
    let rows = responseData
      .filter(item=>{
        if (selectedStore?.id > 0)
          return item.store.id == selectedStore?.id
        else
          return true
      })
      .sort((a, b) => (a.sortTotal < b.sortTotal ? 1 : -1))
      .map((item) => {
        totalDays30 += sortTotal(item['30days'] != '' ? item['30days'] : 0);
        totalDays60 += sortTotal(item['60days'] != '' ? item['60days'] : 0);
        totalDays90 += sortTotal(item['90days'] != '' ? item['90days'] : 0);
        totalDaysOver90 += sortTotal(item['over_90days'] != '' ? item['over_90days'] : 0);
        return {
          date: item.date,
          store: item.store.name,
          invoice: item.invoice_number,
          days30: item['30days'] != '' ? '$' + item['30days'] : '',
          days60: item['60days'] != '' ? '$' + item['60days'] : '',
          days90: item['90days'] != '' ? '$' + item['90days'] : '',
          over90: item['over_90days'] != '' ? '$' + item['over_90days'] : '',
        };
    });
    setTotals({days30: totalDays30, days60: totalDays60, days90: totalDays90, daysOver90: totalDaysOver90})

    rows.unshift({
      date: '',
      store: '',
      invoice: 'Total: ',
      days30: '$' + totalDays30.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      days60: '$' + totalDays60.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      days90: '$' + totalDays90.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      over90: '$' + totalDaysOver90.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    })

    let excelRows = responseData.filter(item=>{
      if (selectedStore?.id > 0)
        return item.store.id == selectedStore?.id
      else
        return true
    }).sort((a, b) => (a.sortTotal < b.sortTotal ? 1 : -1)).map((item) => {
      if (language == 1) {
        return {
          Fecha: item.date,
          Tienda: item.store.name,
          "Número de Factura": item.invoice_number,
          "Menos de 30 días": item['30days'] != '' ? '$' + item['30days'] : '',
          "30 a 60 días": item['60days'] != '' ? '$' + item['60days'] : '',
          "60 a 90 días": item['90days'] != '' ? '$' + item['90days'] : '',
          "Más de 90 días": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
        };
      } else {
        return {
          Fecha: item.date,
          Tienda: item.store.name,
          "Invoice Number": item.invoice_number,
          "Less than 30 days": item['30days'] != '' ? '$' + item['30days'] : '',
          "30 to 60 days": item['60days'] != '' ? '$' + item['60days'] : '',
          "60 to 90 days": item['90days'] != '' ? '$' + item['90days'] : '',
          "More than 90 days": item['over_90days'] != '' ? '$' + item['over_90days'] : ''
        };
      }
    });

    setData(excelRows)
  
    let tableData = { columns: columns, rows: rows };
    setReport(tableData)
  }

  useEffect(() => {
    getData();
    if (stores.length == 0)
      GetStores()
  }, [selectedStore]);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Monto</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortAscending();
                } else if (sortSelect == "descending") {
                  sortDescending();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
          <MDBox display="flex">
            <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
              {language == 1 ? "Organizar" : "Sort"}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            <MDBox ml={1}>
              <ExportToExcel apiData={data} fileName="aging" />
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant='outlined' color='dark' onClick={createPDF}>Download PDF</MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
          <Card>
            <Autocomplete
              sx={{ width: "15%", mt: 2, ml: 2 }}
              disablePortal
              value={selectedStore}
              onChange={(event, newValue) => {
                setSelectedStore(newValue);
              }}
              options={stores}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language == 1 ? "Tiendas" : "Stores"}
                  variant="standard"
                />
              )}
            />
            <DataTable table={report} entriesPerPage={true} canSearch />
          </Card>
          <div hidden={download}>
        <MDBox mt={50}>
        <div id="pdf">
          <h3>Agranel - Aging Report - {getCurrentDateTime()}</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{language == 1 ? "Fecha" : "Date"}</th>
                <th>{language == 1 ? "Tienda" : "Store"}</th>
                <th>{language == 1 ? "Número de Factura" : "Invoice Number"}</th>
                <th>{language == 1 ? "Menos de 30 días" : "Less than 30 days"}</th>
                <th>{language == 1 ? "30 a 60 días" : "30 to 60 days"}</th>
                <th>{language == 1 ? "60 a 90 días" : "60 to 90 days"}</th>
                <th>{language == 1 ? "Más de 90 días" : "More than 90 days"}</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>Totals: </td>
              <td>{'$' + totals.days30.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
              <td>{'$' + totals.days60.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
              <td>{'$' + totals.days60.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
              <td>{'$' + totals.daysOver90.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
            </tr>
            {responseData
              .filter(item => {
                if (selectedStore?.id > 0) {
                  return item.store.id == selectedStore?.id;
                }
                return true;
              })
              .map(item => {
              return <tr>
                <td>{item.date}</td>
                <td>{item.store.name}</td>
                <td>{item.invoice_number}</td>
                <td>{item['30days'] != '' ? '$' + item['30days'] : ''}</td>
                <td>{item['60days'] != '' ? '$' + item['60days'] : ''}</td>
                <td>{item['90days'] != '' ? '$' + item['90days'] : ''}</td>
                <td>{item['over_90days'] != '' ? '$' + item['over_90days'] : ''}</td>
              </tr>
            })}
            </tbody>
          </table>
        </div>
        </MDBox>
          </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportAging;
