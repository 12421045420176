  /**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AlertDialog from "app/components/confirmBox";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import { ExportToExcel } from "app/components/exportToExcel";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import jsPDF from "jspdf";

import IP from "app/IP";

const axios = require("axios");

function ReportMonthlyBalances() {
  const current = new Date();
  let day = current.getDate();
  let month = current.getMonth() + 1;
  const currentDate = `${current.getFullYear()}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;

  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortSelect, setSortSelect] = useState();
  const [reportData, setReportData] = useState([]);
  const [report, setReport] = useState({ columns: [], rows: [] });
  const [errors, setErrors] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(true);
  const [language, setLanguage] = useState(localStorage.getItem("Language"));

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const createPDF = () => {
    setDownload(false);
    const pdf = new jsPDF("portrait", "px", "a0"); //px
    const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("Monthly Balances.pdf");
      setDownload(true);
    });
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight as 12

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  };

  async function GetStores() {
    await axios
      .get(IP + "/stores", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        let store = [];

        response.data.data.map((item) => {
          if (item.active == "Active") {
            store.push({ label: item.name, id: item.id });
          }
        });
        store.unshift({ label: language == 1 ? "Todas" : "All", id: 0 });

        setStores(store);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function getReport() {
    await axios
    .get(
        IP +
        "/reports/monthly-balances?store_id=" + (selectedStore?.id ?? 0),
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
            },
        }
    )
    .then(response => {
        setReportData(
          response.data.data
        );

        let columns = [
          { Header: language == 1 ? "Número de Tienda" : "Store Number", accessor: "store_number" },
          { Header: language == 1 ? "Tienda" : "Store", accessor: "name" },
          { Header: language == 1 ? "Balance Inicial" : "Initial Balance", accessor: "pending_initial_balance" },
          ...response.data.data.months.map(month => ({Header: month, accessor: month})),
          { Header: language == 1 ? "Ajustes" : "Adjustments", accessor: "adjustment_total" },
          { Header: language == 1 ? "Pagos" : "Payments", accessor: "payment_total" },
          { Header: "Total", accessor: "grand_total" },
        ];

        let totals = response.data.data.totals;
        let rows = [
          ...response.data.data.stores.map(store => {
          return {
            ...store,
            pending_initial_balance: '$' + store.pending_initial_balance.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            ...response.data.data.months.reduce((acc, month) => ({
              ...acc,
              [month]: '$' + (store[month] ?? 0)?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }), {}),
            adjustment_total: '$' + store.adjustment_total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            payment_total: '$' + store.payment_total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            grand_total: '$' + store.grand_total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
        }),
        {
          name: language == 1 ? "Totales:" : "Totals:",
          ...response.data.data.months.reduce((acc, month) => ({
            ...acc,
            [month]: '$' + (totals[month] ?? 0)?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }), {}),
          pending_initial_balance: '$' + totals.pending_initial_balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          adjustment_total: '$' + totals.adjustment_total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          payment_total: '$' + totals.payment_total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          grand_total: '$' + totals.grand_total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }
      ];

        let excelRows = response.data.data.stores.map(row => {
            let excelRow = {};
            columns.forEach(column => {
                excelRow[column.Header] = column.accessor == 'name' ? row[column.accessor] : parseFloat(parseFloat(row[column.accessor]).toFixed(2));
            });
            return excelRow;
        });
        console.log(excelRows);

        setData(excelRows);

        let tableData = { columns: columns, rows: rows };
        //console.log(tableData)
        setReport(tableData);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.data);
        if (!error?.response?.data.authenticated) {
          navigate("/signIn");
        }
        console.log(error);
      });
  }

  function TestValidation(message) {
    if (message)
      return message.map((item) => {
        return (
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <label>{item}</label>
          </MDTypography>
        );
      });
    else return null;
  }

  function ShowError(param) {
    // console.log(errors["name"]);
    if (param.id) return TestValidation(errors[param.id]);
    else return null;
  }

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    GetStores()
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open}>
          <DialogTitle>{language == 1 ? "Organizar por:" : "Sort by:"}</DialogTitle>
          <DialogContent>
            <MDTypography variant="body1">Total</MDTypography>
            <FormControl>
              <RadioGroup
                name="Sort Options"
                onChange={(e) => {
                  if (e.target.value == "ascending") {
                    setSortSelect(e.target.value);
                  } else if (e.target.value == "descending") {
                    setSortSelect(e.target.value);
                  }
                }}
              >
                <FormControlLabel
                  value="descending"
                  control={<Radio />}
                  label={language == 1 ? "Mayor a menor" : "Highest to lowest"}
                />
                <FormControlLabel
                  value="ascending"
                  control={<Radio />}
                  label={language == 1 ? "Menor a mayor" : "Lowest to highest"}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{language == 1 ? "Cancelar" : "Cancel"}</Button>
            <Button
              onClick={() => {
                if (sortSelect == "ascending") {
                  sortAscending();
                }
                if (sortSelect == "descending") {
                  sortDescending();
                }
              }}
            >
              {language == 1 ? "Organizar" : "Sort"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <MDBox my={3}>
        {data.length > 0 ? (
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDBox display="flex">
              <MDButton variant="outlined" color="dark" onClick={() => setOpen(true)}>
                {language == 1 ? "Organizar" : "Sort"}&nbsp;
                <Icon>sort</Icon>
              </MDButton>
              <MDBox ml={1}>
                <ExportToExcel apiData={data} fileName="Sales" />
              </MDBox>
              <MDBox ml={1}>
                <MDButton variant="outlined" color="dark" onClick={createPDF}>
                  {language == 1 ? "Descargar PDF" : "Download PDF"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : null}
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" sx={{ mb: 1 }}>
              {language == 1 ? "Busqueda" : "Search"}
            </MDTypography>
            <Grid container spacing={1}>
              <Grid item lg={2}>
                <Autocomplete
                  disablePortal
                  value={selectedStore}
                  onChange={(event, newValue) => {
                    setSelectedStore(newValue);
                  }}
                  options={stores}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language == 1 ? "Tiendas" : "Stores"}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid id="grid1-1" item xs={12} sm={2}>
                <MDButton
                  type="button"
                  variant="gradient"
                  className="float-sm-right"
                  color="info"
                  onClick={getReport}
                >
                  {language == 1 ? "Busqueda" : "Search"}
                </MDButton>
              </Grid>
            </Grid>
            <DataTable table={report} entriesPerPage={true} canSearch />
          </MDBox>
        </Card>
        <div hidden={download}>
          <MDBox mt={50}>
              <div id="pdf">
                  <h3>Agranel - Monthly Customer Balance {getCurrentDateTime()}</h3>
                  <table className="table table-striped">
                      <thead>
                      <tr>
                          {report.columns.map(column =>
                              <th>{column.Header}</th>
                          )}
                      </tr>
                      </thead>
                      <tbody>
                      {report.rows.map(row =>
                          <tr>
                              {report.columns.map(column =>
                                  <td>{row[column?.accessor] ?? ''}</td>
                              )}
                          </tr>
                      )}
                      </tbody>
                  </table>
              </div>
          </MDBox>
      </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportMonthlyBalances;